import React, { useEffect, useState, useCallback } from 'react'
import ProfileSidebar from '../../ProfileSidebar/ProfileSidebar'
import * as constants from '../../../Constants/Constants'
import { Calendar } from '@natscale/react-calendar';
import '@natscale/react-calendar/dist/main.css';
import './ComplianceCalendar.css';

declare var $: any;

export default function ComplianceCalendar() {

  const [currentUser, setCurrentUser] = useState<any>();
  const [apiResponse, setApiResponse] = useState<boolean>(false);
  const [calendars, setCalendars] = useState<Date[]>();
  const [complianceList, setComplianceList] = useState<any[]>();
  const [currentQuarter, setCurrentQuarter] = useState<boolean>(true);
  const [value, setValue] = useState<Date>();
  const [calendarYear, setCalendarYear] = useState<string>('');
  const [formSubmitLoader, setFormSubmitLoader] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const onChange = useCallback((value: any) => {
    getComplianceList(null, null, (value.getMonth() + 1), value.getFullYear());
  }, [setValue],);

  useEffect(() => {
    getDateRange();
    if (localStorage.getItem('currentUser')) {
      let userData = JSON.parse(localStorage.getItem('currentUser')!);
      setCurrentUser(userData);
    }
  }, [currentQuarter, calendarYear, value]);

  const getComplianceList = (startDate: Date | null, endDate: Date | null, month: number, year: number) => {
    setApiResponse(false);
    let urlToAppend = '';
    if (value) {
      urlToAppend += '&specificDate=' + value!.toISOString().substring(0, 10);
    } else if(startDate && endDate) {
      urlToAppend += '&startDate=' + startDate!.toISOString().substring(0, 10) + '&endDate=' + endDate!.toISOString().substring(0, 10);
    }else if(month > 0 && year > 0){
      urlToAppend += '&month=' + month + '&year=' + year;
    }
    console.log(month, year);
    fetch(constants.BASE_URL + 'compliance-calendar?pageNum=1&numPerPage=200' + urlToAppend, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader(), 'Access-Control-Allow-Origin': '*' },
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      if (response.status == '200') {
        sessionStorage.setItem('complianceList', JSON.stringify(response.result));
        setComplianceList(response.result);
        if(startDate && endDate){
          getMonthRange(startDate, endDate);
        }
        setApiResponse(true);
      }
    });
  }

  const changeCalendarYear = (e: any) => {
    setCurrentQuarter(false);
    setCalendarYear(e.target.value);
  }

  const changeCurrentQuarter = (e: any) => {
    if (e.target.checked) {
      setCurrentQuarter(true);
      setCalendarYear('');
    } else {
      setCurrentQuarter(false);
      setCalendarYear('2022-23');
    }
  }

  const getDateRange = () => {
    let startDate = new Date();
    let endDate = new Date();
    if (currentQuarter) {
      let currentDate = new Date();
      let currentMonth = currentDate.getMonth() + 1;
      if (currentMonth >= 1 && currentMonth <= 3) {
        startDate = new Date(currentDate.getFullYear() + '-01-01');
        endDate = new Date(currentDate.getFullYear() + '-03-31');
      } else if (currentMonth >= 4 && currentMonth <= 6) {
        startDate = new Date(currentDate.getFullYear() + '-04-01');
        endDate = new Date(currentDate.getFullYear() + '-06-30');
      } else if (currentMonth >= 7 && currentMonth <= 9) {
        startDate = new Date(currentDate.getFullYear() + '-07-01');
        endDate = new Date(currentDate.getFullYear() + '-09-30');
      } else {
        startDate = new Date(currentDate.getFullYear() + '-10-01');
        endDate = new Date(currentDate.getFullYear() + '-12-31');
      }
    } else {
      let years = calendarYear && calendarYear.length > 0 ? calendarYear.split('-') : '2022-23'.split('-');
      startDate = new Date(years[0] + '-04-01');
      endDate = new Date('20' + years[1] + '-03-31');
    }
    getComplianceList(startDate, endDate, 0 ,0);
  }

  const getMonthRange = (startDate: Date | null, endDate: Date | null) => {
    let startYear = startDate!.getFullYear();
    let endYear = endDate!.getFullYear();
    let dates = [];

    for (let i = startYear; i <= endYear; i++) {
      let endMonth = i != endYear ? 11 : endDate!.getMonth() + 1 - 1;
      let startMon = i === startYear ? startDate!.getMonth() + 1 - 1 : 0;
      for (let j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
        let month = j + 1;
        let displayMonth = month < 10 ? '0' + month : month;
        dates.push(new Date([i, displayMonth, '01'].join('-')));
      }
    }
    setCalendars(dates);
  }

  const isHighlight = useCallback((date: Date) => {
    date.setDate(date.getDate() + 1);
    let doHighlight = false;
    let dateToCompare = date.toISOString().substring(0, 10);
    let cl = JSON.parse(sessionStorage.getItem('complianceList')!);
    let isEqual = cl?.find((element: any) => dateToCompare == element.dueDate);
    if (isEqual) {
      doHighlight = true;
    }
    return doHighlight;
  }, []);

  const addToMyInterest = (complianceCalendarId: string, index: number) => {
    setSelectedIndex(index);
    setFormSubmitLoader(true);
    fetch(constants.BASE_URL + 'compliance-interest/' + complianceCalendarId + '/' + currentUser?.userId, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
      body: JSON.stringify({})
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      setFormSubmitLoader(false);
      if (response.status == '200') {
        constants.showSuccess(response.message);
      } else {
        constants.showError(response.message);
      }
    });
  }

  return (
    <>
      <section className="page-header page-header-dark bg-secondary">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-8">
              <h1>My Profile</h1>
            </div>
            <div className="col-md-4">
              <ul className="breadcrumb justify-content-start justify-content-md-end mb-0">
                <li><a href="/">Home</a></li>
                <li className="active">My Profile</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div id="content">
        <div className="container-fluid p-5 my-4">
          <div className="row ">
            <div className="col-lg-2">
              <ProfileSidebar />
            </div>
            <div className="col-lg-10">
              <div className="bg-white shadow-md rounded p-4">
                <div className='row'>
                  <div className='col-md-4'>
                    <label className='fs-6'><b>Calender : {calendarYear ? calendarYear : '2022-23'}</b></label>
                  </div>
                  <div className='col-md-3'>
                    {/* <label className='fs-6'><b>Current Quarter:</b>
                      <input type="checkbox" className='ms-2' onChange={changeCurrentQuarter} defaultChecked={currentQuarter} />
                    </label> */}
                  </div>
                  <div className='col-md-5'>
                    <div className='row'>
                      <label htmlFor="cars" className='col-8 text-end fs-6'><b>Financial Year:</b></label>
                      <div className='col-4'>
                        <select name="years" id="financial years" className='form-control form-control-sm' onChange={changeCalendarYear}
                          value={calendarYear}>
                          <option value="">--Select--</option>
                          <option value="2022-23">2022-23</option>
                          <option value="2023-24">2023-24</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <div className='row'>
                  {apiResponse && calendars?.map((item, index) => (
                    <div className='col pt-2 pb-2' key={index}>
                      <Calendar lockView weekends={[]} hideAdjacentDates value={item} isHighlight={isHighlight} onChange={onChange} />
                    </div>
                  ))}
                </div>
                {/* <h2 className="text-6 mb-4">ROC Compliance Calendar 2022-23: A Complete Overview</h2>
                <hr className="mx-n4 mb-4" />
                <img src='assets/images/compliance.jpg' className='w-100' />
                <p className='mt-4'>Companies and LLPs operating pan India are mandated to adhere to annual filings norms cited under the
                  Companies Act, 2013 and the LLP Act, 2008. The filing with the ROC should be done annually or on the
                  occurrence of certain events.</p>
                <h3 className="text-6 mb-4 mt-4">ROC Compliance Calendar</h3>
                <p>Companies and LLPs must adhere to the filing-related norms and respect the deadline for the same to
                  avert penalties. If the companies or LLPs fail to ensure conformity with such norms, stringent penalties
                  would be levied. Therefore, the companies should stay in line with ROC compliance and fulfilled them in a
                  time-bound manner.</p> */}
                {/* changes by client  start*/}
                <h4 className='mt-4'>List of due dates</h4>
                {complianceList?.map((item, index) => (

                  <div className='column1 shadow-sm' key={index}>
                    <div className=' d-flex justify-content-between'>
                      <div className='col-md-9'>{item.form}</div>
                      <div className='col-md-3'><span className='text-muted'>Due date: </span>{item.dueDate}</div>
                    </div>
                    <hr className="style3"></hr>
                    <div className=' d-flex justify-content-between p-1'>
                      <div className='text-muted col-md-9'>{item.description}</div>
                      <div className='col-md-3 '>
                        <button type="button" className="btn btn-primary rounded-pill intbtn float-right" 
                          disabled={formSubmitLoader && selectedIndex == index}
                          onClick={() => addToMyInterest(item.complianceCalendarId, index)}>
                            {formSubmitLoader && selectedIndex == index && <i className="fas fa-circle-notch fa-spin me-2"></i>}
                            I'm interested
                          </button>
                      </div>
                    </div>
                  </div>
                ))}
                {/* changes by client  end*/}
                <p className='mt-4'><i>* The due dates cited above are amendable and hence they could be changed by the department concerned
                  whenever necessary. The intimation relating to due date change should be shared by authority via notification</i></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
