import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import ProfileSidebar from '../../ProfileSidebar/ProfileSidebar'
import * as constants from '../../../Constants/Constants'
import { useForm } from 'react-hook-form';
import ReactPaginate from 'react-paginate';
import './Service.css'

declare var $: any;

export default function Service () {
  
  const { register, handleSubmit, setValue, formState: { errors } } = useForm();
  const [formSubmitLoader, setFormSubmitLoader] = useState<boolean>(false);
  const [serviceList, setServiceList] = useState<any[]>();
  const [serviceName, setServiceName] = useState<string>('');

  // VARIABLES FOR PAGINATION
  const [pageCount, setPageCount] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const [itemsPerPage , setItemsPerPage ] = useState(10);
  const [searchKey, setSearchKey] = useState<string | null>(null);

  useEffect(() => {
    getServiceList();
  }, [pageNum, searchKey]);

  const getServiceList = () => {
    fetch(constants.BASE_URL + 'services?pageNum='+pageNum+'&numPerPage=' + itemsPerPage + (searchKey && searchKey.length > 2 ? '&searchBy=' + searchKey : ''), {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      if (response.status == '200') {
        response.result.forEach((element: any) => {
          element['basePriceStr'] = element.basePrice?.toLocaleString();
          element['minimumPayableStr'] = element.minimumPayable?.toLocaleString();
        });
        setServiceList(response.result);
        setPageCount(Math.ceil(response.listCount / itemsPerPage));
      }
    });
  }

  const handlePageClick = (event: any) => {
    setPageNum(event.selected + 1);
  };

  const prepareEditService = (serviceId: string, basePrice: number, minimumPayable: number, 
    status: boolean, serviceName: string, taxPercent: number) => {
    $('#service-modal').modal('show');
    setServiceName(serviceName);
    setTimeout(() => {
      setValue('serviceId', serviceId, { shouldDirty: true });
      setValue('basePrice', basePrice, { shouldDirty: true });
      setValue('minimumPayable', minimumPayable, { shouldDirty: true });
      setValue('taxPercent', taxPercent, { shouldDirty: true });
      setValue('status', status, { shouldDirty: true });
    }, 500);
  }

  const editService = (data: any) => {
    setFormSubmitLoader(true);
    fetch(constants.BASE_URL + 'services/' + data.serviceId, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
      body: JSON.stringify(data)
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      setFormSubmitLoader(false);
      if (response.status == '200') {
        $('#service-modal').modal('hide');
        constants.showSuccess('Service updated successfully');
        getServiceList();
      } else {
        constants.showError(response.message);
      }
    });
  }

  const searchByName = (e: any) => {
    let searchKey = e.target.value;
    if(searchKey.length >= 3 || searchKey.length == 0){
      setSearchKey(searchKey);
    }
  }

  return (
    <>
      <section className="page-header page-header-dark bg-secondary">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-8">
              <h1>My Profile</h1>
            </div>
            <div className="col-md-4">
              <ul className="breadcrumb justify-content-start justify-content-md-end mb-0">
                <li><a href="/">Home</a></li>
                <li className="active">My Profile</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div className="container-fluid p-5 mb-2">
        <div className="row">
          <div className="col-lg-2">
            <ProfileSidebar />
          </div>
          <div className="col-lg-10">
            <div className="bg-white shadow-md rounded p-4">
              <div className='row'>
                <div className='col-lg-6'>
                  <h4 className="mb-2">Services</h4>
                </div>
                <div className='col-lg-6'>
                  <input type="text" placeholder='Search by service name' className='form-control form-control-sm w-50 float-end'
                    onChange={searchByName} />
                </div>
              </div>
              <div className="tab-content my-3" id="myTabContent">
                <div className="tab-pane fade show active" id="first" role="tabpanel" aria-labelledby="first-tab">
                  <div className="table-responsive-md">
                    <table className="table table-hover border">
                      <thead>
                        <tr>
                          <th>Sr No</th>
                          <th>Service</th>
                          <th>Category</th>
                          <th>Base Amount(₹)</th>
                          <th>Minimum Payable(₹)</th>
                          <th>Tax(%)</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {serviceList?.map((ser, index) => (
                          <tr key={index}>
                            <td className="align-middle">{((pageNum - 1) * itemsPerPage) + (index + 1)}</td>
                            <td className="align-middle">{ser.serviceName}</td>
                            <td className="align-middle">{ser.category?.categoryName}</td>
                            <td className="align-middle">{ser.basePriceStr}</td>
                            <td className="align-middle">{ser.minimumPayableStr}</td>
                            <td className="align-middle">{ser.taxPercent}</td>
                            {/* <td className="align-middle">{ser.status ? 'Active': 'Inactive'}</td> */}
                            <td className="align-middle">
                              <button className="featured-box-icon bg-primary text-white rounded-circle border-0 edit-icon"
                                data-bs-toggle="modal" data-bs-target="#service-modal" onClick={() => prepareEditService(ser.serviceId, 
                                  ser.basePrice, ser.minimumPayable, ser.status, ser.serviceName, ser.taxPercent)}>
                                <i className="fas fa-edit"></i>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <ReactPaginate breakLabel="..." nextLabel="next >" onPageChange={handlePageClick} pageRangeDisplayed={5}
                      pageCount={pageCount} previousLabel="< previous" pageClassName="page-item" pageLinkClassName="page-link"
                      previousClassName="page-item" previousLinkClassName="page-link" nextClassName="page-item" nextLinkClassName="page-link"
                      breakClassName="page-item" breakLinkClassName="page-link" containerClassName="pagination" activeClassName="active"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="service-modal" className="modal fade" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content border-0">
            <div className="modal-header">
              <h5 className="modal-title">Service Details</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form id="loginForm" method="post" onSubmit={handleSubmit(editService)}>
              <div className="modal-body py-4 px-0">
                <div className="row">
                  <div className="col-11 col-md-10 mx-auto">
                    <input type="hidden" id="serviceId" {...register("serviceId", { required: true })}/>
                    <div className="mb-3">
                      <h4>{serviceName}</h4>
                    </div>
                    <div className="mb-3">
                      <label className="number" htmlFor="basePrice">Base Price(₹)<span className='star'>*</span></label>
                      <input type="text" className="form-control" id="basePrice" placeholder="Base Price" 
                        {...register("basePrice", { required: true })}/>
                      {errors.basePrice && <span className='text-danger'>Base price is required </span>}
                    </div>
                    <div className="mb-3">
                      <label className="form-label" htmlFor="minimumPayable">Minimum Payable Price(₹) <span className='star'>*</span></label>
                      <input type="number" className="form-control" id="minimumPayable" placeholder="Minimum Payable Price" 
                        {...register("minimumPayable", { required: true })}/>
                      {errors.minimumPayable && <span className='text-danger'>Minimum payable price is required</span>}
                    </div>
                    <div className="mb-3">
                      <label className="form-label" htmlFor="taxPercent">Tax Percent(%) <span className='star'>*</span></label>
                      <input type="number" className="form-control" id="taxPercent" placeholder="Tax Percentage" 
                        {...register("taxPercent", { required: true })}/>
                      {errors.taxPercent && <span className='text-danger'>Tax percentage is required</span>}
                    </div>
                    {/* <div className="mb-3">
                      <input type="checkbox" id="status" {...register("status")}/> Status
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-gray" data-bs-dismiss="modal">Close</button>
                <button type="submit" className="btn btn-primary" disabled={formSubmitLoader}>
                  {formSubmitLoader && <i className="fas fa-circle-notch fa-spin me-2"></i>}
                  Save Changes
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}
