import { BehaviorSubject } from 'rxjs';
declare var $: any;

const BASE_URL = process.env.REACT_APP_API_URL;
const CASHFREE_URL = process.env.REACT_APP_CASHFREE_URL;

const loginSubscriber = new BehaviorSubject<boolean>(false);

const loginStatusService = {
  send: (msg: boolean) => {
    loginSubscriber.next(msg);
  }
}

const showSuccess = (msg: string) => {
  $('#success-alert').html(msg);
  $('#success-alert').show().delay(3000).fadeOut();
}

const showError = (msg: string) => {
  $('#error-alert').html(msg);
  $('#error-alert').show().delay(3000).fadeOut();
}

const getAuthHeader = () => {
  let authHeader = '';
  if(localStorage.getItem('currentUser')){
    const user = JSON.parse(localStorage.getItem('currentUser')!);
    authHeader = 'Bearer ' + user.sessionToken;
  }
  return authHeader;
}

export { BASE_URL, CASHFREE_URL, loginStatusService, loginSubscriber, showSuccess, showError, getAuthHeader }
