import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import ProfileSidebar from '../../ProfileSidebar/ProfileSidebar'
import * as constants from '../../../Constants/Constants'

export default function Category() {

  const [requestBody, setRequestBody] = useState<any>();
  const [categoryList, setCategoyrList] = useState<any[]>();
  const [categoryDDList, setCategoyrDDList] = useState<any[]>();
  const [categoryId, setCategoyrId] = useState<string>();

  useEffect(() => {
    getCategoryList();
    getCategoryDDList();
  }, []);

  const getCategoryList = () => {
    fetch(constants.BASE_URL + 'category?pageNum=1&numPerPage=100', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      if (response.status == '200') {
        setCategoyrList(response.result);
      }
    });
  };

  const getCategoryDDList = () => {
    fetch(constants.BASE_URL + 'category/dropdown', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      if (response.status == '200') {
        setCategoyrDDList(response.result);
      }
    });
  };

  const prepareAddCategory = () => {
    setCategoyrId(undefined);
  }

  const inputChangeHandler = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = event.target
    setRequestBody({ ...requestBody, [name]: value })
  }

  const onSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    requestBody.status = requestBody.statusStr == 'on' ? true : false;
    if(requestBody.parentCategoryId){
      requestBody['parentCategory'] = { categoryId: requestBody.parentCategoryId };
    }
    if(categoryId){
      updateCategory();
    }else{
      fetch(constants.BASE_URL + 'category', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
        body: JSON.stringify(requestBody)
      }).then(function (response) {
        return response.json();
      }).then(function (response: any) {
        if (response.status == '201') {
          constants.showSuccess('Category submitted successfully');
          getCategoryList();
        }
      });
    }
  }

  const updateCategory = () => {
    fetch(constants.BASE_URL + 'category/' + categoryId, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
      body: JSON.stringify(requestBody)
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      if (response.status == '201') {
        constants.showSuccess('Category updated successfully');
        getCategoryList();
      }
    });
  }

  const prepareEditCategory = (categoryId: string) => {
    let category = categoryList?.find((cat) => categoryId == cat.categoryId);
    setCategoyrId(categoryId);
    if(category.parentCategory && category.parentCategory.categoryId){
      category['parentCategoryId'] = category.parentCategory.categoryId;
    }
    setRequestBody(category);
  };

  return (
    <>
      <section className="page-header page-header-dark bg-secondary">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-8">
              <h1>My Profile</h1>
            </div>
            <div className="col-md-4">
              <ul className="breadcrumb justify-content-start justify-content-md-end mb-0">
                <li><a href="index.html">Home</a></li>
                <li className="active">My Profile</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div className="container mb-2">
        <div className="row">
          <div className="col-lg-3">
            <ProfileSidebar />
          </div>
          <div className="col-lg-9">
            <div className="bg-white shadow-md rounded p-4">
              <div className='row'>
                <div className='col-lg-6'>
                  <h4 className="mb-2">Categories</h4>
                </div>
                <div className='col-lg-6'>
                  {/* <button className="featured-box-icon bg-primary text-white rounded-circle float-end border-0"
                    data-bs-toggle="modal" data-bs-target="#category-modal" onClick={() => prepareAddCategory()}>
                    <i className="fas fa-plus"></i>
                  </button> */}
                </div>
              </div>
              <div className="tab-content my-3" id="myTabContent">
                <div className="tab-pane fade show active" id="first" role="tabpanel" aria-labelledby="first-tab">
                  <div className="table-responsive-md">
                    <table className="table table-hover border">
                      <thead>
                        <tr>
                          <th>Sr No</th>
                          <th>Category</th>
                          <th>Parent Category</th>
                          <th>Status</th>
                          {/* <th>Action</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {categoryList?.map((cat, index) => (
                          <tr key={index}>
                            <td className="align-middle">{index + 1}</td>
                            <td className="align-middle">{cat.categoryName}</td>
                            <td className="align-middle">{cat.parentCategory?.categoryName}</td>
                            <td className="align-middle">{cat.status ? 'Active': 'Inactive'}</td>
                            {/* <td className="align-middle">
                              <button className="featured-box-icon bg-primary text-white rounded-circle border-0 edit-icon"
                                data-bs-toggle="modal" data-bs-target="#category-modal" onClick={() => prepareEditCategory(cat.categoryId)}>
                                <i className="fas fa-edit"></i>
                              </button>
                            </td> */}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="category-modal" className="modal fade" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content border-0">
            <div className="modal-header">
              <h5 className="modal-title">Category Details</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form id="loginForm" method="post" onSubmit={onSubmitHandler}>
              <div className="modal-body py-4 px-0">
                <div className="row">
                  <div className="col-11 col-md-10 mx-auto">
                    <div className="mb-3">
                      <label htmlFor="categoryName" className="form-label">Category Name</label>
                      <input type="text" className="form-control" id="categoryName" name='categoryName' onChange={(e)=>inputChangeHandler(e)} 
                        value={requestBody?.categoryName}/>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="parentCategoryId" className="form-label">Parent Category</label>
                      <select className="form-control" id="parentCategoryId" name='parentCategoryId' onChange={(e)=>inputChangeHandler(e)}
                        value={requestBody?.parentCategoryId}>
                        <option disabled>--Select Category--</option>
                        {categoryDDList?.map((catDD, ddindex) => (
                          <option key={ddindex} value={catDD.categoryId}>{catDD.categoryName}</option>  
                        ))}
                      </select>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="description" className="form-label">Description</label>
                      <textarea className="form-control" id="description" name='description' onChange={(e)=>inputChangeHandler(e)} value={requestBody?.description}></textarea>
                    </div>
                    <div className="mb-3 form-check">
                      <input type="checkbox" className="form-check-input" id="statusStr" name='statusStr' onChange={(e)=>inputChangeHandler(e)} 
                        />
                      <label className="form-check-label" htmlFor="statusStr">Status</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-gray" data-bs-dismiss="modal">Close</button>
                <button type="submit" className="btn btn-primary">Save changes</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}
