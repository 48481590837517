import React, { useEffect, useState } from 'react'
import { NavLink, useParams } from 'react-router-dom';
import * as constants from '../../Constants/Constants'

declare var $: any;

export default function Checkout() {

  const { serviceId } = useParams();
  const [currentUser, setCurrentUser] = useState<any>();
  const [serviceDetails, setServiceDetails] = useState<any>();
  const [formSubmitLoader, setFormSubmitLoader] = useState<boolean>(false);
  const [signature, setSignature] = useState<any>();
  const [cashfreeUrl, setCashfreeUrl] = useState<string | undefined>();

  useEffect(() => {
    if (localStorage.getItem('currentUser')) {
      setCurrentUser(JSON.parse(localStorage.getItem('currentUser')!));
    }
    getServiceDetails();
    setCashfreeUrl(constants.CASHFREE_URL);
  }, []);

  const getServiceDetails = () => {
    fetch(constants.BASE_URL + 'services/' + serviceId, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      if (response.status == '200') {
        response.result['basePriceStr'] = response.result?.basePrice?.toLocaleString();
        response.result['minimumPayableStr'] = response.result?.minimumPayable?.toLocaleString();
        response.result['taxPercentStr'] = response.result?.taxPercent?.toLocaleString();
        let taxAmount = (response.result?.taxPercent/100) * response.result?.minimumPayable;
        response.result['taxAmountStr'] = taxAmount?.toLocaleString();
        response.result['totalAmountStr'] = (taxAmount + response.result?.minimumPayable).toLocaleString();
        setServiceDetails(response.result);
      } else {
        constants.showError(response.message);
      }
    });
  }

  const startPayment = () => {
    let data = {
      totalAmount: serviceDetails?.minimumPayable,
      serviceId: serviceDetails?.serviceId,
      user: {
        userId: currentUser?.userId,
        fullName: currentUser?.fullName,
        mobile: currentUser?.mobile,
        email: currentUser?.email
      }
    };
    setFormSubmitLoader(true);
    fetch(constants.BASE_URL + 'service-request', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
      body: JSON.stringify(data)
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      if (response.status == '200') {
        setSignature(response.result);
        setTimeout(() => {
          setFormSubmitLoader(false);
          $('#payment-form').submit();
        }, 1000);
      } else {
        constants.showError(response.message);
      }
    });
  }

  return (
    <>
      <section className="page-header page-header-dark bg-secondary">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-8">
              <h1>Checkout</h1>
            </div>
            <div className="col-md-4">
              <ul className="breadcrumb justify-content-start justify-content-md-end mb-0">
                <li><NavLink to="/">Home</NavLink></li>
                <li className="active">Checkout</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div id="content">
        <section className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="bg-white shadow-md rounded p-3 p-sm-4 confirm-details mb-2">
                <h2 className="text-6 mb-3 mb-sm-4">Confirm Service Details</h2>
                <hr className="mx-n3 mx-sm-n4 mb-4" />
                <div className="card">
                  <div className="card-header">
                    <div className="row align-items-center trip-title">
                      <div className="col-5 col-sm-auto text-center text-sm-start">
                        <h5 className="m-0 trip-place">Service</h5>
                      </div>
                      <div className="col-2 col-sm-auto text-8 text-black-50 text-center trip-arrow">➝</div>
                      <div className="col-5 col-sm-auto text-center text-sm-start">
                        <h5 className="m-0 trip-place">{serviceDetails?.serviceName}</h5>
                      </div>
                      <div className="col-12 mt-1 d-block d-md-none"></div>
                      <div className="col-6 col-sm col-md-auto text-end order-sm-1">
                        {/* <Link className="text-1" to='/private-limited-company'>View Details</Link> */}
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12 col-sm-6 text-center time-info mt-3 mt-sm-0">
                        <span className="text-5 text-dark">₹ {serviceDetails?.basePriceStr}</span>
                        <small className="text-muted d-block">Approximate Cost</small>
                      </div>
                      <div className="col-12 col-sm-6 text-center time-info mt-3 mt-sm-0">
                        <span className="text-3 text-dark">₹ {serviceDetails?.minimumPayableStr}</span>
                        <small className="text-muted d-block">Minimum Payable</small>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card mt-4">
                  <div className="card-header">
                    <div className="row align-items-center trip-title">
                      <div className="col-5 col-sm-auto text-center text-sm-start">
                        <h5 className="m-0 trip-place">Customer</h5>
                      </div>
                      <div className="col-2 col-sm-auto text-8 text-black-50 text-center trip-arrow">➝</div>
                      <div className="col-5 col-sm-auto text-center text-sm-start">
                        <h5 className="m-0 trip-place">{currentUser?.fullName}</h5>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12 col-sm-6 text-center time-info mt-3 mt-sm-0">
                        <span className="text-5 text-dark">{currentUser?.mobile}</span>
                        <small className="text-muted d-block">Mobile</small>
                      </div>
                      <div className="col-12 col-sm-6 text-center time-info mt-3 mt-sm-0">
                        <span className="text-3 text-dark">{currentUser?.email}</span>
                        <small className="text-muted d-block">Email</small>
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <aside className="col-lg-4 mt-4 mt-lg-0">
              <div className="bg-white shadow-md rounded p-3">
                <h3 className="text-5 mb-3">Charges</h3>
                <hr className="mx-n3" />
                <ul className="list-unstyled">
                  <li className="mb-2">Approximate Cost <span className="float-end text-4 fw-500 text-dark">₹ {serviceDetails?.basePriceStr}</span><br /></li>
                  <li className="mb-2">Minimum Payable <span className="float-end text-4 fw-500 text-dark">₹ {serviceDetails?.minimumPayableStr}</span></li>
                  <li className="mb-2">Tax ({serviceDetails?.taxPercentStr})% <span className="float-end text-4 fw-500 text-dark">₹ {serviceDetails?.taxAmountStr}</span></li>
                </ul>
                <div className="text-dark bg-light-4 text-4 fw-600 p-3"> Total Amount To Pay <span className="float-end text-6">₹ {serviceDetails?.totalAmountStr}</span> </div>
                <div className="d-grid mt-2">
                  <button className="btn btn-primary" type="submit" onClick={startPayment} disabled={formSubmitLoader}>
                    {formSubmitLoader && <i className="fas fa-circle-notch fa-spin me-2"></i>}
                    Proceed To Payment
                  </button>
                </div>
              </div>
            </aside>
          </div>
        </section>
      </div>
      <section id="payment-section">
        <form action={cashfreeUrl} name="frm1" method="post" id="payment-form">
          <input type="hidden" name="signature" value={signature?.signature} />
          <input type="hidden" name="orderNote" value={signature?.orderNote} />
          <input type="hidden" name="orderCurrency" value={signature?.currency} />
          <input type="hidden" name="customerName" value={currentUser?.fullName} />
          <input type="hidden" name="customerEmail" value={currentUser?.email} />
          <input type="hidden" name="customerPhone" value={currentUser?.mobile} />
          <input type="hidden" name="orderAmount" value={signature?.orderAmount} />
          <input type="hidden" name="notifyUrl" value={signature?.notifyUrl} />
          <input type="hidden" name="returnUrl" value={signature?.returnUrl} />
          <input type="hidden" name="appId" value={signature?.appId} />
          <input type="hidden" name="orderId" value={signature?.orderId} />
        </form>
      </section>
    </>
  )
}
