import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import ProfileSidebar from '../../ProfileSidebar/ProfileSidebar'
import * as constants from '../../../Constants/Constants'
import { useForm } from 'react-hook-form';

declare var $: any;

export default function EditService() {

  const navigate = useNavigate();
  const { serviceName } = useParams();
  const [formSubmitLoader, setFormSubmitLoader] = useState<boolean>(false);
  const [serviceDetails, setServiceDetails] = useState<any>();

  useEffect(() => {
    getServiceDetails()
  }, []);

  const getServiceDetails = () => {
    fetch(constants.BASE_URL + 'services/details/' + serviceName + '.json', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      setServiceDetails(response.result);
      $('#overview').summernote('code', response.result?.overview);
      $('#documentReq').summernote('code', response.result?.documentReq);
      $('#registrationProcess').summernote('code', response.result?.registrationProcess);
      $('#basePrice').summernote('code', response.result?.basePrice);
      $('#whyCompanySearch').summernote('code', response.result?.whyCompanySearch);
      $('#freqaskQuestions').summernote('code', response.result?.freqaskQuestions);
      $('#benefits').summernote('code', response.result?.benefits);
      $('#requirements').summernote('code', response.result?.requirements);
    });
  };

  const saveChanges = () => {
    setFormSubmitLoader(true);
    let data = {
      "serviceId": serviceDetails.serviceId,
      "serviceName": serviceDetails.serviceName,
      "overview": $('#overview').summernote('code'),
      "documentReq": $('#documentReq').summernote('code'),
      "registrationProcess": $('#registrationProcess').summernote('code'),
      "basePrice": $('#basePrice').summernote('code'),
      "whyCompanySearch": $('#whyCompanySearch').summernote('code'),
      "freqaskQuestions": $('#freqaskQuestions').summernote('code'),
      "benefits": $('#benefits').summernote('code'),
      "requirements": $('#requirements').summernote('code'),
    };
    fetch(constants.BASE_URL + 'services/details/' + serviceDetails.serviceId, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
      body: JSON.stringify(data)
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      setFormSubmitLoader(false);
      if (response.status == '200') {
        constants.showSuccess('Service details updated successfully');
        navigate("/dashboard");
      } else {
        constants.showError(response.message);
      }
    });
  }

  return (
    <>
      <section className="page-header page-header-dark bg-secondary">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-8">
              <h1>My Profile</h1>
            </div>
            <div className="col-md-4">
              <ul className="breadcrumb justify-content-start justify-content-md-end mb-0">
                <li><a href="index.html">Home</a></li>
                <li className="active">My Profile</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div className="container mb-2">
        <div className="row">
          <div className="col-lg-3">
            <ProfileSidebar />
          </div>
          <div className="col-lg-9">
            <div className="bg-white shadow-md rounded p-4">
              <div className='row'>
                <div className='col-lg-6'>
                  <h4 className="mb-2">{serviceDetails?.serviceName}</h4>
                </div>
                <div className='col-lg-6'>

                </div>
              </div>
              <div className="tab-content my-3" id="myTabContent">
                <div className="tab-pane fade show active" id="first" role="tabpanel" aria-labelledby="first-tab">
                  <div className="mb-3">
                    <label className="form-label fw-bold fs-5" htmlFor="overview">Overview </label>
                    <textarea className="form-control" id="overview" placeholder='Overview'></textarea>
                  </div>
                  <div className="mb-3">
                    <label className="form-label fw-bold fs-5" htmlFor="documentReq">Document Required </label>
                    <textarea className="form-control" id="documentReq" placeholder='Document Required'></textarea>
                  </div>
                  <div className="mb-3">
                    <label className="form-label fw-bold fs-5" htmlFor="registrationProcess">Registration Process </label>
                    <textarea className="form-control" id="registrationProcess" placeholder='Registration Process'></textarea>
                  </div>
                  <div className="mb-3">
                    <label className="form-label fw-bold fs-5" htmlFor="basePrice">Base Price </label>
                    <textarea className="form-control" id="basePrice" placeholder='Base Price'></textarea>
                  </div>
                  <div className="mb-3">
                    <label className="form-label fw-bold fs-5" htmlFor="whyCompanySearch">Why Company Search </label>
                    <textarea className="form-control" id="whyCompanySearch" placeholder='Why Company Search'></textarea>
                  </div>
                  <div className="mb-3">
                    <label className="form-label fw-bold fs-5" htmlFor="freqaskQuestions">Frequently Asked Questions </label>
                    <textarea className="form-control" id="freqaskQuestions" placeholder='Frequently Asked Questions'></textarea>
                  </div>
                  <div className="mb-3">
                    <label className="form-label fw-bold fs-5" htmlFor="benefits">Benefits </label>
                    <textarea className="form-control" id="benefits" placeholder='Benefits'></textarea>
                  </div>
                  <div className="mb-3">
                    <label className="form-label fw-bold fs-5" htmlFor="requirements">Requirements </label>
                    <textarea className="form-control" id="requirements" placeholder='Requirements'></textarea>
                  </div>
                  <div className="mt-4 mb-3">
                    <button type="button" className="btn btn-primary" onClick={saveChanges} disabled={formSubmitLoader}>
                      {formSubmitLoader && <i className="fas fa-circle-notch fa-spin me-2"></i>}
                      Save changes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
