import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import ProfileSidebar from '../ProfileSidebar/ProfileSidebar'
import * as constants from '../../Constants/Constants'
import './AddressDetails.css'

export default function AddressDetails() {

  const [currentUser, setCurrentUser] = useState<any>();
  const { register, handleSubmit, setValue, formState: { errors } } = useForm();
  const [formSubmitLoader, setFormSubmitLoader] = useState<boolean>(false);

  useEffect(() => {
    if(localStorage.getItem('currentUser')){
      let userData = JSON.parse(localStorage.getItem('currentUser')!);
      setCurrentUser(userData);
      if(userData.address){
        setTimeout(() => {
          setValue('addressLineOne', userData?.address?.addressLineOne, { shouldDirty: true });
          setValue('addressLineTwo', userData?.address?.addressLineTwo, { shouldDirty: true });
          setValue('city', userData?.address?.city, { shouldDirty: true });
          setValue('state', userData?.address?.state, { shouldDirty: true });
          setValue('zipCode', userData?.address?.zipCode, { shouldDirty: true });
          setValue('country', userData?.address?.country, { shouldDirty: true });
        }, 500);
      }
    }
  }, []);

  const updateProfile = (data: any) => {
    setFormSubmitLoader(true);
    fetch(constants.BASE_URL + 'user/address/' + currentUser.userId, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
      body: JSON.stringify(data)
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      setFormSubmitLoader(false);
      if (response.status == '200') {
        let userData = currentUser;
        userData.address = response.result;
        localStorage.setItem('currentUser', JSON.stringify(userData));
        constants.showSuccess('Address details updated successfully');
        constants.loginSubscriber.next(true);
      } else {
        constants.showError(response.message);
      }
    });
  }

  return (
    <>
      <section className="page-header page-header-dark bg-secondary">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-8">
              <h1>My Profile</h1>
            </div>
            <div className="col-md-4">
              <ul className="breadcrumb justify-content-start justify-content-md-end mb-0">
                <li><a href="/">Home</a></li>
                <li className="active">My Profile</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div className="container mb-2">
        <div className="row">
          <div className="col-lg-3">
            <ProfileSidebar />
          </div>
          <div className="col-lg-9">
            <div className="bg-white shadow-md rounded p-4">
              <h4 className="mb-4">Address Details</h4>
              <hr className="mx-n4 mb-4" />
              <div className="row">
                <div className="col-lg-8">
                  <form id="personalInformation" method="post" onSubmit={handleSubmit(updateProfile)}>
                    <div className="mb-3">
                      <label className="form-label" htmlFor="addressLineOne">Address Line 1<span className='star'>*</span> </label>
                      <input type="text" className="form-control" id="addressLineOne" placeholder="Address Line 1" 
                        {...register("addressLineOne", { required: true })}/>
                      {errors.addressLineOne && <span className='text-danger'>Address Line 1 is mandatory</span>}
                    </div>
                    <div className="mb-3">
                      <label className="form-label" htmlFor="addressLineTwo">Address Line 2</label>
                      <input type="text" className="form-control" id="addressLineTwo" placeholder="Address Line 2"
                        {...register("addressLineTwo")}/>
                    </div>
                    <div className="mb-3">
                      <label className="form-label" htmlFor="city">City <span className='star'>*</span></label>
                      <input type="text" className="form-control" id="city" placeholder="City"
                        {...register("city", { required: true })}/>
                      {errors.city && <span className='text-danger'>City is required</span>}
                    </div>
                    <div className="mb-3">
                      <label className="form-label" htmlFor="state">State<span className='star'>*</span> </label>
                      <input type="text" className="form-control" id="state" placeholder="State"
                        {...register("state", { required: true })}/>
                      {errors.state && <span className='text-danger'>State is required</span>}
                    </div>
                    <div className="mb-3">
                      <label className="form-label" htmlFor="zipCode">Zip Code <span className='star'>*</span></label>
                      <input type="text" className="form-control" id="zipCode" placeholder="Zip Code"
                        {...register("zipCode", { required: true, pattern:/^[1-9][0-9]{5}$/ , minLength:6, maxLength:6 })}/>
                      {errors.zipCode && <span className='text-danger'> Valid Zip Code is required</span>}
                    </div>
                    <div className="mb-3">
                      <label className="form-label" htmlFor="country">Country<span className='star'>*</span></label>
                      <input type="text" className="form-control" id="country" placeholder="Country"
                        {...register("country", { required: true })}/>
                      {errors.country && <span className='text-danger'>Country is required</span>}
                    </div>
                    <button className="btn btn-primary" type="submit" disabled={formSubmitLoader}>
                      {formSubmitLoader && <i className="fas fa-circle-notch fa-spin me-2"></i>}
                      Update Now
                    </button>
                  </form>
                </div>
                <div className="col-lg-4 mt-4 mt-lg-0 ">
                  <div className="bg-light-2 rounded p-4">
                    <h3 className="text-4 mb-2">We value your Privacy</h3>
                    <p className="mb-0">We will not sell or distribute your contact information. Read our <a
                      href="#">Privacy Policy</a>.</p>
                    <hr className="mx-n4" />
                    <h3 className="text-4 mb-3">Billing Enquiries</h3>
                    <p className="mb-0">Do not hesitate to reach our <a href="#">support team</a> if you have any queries.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
