import Footer from './Component/Footer/Footer';
import Header from './Component/Header/Header';
import { BrowserRouter } from "react-router-dom";

import Routing from './Routing/Routing';
import Login from './Component/Login/Login';
import SignUp from './Component/SignUp/SignUp';
import ForgotPassword from './Component/ForgotPassword/ForgotPassword';

function App() {
  return (
    <>
      <BrowserRouter>
        <Header />
        <div id="content">
          <Routing />
        </div>
        <Footer />
        <Login />
        <SignUp />
        <ForgotPassword />
      </BrowserRouter>
    </>
  );
}

export default App;
