import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import ProfileSidebar from '../../ProfileSidebar/ProfileSidebar'
import * as constants from '../../../Constants/Constants'
import { useForm } from 'react-hook-form';
import ReactPaginate from 'react-paginate';

declare var $: any;

export default function ComplianceManagement() {

  const { register, handleSubmit, setValue, formState: { errors } ,reset} = useForm();
  const [formSubmitLoader, setFormSubmitLoader] = useState<boolean>(false);
  const [complianceList, setComplianceList] = useState<any[]>();
  const [compliance, setCompliance] = useState<any>();

  // VARIABLES FOR PAGINATION
  const [pageCount, setPageCount] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchKey, setSearchKey] = useState<string | null>(null);

  useEffect(() => {
    getComplianceList();
  }, [pageNum, searchKey]);

  const getComplianceList = () => {
    fetch(constants.BASE_URL + 'compliance-calendar?pageNum=' + pageNum + '&numPerPage=' + itemsPerPage+ (searchKey && searchKey.length > 2 ? '&searchBy=' + searchKey : ''), {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader(), 'Access-Control-Allow-Origin': '*' },
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      if (response.status == '200') {
        setComplianceList(response.result);
        setPageCount(Math.ceil(response.listCount / itemsPerPage));
      }
    });
  }

  const handlePageClick = (event: any) => {
    setPageNum(event.selected + 1);
  };

  const prepareAddBlog = () => {
    reset()
    $('#compliance-modal').modal('show');
    
  }

  const prepareEditBlog = (blog: any) => {
    reset();
    $('#compliance-modal').modal('show');
    setCompliance(blog);
    setTimeout(() => {
      setValue('complianceCalendarId', blog.complianceCalendarId)
      setValue('complianceYear', blog.complianceYear);
      setValue('form', blog.form, { shouldDirty: true });
      setValue('description', blog.description, { shouldDirty: true });
      setValue('dueDate', blog.dueDate, { shouldDirty: true });
      setValue('period', blog.period, { shouldDirty: true });
    }, 500);
  }

  const deleteCompliance = (complianceCalendarId: any) => {
    fetch(constants.BASE_URL + 'compliance-calendar/' + complianceCalendarId, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      if (response.status == '200') {
        constants.showSuccess('Compliance deleted  successfully');
        setCompliance(null);
        getComplianceList();
      } else {
        constants.showError(response.message);
      }
    });
  }

  const editBlog = (data: any) => {
    debugger
    setFormSubmitLoader(true);
    if (compliance && compliance.complianceCalendarId && compliance.complianceCalendarId.length > 0) {
      fetch(constants.BASE_URL + 'compliance-calendar/' + compliance.complianceCalendarId, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
        body: JSON.stringify(data)
      }).then(function (response) {
        return response.json();
      }).then(function (response: any) {
        setFormSubmitLoader(false);
        if (response.status == '200') {
          $('#compliance-modal').modal('hide');
          constants.showSuccess('Compliance updated successfully');
          setCompliance(null);
          getComplianceList();
        } else {
          constants.showError(response.message);
          setFormSubmitLoader(false);
        }
      });
    }else {
      delete data.complianceCalendarId;
      fetch(constants.BASE_URL + 'compliance-calendar', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
        body: JSON.stringify(data)
      }).then(function (response) {
        return response.json();
      }).then(function (response: any) {
        setFormSubmitLoader(false);
        if (response.status == '201') {
          $('#compliance-modal').modal('hide');
          constants.showSuccess('Compliance created successfully');
          setCompliance(null);
          getComplianceList();
        } else {
          constants.showError(response.message);
          setFormSubmitLoader(false);
        }
      });
    }
  }

  const searchByName = (e: any) => {
    let searchKey = e.target.value;
    if(searchKey.length >= 3 || searchKey.length == 0){
      setSearchKey(searchKey);
    }
  }

  return (
    <>
      <section className="page-header page-header-dark bg-secondary">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-8">
              <h1>My Profile</h1>
            </div>
            <div className="col-md-4">
              <ul className="breadcrumb justify-content-start justify-content-md-end mb-0">
                <li><a href="/">Home</a></li>
                <li className="active">My Profile</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div className="container-fluid p-5 mb-2">
        <div className="row">
          <div className="col-lg-2">
            <ProfileSidebar />
          </div>
          <div className="col-lg-10">
            <div className="bg-white shadow-md rounded p-4">
              <div className='row'>
                <div className='col'>
                  <h4 className="mb-2">Compliance
                    <button className="featured-box-icon bg-primary text-white rounded-circle border-0 ms-2"
                      onClick={prepareAddBlog}>
                      <i className="fas fa-plus" style={{ fontSize: '20px' }}></i>
                    </button>
                  </h4>
                </div>
                <div className='col'>
                  <input type="text" placeholder='Search by form name' className='form-control form-control-sm w-50 float-end'
                    onChange={searchByName} />
                </div>
              </div>
              <div className="tab-content my-3" id="myTabContent">
                <div className="tab-pane fade show active" id="first" role="tabpanel" aria-labelledby="first-tab">
                  <div className="table-responsive-md">
                    <table className="table table-hover border">
                      <thead>
                        <tr>
                          <th>Sr. No</th>
                          <th>Year</th>
                          <th>Form</th>
                          <th>Due Date</th>
                          <th>Period</th>
                          <th>Description</th>
                          <th style={{minWidth: '100px'}}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {complianceList?.map((item, index) => (
                          <tr key={index}>
                            <td className="align-middle">{((pageNum - 1) * itemsPerPage) + (index + 1)}</td>
                            <td className="align-middle">{item.complianceYear}</td>
                            <td className="align-middle">{item.form}</td>
                            <td className="align-middle">{item.dueDate}</td>
                            <td className="align-middle">{item.period}</td>
                            <td className="align-middle">{item.description}</td>
                            <td className="align-middle" style={{minWidth: '100px'}}>
                              <button className="featured-box-icon bg-primary text-white rounded-circle border-0 edit-icon"
                                data-bs-toggle="modal" data-bs-target="#compliance-modal" onClick={() => prepareEditBlog(item)}>
                                <i className="fas fa-edit"></i>
                              </button>&nbsp;
                              <button className="featured-box-icon bg-primary text-white rounded-circle border-0 edit-icon"
                                data-bs-toggle="modal" data-bs-target="#compliance-delete" onClick={() => deleteCompliance(item.complianceCalendarId)}>
                                <i className="fa fa-trash "></i>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <ReactPaginate breakLabel="..." nextLabel="next >" onPageChange={handlePageClick} pageRangeDisplayed={5}
                      pageCount={pageCount} previousLabel="< previous" pageClassName="page-item" pageLinkClassName="page-link"
                      previousClassName="page-item" previousLinkClassName="page-link" nextClassName="page-item" nextLinkClassName="page-link"
                      breakClassName="page-item" breakLinkClassName="page-link" containerClassName="pagination" activeClassName="active"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="compliance-modal" className="modal fade" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content border-0">
            <div className="modal-header">
              <h5 className="modal-title">Compliance Details</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form id="loginForm" method="post" onSubmit={handleSubmit(editBlog)}>
              <div className="modal-body py-4 px-0">
                <div className="row">
                  <div className="col-11 col-md-10 mx-auto">
                    <input type="hidden" id="complianceCalendarId" {...register("complianceCalendarId")} />
                    <div className="mb-3">
                      <label className="number" htmlFor="complianceYear">Compliance Year <span className='star'>*</span></label>
                      <select className="form-control" id="complianceYear" {...register("complianceYear", { required: true })}>
                        <option>2022-23</option>
                        <option>2023-24</option>
                      </select>
                      {errors.heading && <span className='text-danger'>Compliance Year is required</span>}
                    </div>
                    <div className="mb-3">
                      <label className="form-label" htmlFor="form">Form <span className='star'>*</span></label>
                      <input type="text" className="form-control" id="form" placeholder="Form Name"
                        {...register("form", { required: true })} />
                      {errors.form && <span className='text-danger'>Enter valid form name</span>}
                    </div>
                    <div className="mb-3">
                      <label className="form-label" htmlFor="dueDate">Due Date <span className='star'>*</span></label>
                      <input type="date" className="form-control" id="dueDate" placeholder="Due Date"
                        {...register("dueDate", { required: true })} />
                      {errors.dueDate && <span className='text-danger'>dueDate is required</span>}
                    </div>
                    <div className="mb-3">
                      <label className="form-label" htmlFor="period">Period <span className='star'>*</span></label>
                      <input type="year" className="form-control" id="period" placeholder="Period"
                        {...register("period", { required: true })} />
                      {errors.period && <span className='text-danger'>Year is required.</span>}
                    </div>
                    <div className="mb-3">
                      <label className="form-label" htmlFor="Description">Description <span className='star'>*</span></label>
                      <textarea className="form-control" id="description" placeholder='Description' rows={3}
                        {...register("description", { required: true })}></textarea>
                      {errors.description && <span className='text-danger'>Description is required</span>}
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-gray" data-bs-dismiss="modal">Close</button>
                <button type="submit" className="btn btn-primary" disabled={formSubmitLoader}>
                  {formSubmitLoader && <i className="fas fa-circle-notch fa-spin me-2"></i>}
                  Save changes
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}
