import React, { useEffect, useState } from 'react'
import ProfileSidebar from '../ProfileSidebar/ProfileSidebar'
import * as constants from '../../Constants/Constants'
import ReactPaginate from 'react-paginate';
declare var $: any;

export default function MyServices() {

  const [cashfreeUrl, setCashfreeUrl] = useState<string | undefined>();
  const [serviceRequestList, setServiceRequestList] = useState<any[]>();
  const [currentUser, setCurrentUser] = useState<any>();
  const [serviceRequestId, setServiceRequestId] = useState<string>();
  const [signature, setSignature] = useState<any>();

  // VARIABLES FOR PAGINATION
  const [pageCount, setPageCount] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchKey, setSearchKey] = useState<string | null>(null);
  const [status, setStatus] = useState<string | null>(null);

  const [categoryList, setCategoryList] = useState<any[]>();
  const [subCategoryList, setSubCategoryList] = useState<any[]>();
  const [categoryId, setCategoryId] = useState<string | null>();
  const [subCategoryId, setSubCategoryId] = useState<string | null>();

  useEffect(() => {
    getCategoryList();
  }, []);

  useEffect(() => {
    setCashfreeUrl(constants.CASHFREE_URL);
    if (localStorage.getItem('currentUser')) {
      let userData = JSON.parse(localStorage.getItem('currentUser')!);
      setCurrentUser(userData);
      getServiceRequestList(userData.userId);
    }
  }, [pageNum, searchKey, status, categoryId, subCategoryId]);

  const getCategoryList = () => {
    fetch(constants.BASE_URL + 'category/dropdown', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      if (response.status == '200') {
        setCategoryList(response.result);
      } else {
        constants.showError(response.message);
      }
    });
  }

  const onCategorySelect = (e: any) => {
    setCategoryId(e.target.value);
    fetch(constants.BASE_URL + 'category/sub/dropdown/' + e.target.value, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      if (response.status == '200') {
        setSubCategoryList(response.result);
      } else {
        constants.showError(response.message);
      }
    });
  }

  const onSubCategorySelect = (e: any) => {
    setSubCategoryId(e.target.value);
  }

  const getServiceRequestList = (userId: string) => {
    let queryParams = '?pageNum=' + pageNum + '&numPerPage=' + itemsPerPage + (searchKey && searchKey.length > 2 ? '&searchBy=' + searchKey : '')
      + (status && status.length > 0 ? '&status=' + status : '')
      + (categoryId && categoryId.length > 0 && (!subCategoryId || subCategoryId.length == 0) ? '&categoryId=' + categoryId : '')
      + (subCategoryId && subCategoryId.length > 0 ? '&subCategoryId=' + subCategoryId : '');
    fetch(constants.BASE_URL + 'service-request/user/' + userId + queryParams, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      if (response.status == '200') {
        response.result.forEach((element: any) => {
          element['serviceAmountStr'] = element.serviceAmount?.toLocaleString();
          element['totalAmountStr'] = element.totalAmount?.toLocaleString();
          element['paidAmountStr'] = element.paidAmount?.toLocaleString();
        });
        setServiceRequestList(response.result);
        setPageCount(Math.ceil(response.listCount / itemsPerPage));
      } else {
        constants.showError(response.message);
      }
    });
  }

  const handlePageClick = (event: any) => {
    setPageNum(event.selected + 1);
  };

  const payPendingPayment = (serviceRequestId: string) => {
    setServiceRequestId(serviceRequestId);
    fetch(constants.BASE_URL + 'service-request/pending-payment/' + serviceRequestId, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      if (response.status == '200') {
        setSignature(response.result);
        setTimeout(() => {
          $('#payment-form').submit();
        }, 1000);
      } else {
        constants.showError(response.message);
      }
    });
  }

  const searchByService = (e: any) => {
    let searchKey = e.target.value;
    if (searchKey.length >= 3 || searchKey.length == 0) {
      setSearchKey(searchKey);
    }
  }

  const searchByStatus = (status: string | null) => {
    setStatus(status);
  }

  return (
    <>
      <section className="page-header page-header-dark bg-secondary">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-8">
              <h1>My Profile</h1>
            </div>
            <div className="col-md-4">
              <ul className="breadcrumb justify-content-start justify-content-md-end mb-0">
                <li><a href="/">Home</a></li>
                <li className="active">My Profile</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div className="container-fluid p-5 mb-2">
        <div className="row">
          <div className="col-lg-2">
            <ProfileSidebar />
          </div>
          <div className="col-lg-10">
            <div className="bg-white shadow-md rounded p-4">
              <h4 className="mb-4">Service Requests</h4>
              <hr className="mx-n4" />
              <ul className="nav nav-tabs mb-4 position-relative" id="myTab" role="tablist">
                <div className='col-md-1.5 col-sm-1.5 col-xs-2'>
                  <li className="nav-item cursor-pointer" onClick={() => searchByStatus(null)}> <a className={"nav-link" + (!status ? ' active' : '')} id="first-tab">All</a></li>
                </div>
                <div className='col-md-1.5 col-sm-1.5 col-xs-2'>
                  <li className="nav-item cursor-pointer" onClick={() => searchByStatus('PARTIALLY_PAID')}> <a className={"nav-link" + (status == 'PARTIALLY_PAID' ? ' active' : '')} id="second-tab">Ongoing</a> </li>
                </div>
                <div className='col-md-1.5 col-sm-1.5 col-xs-2'>
                  <li className="nav-item cursor-pointer" onClick={() => searchByStatus('PAYMENT_AWAITED')}> <a className={"nav-link" + (status == 'PAYMENT_AWAITED' ? ' active' : '')} id="third-tab">Payment Awaited</a> </li>
                </div>
                <div className='col-md-1.5 col-sm-1.5 col-xs-2'>
                  <li className="nav-item cursor-pointer" onClick={() => searchByStatus('PAID')}> <a className={"nav-link" + (status == 'PAID' ? ' active' : '')} id="fourth-tab">Paid</a> </li>
                </div>
                <div className='col-md-1.5 col-sm-1.5 col-xs-2'>
                  <li className="nav-item cursor-pointer" onClick={() => searchByStatus('CLOSED')}> <a className={"nav-link" + (status == 'CLOSED' ? ' active' : '')} id="fifth-tab">Closed</a> </li>
                </div>
                <div className='col-md-1.5 col-sm-1.5 col-xs-2'>
                  <li className="nav-item cursor-pointer" onClick={() => searchByStatus('PENDING')}> <a className={"nav-link" + (status == 'PENDING' ? ' active' : '')} id="sixth-tab">Failed</a> </li>
                </div>
                <div className='col-md-2 col-sm-2 col-xs-2'>
                </div>
                <div className='col-md-2 col-sm-2 col-xs-3'>
                  {/* <li className="nav-item pt-2">
                    <input type="text" className='form-control form-control-sm position-absolute w-25'
                      placeholder='Search by Service Name' onChange={searchByService} />
                  </li> */}
                </div>
              </ul>
              <div className="tab-content my-3" id="myTabContent">
                <div className="tab-pane fade show active" id="first" role="tabpanel" aria-labelledby="first-tab">
                <div className='row'>
                    <div className='col-sm-12 col-md-4 form-group'>
                      <label className='form-label'>Category</label>
                      <select className='form-control form-control-sm' onChange={onCategorySelect}>
                        <option value="">Select Category</option>
                        {categoryList?.map((cat, index) => (
                          <option key={index} value={cat.categoryId}>{cat.categoryName}</option>
                        ))}
                      </select>
                    </div>
                    <div className='col-sm-12 col-md-4'>
                      <label className='form-label'>Sub Category</label>
                      <select className='form-control form-control-sm' onChange={onSubCategorySelect}>
                        <option value="">Select Sub Category</option>
                        {subCategoryList?.map((subCat, index) => (
                          <option key={index} value={subCat.categoryId}>{subCat.categoryName}</option>
                        ))}
                      </select>
                    </div>
                    <div className='col-sm-12 col-md-4'>
                      <label className='form-label'>Search by Service Name</label>
                      <input type="text" className='form-control form-control-sm'
                        placeholder='Search by Service Name' onChange={searchByService} />
                    </div>
                  </div>
                  <div className="table-responsive-md mt-4">
                    <table className="table table-hover border">
                      <thead>
                        <tr>
                          <th>Sr No</th>
                          <th>Service</th>
                          <th>Request Date</th>
                          <th>Amount(₹)</th>
                          <th>Tax(%)</th>
                          <th>Total Amount(₹)</th>
                          <th>Paid Amount(₹)</th>
                          <th>Status</th>
                          <th>Comments</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {serviceRequestList?.map((ser, index) => (
                          <tr key={index}>
                            <td className="align-middle">{((pageNum - 1) * itemsPerPage) + (index + 1)}</td>
                            <td className="align-middle">{ser?.service?.serviceName}</td>
                            <td className="align-middle">{ser?.requestDate}</td>
                            <td className="align-middle">{ser?.serviceAmountStr}</td>
                            <td className="align-middle">{ser?.taxPercent}</td>
                            <td className="align-middle">{ser?.totalAmountStr}</td>
                            <td className="align-middle">{ser?.paidAmountStr}</td>
                            <td className="align-middle">{ser?.status}</td>
                            <td className="align-middle">{ser?.comments}</td>
                            <td className="align-middle">
                              {ser?.status == 'PAYMENT_AWAITED' &&
                                <button className='btn btn-outline-primary btn-sm' style={{ padding: '0.1rem 1rem' }}
                                  disabled={ser.serviceRequestId == serviceRequestId}
                                  onClick={() => payPendingPayment(ser.serviceRequestId)}>
                                  {ser.serviceRequestId == serviceRequestId && <i className="fas fa-circle-notch fa-spin me-2"></i>}
                                  Pay
                                </button>
                              }
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <ReactPaginate breakLabel="..." nextLabel="next >" onPageChange={handlePageClick} pageRangeDisplayed={5}
                      pageCount={pageCount} previousLabel="< previous" pageClassName="page-item" pageLinkClassName="page-link"
                      previousClassName="page-item" previousLinkClassName="page-link" nextClassName="page-item" nextLinkClassName="page-link"
                      breakClassName="page-item" breakLinkClassName="page-link" containerClassName="pagination" activeClassName="active"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section id="payment-section" style={{ display: 'none' }}>
        <form action={cashfreeUrl} name="frm1" method="post" id="payment-form">
          <input type="hidden" name="signature" value={signature?.signature} />
          <input type="hidden" name="orderNote" value={signature?.orderNote} />
          <input type="hidden" name="orderCurrency" value={signature?.currency} />
          <input type="hidden" name="customerName" value={currentUser?.fullName} />
          <input type="hidden" name="customerEmail" value={currentUser?.email} />
          <input type="hidden" name="customerPhone" value={currentUser?.mobile} />
          <input type="hidden" name="orderAmount" value={signature?.orderAmount} />
          <input type="hidden" name="notifyUrl" value={signature?.notifyUrl} />
          <input type="hidden" name="returnUrl" value={signature?.returnUrl} />
          <input type="hidden" name="appId" value={signature?.appId} />
          <input type="hidden" name="orderId" value={signature?.orderId} />
        </form>
      </section>
    </>
  )
}
