import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import './ProfileSidebar.css'

declare var $: any;

export default function ProfileSidebar() {

  let navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState<any>();

  useEffect(() => {
    if (localStorage.getItem('currentUser')) {
      setCurrentUser(JSON.parse(localStorage.getItem('currentUser')!));
    } else {
      navigate('/');
    }

  }, []);

  const toggleSidebar = () => {
    $('.mobile-sidebar .sidebar-section').toggleClass('show');
  }

  return (
    <>
      <section className='mobile-sidebar pb-2'>
        <button className="btn btn-primary btn-sm" type="button" onClick={toggleSidebar}>
          <span className="fas fa-bars"></span>
          <span className='ms-3'>Menu</span>
        </button>
        <ul className='sidebar-section'>
          <li className="nav-item">
            <NavLink className="nav-link" to='/dashboard'>
              <span className="me-2"><i className="fa fa-chart-area"></i></span>
              Dashboard
            </NavLink>
          </li>
          {currentUser && currentUser.role?.name == 'CUSTOMER' &&
            <>
              <li className="nav-item">
                <NavLink className="nav-link" to="/my-services">
                  <span className="me-2"><i className="fas fa-history"></i></span>
                  My Services
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/my-payments">
                  <span className="me-2"><i className="fas fa-credit-card"></i></span>
                  My Payments
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/compliance-calendar">
                  <span className="me-2"><i className="fas fa-calendar"></i></span>
                  Compliance Calendar
                </NavLink>
              </li>
            </>
          }
          {currentUser && currentUser.role?.name == 'ADMIN' &&
            <>
              <li className="nav-item">
                <NavLink className="nav-link" to="/service">
                  <span className="me-2"><i className="fas fa-archive"></i></span>
                  Services
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/service-requests">
                  <span className="me-2"><i className="fas fa-cart-arrow-down"></i></span>
                  Service Requests
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/enquiries">
                  <span className="me-2"><i className="fas fa-question-circle"></i></span>
                  Enquiries
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/blog-management">
                  <span className="me-2"><i className="fas fa-blog"></i></span>
                  Blogs
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/compliance-management">
                  <span className="me-2"><i className="fas fa-book"></i></span>
                  Compliances
                </NavLink>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/customer-interests">
                    <span className="me-2"><i className="fas fa-thumbs-up"></i></span>
                    Customer Interests
                  </NavLink>
                </li>
              </li>
            </>
          }
        </ul>
      </section>
      <ul className="nav nav-pills alternate flex-lg-column mb-3 mb-lg-0 sticky-top desktop-sidebar" style={{ zIndex: 899, top: '80px' }}>
        <li className="nav-item">
          <NavLink className="nav-link" to='/dashboard'>
            <span className="me-2"><i className="fa fa-chart-area"></i></span>
            Dashboard
          </NavLink>
        </li>
        {currentUser && currentUser.role?.name == 'CUSTOMER' &&
          <>
            <li className="nav-item">
              <NavLink className="nav-link" to="/my-services">
                <span className="me-2"><i className="fas fa-history"></i></span>
                My Services
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/my-payments">
                <span className="me-2"><i className="fas fa-credit-card"></i></span>
                My Payments
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/compliance-calendar">
                <span className="me-2"><i className="fas fa-calendar"></i></span>
                Compliance Calendar
              </NavLink>
            </li>
          </>
        }
        {currentUser && currentUser.role?.name == 'ADMIN' &&
          <>
            <li className="nav-item">
              <NavLink className="nav-link" to="/service">
                <span className="me-2"><i className="fas fa-archive"></i></span>
                Services
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/service-requests">
                <span className="me-2"><i className="fas fa-cart-arrow-down"></i></span>
                Service Requests
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/enquiries">
                <span className="me-2"><i className="fas fa-question-circle"></i></span>
                Enquiries
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/blog-management">
                <span className="me-2"><i className="fas fa-blog"></i></span>
                Blogs
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/compliance-management">
                <span className="me-2"><i className="fas fa-book"></i></span>
                Compliances
              </NavLink>
              <li className="nav-item">
                <NavLink className="nav-link" to="/customer-interests">
                  <span className="me-2"><i className="fas fa-thumbs-up"></i></span>
                  Customer Interests
                </NavLink>
              </li>
            </li>
          </>
        }
      </ul>
    </>
  )
}
