import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import * as constants from '../../Constants/Constants'

declare var $: any;

export default function () {

  const [formSubmitLoader, setFormSubmitLoader] = useState<boolean>(false);
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const [user, setUser] = useState<any>();
  const [enableOtp, setEnableOtp] = useState<boolean>(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const[eye,setEye] = useState(false);

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
    setEye(eye ? false:true)
  };

  const forgotPassword = (data: any) => {
    if (data.otp && data.otp.length > 0) {
      resetPassword(data);
    } else {
      setFormSubmitLoader(true);
      fetch(constants.BASE_URL + 'user/forgot-password', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
        body: JSON.stringify(data)
      }).then(function (response) {
        return response.json();
      }).then(function (response: any) {
        setFormSubmitLoader(false);
        if (response.status == '200') {
          constants.showSuccess(response.message);
          setEnableOtp(true);
          setUser({userId: response.result, email: data.email});
        } else {
          constants.showError(response.message);
        }
      });
    }
  }

  const resetPassword = (data: any) => {
    setFormSubmitLoader(true);
    data['userId'] = user.userId;
    fetch(constants.BASE_URL + 'user/reset-password', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
      body: JSON.stringify(data)
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      setFormSubmitLoader(false);
      if (response.status == '200') {
        constants.showSuccess(response.message);
        setEnableOtp(false);
        setUser(null);
        $('#forgot-password-modal').modal('hide');
      } else {
        constants.showError(response.message);
      }
    });
  }

  const resendOtp = () => {
    forgotPassword({email: user.email});
  }

  return (
    <div id="forgot-password-modal" className="modal fade" role="dialog" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content border-0">
          <div className="modal-body py-4 px-0">
            <button type="button" className="btn-close position-absolute top-0 end-0 mt-1 me-1 mt-sm-2 me-sm-2" data-bs-dismiss="modal" aria-label="Close"></button>
            <div className="row">
              <div className="col-11 col-md-10 mx-auto">
                <h3 className="text-center mt-3 mb-4">Forgot Password</h3>
                <p className="text-center text-3 text-muted">Enter your Email and we’ll help you reset your password.</p>
                <form className="form-border" method="post" onSubmit={handleSubmit(forgotPassword)}>
                  <div className="mb-3">
                    <input type="email"  className="form-control" id="emailAddress" placeholder="Enter Email"  disabled={enableOtp}
                      {...register("email", { required: true , pattern:/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/})} />
                    {errors.email && <span className='text-danger'>Please enter valid email</span>}
                  </div>
                  {enableOtp &&
                    <>
                      <div className="mb-3">
                        <input type="number" className="form-control" id="otp" placeholder="Enter OTP"
                          {...register("otp", { required: true })} />
                        {errors.otp && <span className='text-danger'>Please enter otp</span>}
                      </div>
                      <div className="mb-3 ">
                        <div className='pass-wrapper'>
                          <input type={passwordShown? "text" : "password"} className="form-control" id="password" placeholder="New Password"
                            {...register("password", { required: true, pattern:/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{5,}$/ , maxLength:40 })} />
                              <span><i  onClick={togglePasswordVisiblity} className={`fa ${eye ? "fa-eye-slash" : "fa-eye" } passwordhide`} aria-hidden="true"></i></span>
                        </div>
                        
                        {errors.password && <span className='text-danger'>Must contain minimum five characters, at least one uppercase letter, one lowercase letter and one number.</span>}
                      </div>
                    </>
                  }
                  <div className="d-grid my-4">
                    <button className="btn btn-primary" type="submit" disabled={formSubmitLoader}>
                      {formSubmitLoader && <i className="fas fa-circle-notch fa-spin me-2"></i>}
                      {enableOtp && 'Reset Password'}
                      {!enableOtp && 'Continue'}
                    </button>
                  </div>
                </form>
                <p className="text-center mb-0"><a className="btn-link" href="" data-bs-toggle="modal" data-bs-target="#login-modal" data-bs-dismiss="modal">Return to Log In</a> <span className="text-muted mx-3">|</span> <a className="btn-link cursor-pointer" onClick={resendOtp}>Request OTP</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
