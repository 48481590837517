import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom';


export default function AboutUs
  () {
  return (
    <div>
      <section className="page-header page-header-dark bg-secondary">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-8">
              <h1>About Us</h1>
            </div>
            <div className="col-md-4">
              <ul className="breadcrumb justify-content-start justify-content-md-end mb-0">

                <li><NavLink to="/">Home</NavLink></li>
                <li className="active">About Us</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div id="content">
        <div className="container">
          <div className="bg-white shadow-md rounded p-4 my-4">
            <h2 className="text-6">What is CompanySearch?</h2>
            <p>CompanySearch is India’s largest online legal services platform which has helped of entrepreneurs to start and grow their business easily at a very affordable cost. We provide quality services from planning to setting up the business by fulfilling all legal and regulatory requirements. We understand the difficulties and complexities that Indian citizens have to face in obtaining legal documents. We have made the process very simple with technology and legal professional skills. We consistently upgrade ourselves to improve our services and customer satisfaction.</p>
            <p>Our goal is to use our unique experience and the skills gained over a specific period of time to grow our client's business with our own insights gained from the experience. We are passionate and able to support our clients to achieve their business goals and objectives. We offer quality solutions to increase our client business productivity.</p>
            <p>CompanySearch provide professional, multi-disciplinary compliance services in India. We are here to provide various types of services such as incorporation of different type of entities, government registrations, Licenses, Filings, legal documentation and annual compliance's, audit and assurance, valuation services, finance, and accounting outsourcing services, taxation, IPR Services, regulatory services, and doing business in India</p>
            <div className="row mt-4 mb-2">

              <div className="col-md-6">
                <div className="featured-box style-1">
                  <div className="featured-box-icon text-primary"> <i className="far fa-paper-plane" /> </div>
                  <h3>Our Mission</h3>
                  <p>Our mission is to provide excellent legal services to entrepreneurs across the country under a single window service.</p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="featured-box style-1">
                  <div className="featured-box-icon text-primary"> <i className="far fa-eye" /> </div>
                  <h3>Our Vision</h3>
                  <p>Our vision is to deliver excellence in all services provided by the firm. We are having bold ambition to become the most <b>trusted</b>  brand in online professional services industry.</p>
                </div>
              </div>
            </div>

            <div>
              <section className="section bg-white my-sm-5 ">
              <div >
                <h2 className="text-9 text-center">Why Choose Us</h2>
                <p className="lead text-center mb-5"></p>
                <div className="row">
                  <div className="col-md-4">
                    <div className="featured-box style-4">
                      <div className="featured-box-icon bg-light-2 text-primary shadow-sm rounded-circle"> <i className="fa fa-window-maximize"></i></div>
                      <h3 className="text-5 fw-400 mb-3">Single Window Services!</h3>
                      <p className="text-3">We provide all a one-stop solution for all types of enterprises.</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="featured-box style-4">
                      <div className="featured-box-icon bg-light-2 text-primary shadow-sm rounded-circle"> <i className="fa fa-download"></i></div>
                      <h3 className="text-5 fw-400 mb-3">Simplified process </h3>
                      <p className="text-3">Our process is extremely simplified because our dedicated experts handhold you throughout each process.</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="featured-box style-4">
                      <div className="featured-box-icon bg-light-2 text-primary shadow-sm rounded-circle"> <i className="fa fa-users"></i> </div>
                      <h3 className="text-5 fw-400 mb-3">Professional Team</h3>
                      <p className="text-3">Well studied and verified drafts from legal experts.</p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="featured-box style-4">
                      <div className="featured-box-icon bg-light-2 text-primary shadow-sm rounded-circle"><i className="fa fa-trademark"></i></div>
                      <h3 className="text-5 fw-400 mb-3">Believe in Quality</h3>
                      <p className="text-3">We provide high quality service. We also deliver fast and error-free services.</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="featured-box style-4">
                      <div className="featured-box-icon bg-light-2 text-primary shadow-sm rounded-circle"> <i className='fa fa-edit' /> </div>
                      <h3 className="text-5 fw-400 mb-3">Assured customer satisfaction</h3>
                      <p className="text-3">We offer Continuous perpetual support for client satisfaction.</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="featured-box style-4">
                      <div className="featured-box-icon bg-light-2 text-primary shadow-sm rounded-circle"> <i className="fa fa-lock"></i> </div>
                      <h3 className="text-5 fw-400 mb-3">Client data confidentiality   </h3>
                      <p className="text-3">We provide customers with a trust of confidentiality & privacy.</p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="featured-box style-4">
                      <div className="featured-box-icon bg-light-2 text-primary shadow-sm rounded-circle"><i className="fa fa-phone"></i></div>
                      <h3 className="text-5 fw-400 mb-3">Instant Response</h3>
                      <p className="text-3">Get instant responses to all your queries within a few minutes.</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="featured-box style-4">
                      <div className="featured-box-icon bg-light-2 text-primary shadow-sm rounded-circle"> <i className="fa fa-flag"></i></div>
                      <h3 className="text-5 fw-400 mb-3">Consistency</h3>
                      <p className="text-3">Our primary commitment is to provide continuous business support to our client base.</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="featured-box style-4">
                      <div className="featured-box-icon bg-light-2 text-primary shadow-sm rounded-circle"> <i className="fa fa-calendar"></i> </div>
                      <h3 className="text-5 fw-400 mb-3">On-time service</h3>
                      <p className="text-3">We take precautionary measures to ensure that our clients never miss a single deadline.</p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            </div>
            {/* <h2 className="text-6 mb-3">Leadership</h2>
            <div className="row">
              <div className="col-sm-6 col-md-3">
                <div className="team"> <img className="img-fluid rounded" alt="" src="assets/images/team/leader.jpg" />
                  <h3>Neil Patel</h3>
                  <p className="text-muted">CEO &amp; Founder</p>
                  <ul className="social-icons social-icons-sm d-inline-flex">
                    <li className="social-icons-facebook"><a data-bs-toggle="tooltip" href="http://www.facebook.com/" target="_blank" title="Facebook"><i className="fab fa-facebook-f" /></a></li>
                    <li className="social-icons-twitter"><a data-bs-toggle="tooltip" href="http://www.twitter.com/" target="_blank" title="Twitter"><i className="fab fa-twitter" /></a></li>
                    <li className="social-icons-google"><a data-bs-toggle="tooltip" href="http://www.google.com/" target="_blank" title="Google"><i className="fab fa-google" /></a></li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-6 col-md-3">
                <div className="team"> <img className="img-fluid rounded" alt="" src="assets/images/team/leader-2.jpg" />
                  <h3>James Maxwell</h3>
                  <p className="text-muted">Co-Founder</p>
                  <ul className="social-icons social-icons-sm d-inline-flex">
                    <li className="social-icons-facebook"><a data-bs-toggle="tooltip" href="http://www.facebook.com/" target="_blank" title="Facebook"><i className="fab fa-facebook-f" /></a></li>
                    <li className="social-icons-twitter"><a data-bs-toggle="tooltip" href="http://www.twitter.com/" target="_blank" title="Twitter"><i className="fab fa-twitter" /></a></li>
                    <li className="social-icons-google"><a data-bs-toggle="tooltip" href="http://www.google.com/" target="_blank" title="Google"><i className="fab fa-google" /></a></li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-6 col-md-3">
                <div className="team"> <img className="img-fluid rounded" alt="" src="assets/images/team/leader-3.jpg" />
                  <h3>Ruby Clinton</h3>
                  <p className="text-muted">Co-Founder</p>
                  <ul className="social-icons social-icons-sm d-inline-flex">
                    <li className="social-icons-facebook"><a data-bs-toggle="tooltip" href="http://www.facebook.com/" target="_blank" title="Facebook"><i className="fab fa-facebook-f" /></a></li>
                    <li className="social-icons-twitter"><a data-bs-toggle="tooltip" href="http://www.twitter.com/" target="_blank" title="Twitter"><i className="fab fa-twitter" /></a></li>
                    <li className="social-icons-google"><a data-bs-toggle="tooltip" href="http://www.google.com/" target="_blank" title="Google"><i className="fab fa-google" /></a></li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-6 col-md-3">
                <div className="team"> <img className="img-fluid rounded" alt="" src="assets/images/team/leader-4.jpg" />
                  <h3>Miky Sheth</h3>
                  <p className="text-muted">Support</p>
                  <ul className="social-icons social-icons-sm d-inline-flex">
                    <li className="social-icons-facebook"><a data-bs-toggle="tooltip" href="http://www.facebook.com/" target="_blank" title="Facebook"><i className="fab fa-facebook-f" /></a></li>
                    <li className="social-icons-twitter"><a data-bs-toggle="tooltip" href="http://www.twitter.com/" target="_blank" title="Twitter"><i className="fab fa-twitter" /></a></li>
                    <li className="social-icons-google"><a data-bs-toggle="tooltip" href="http://www.google.com/" target="_blank" title="Google"><i className="fab fa-google" /></a></li>
                  </ul>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>

    </div>
  )
}
