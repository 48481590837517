import React from 'react'
import { NavLink } from 'react-router-dom'

export default function RefundPolicy
() {
  return (
    <div>
<section className="page-header page-header-dark bg-secondary">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-md-8">
          <h1>Refund Policy</h1>
        </div>
        <div className="col-md-4">
          <ul className="breadcrumb justify-content-start justify-content-md-end mb-0">
            <li><NavLink to="/">Home</NavLink></li>
            <li className="active">Refund Policy</li>
          </ul>
        </div>
      </div>
    </div>
  </section> 
  <div id="content">
        <div className="container">
          <div className="bg-white shadow-md rounded p-4 my-4">
            <h2 className="text-6">Refund Policy</h2>
            <p>The Fee that you pay to CompanySearch will be a retained by CompanySerach and we will earn the fees upon completing the assignments on stage by stage. The fee we earn for delivery of services may vary from services to services depends on the time and efforts deployed by the team for client counselling and advise, briefing the process and requirements, data processing, documentation, processing of external services, registration at regulatory portals, submission of details and documents, liaison with regulatory offices and approvals etc. </p>
            <p>CompanySearch reserves the right to decide the earned fee for a specific service process and the parameters for charging the fee may vary from time to time. The earned fee will not be refunded as CompanySearch have to spend on man hours and efforts to process the services. Also the fee paid to the government authorities such as taxes and filing fee and professional fee and expenses to third party service providers will not be eligible for refund.</p>
            <h6>Limited Liability</h6><p>Under any circumstances, the maximum liability of CompanySearch shall be limited to the fee we collected for the services and we will not liable for any loss or damages that may be incurred or as may be claimed by the customer due to delay in process (technical or otherwise) or rejection of application by the regulatory authorities. </p>
            <h6>Earned Fee & Cancellation Fee Deduction</h6><p>Before processing any refund, we reserve the right to make a best effort to complete the service. In case you are not satisfied with the service, a cancellation fee of 20% + earned fee + fee paid to the government would be applicable. In case of change of service, the cancellation fee would not be applicable</p><p>Hence, all refunds will be subject to a minimum of 20% cancellation fee. Further, if CompanySearch has spent the time and resources to complete the engagement or incurred any other fee, such fees will be deducted on best judgement basis by the company and the balance will be refunded. Under any circumstance, CompanySearch shall be liable to refund only upto 80% of the fee paid by the client.</p>
            <h6>Procedure for Requesting Refund</h6><p>In case you want to cancel the order or request refund of fee, contact your relationship manager or sent an email to support@companysearch.co.in with reason for refund of fee. Refund will be processed only for refund requests submitted within 48 hours of placing the order. Refund request submitted after 48 hours of placing the order shall not be eligible for Refund. In case of refund request submitted after 48 hours of placing the order, the fee paid by the customer, after adjusting the Earned Fee, Costs and expenses incurred by CompanySearch and Cancellation Fee, if any, they will be credited to the customer account for settlement against alternate services or for availing services within a specified time. Date of first payment for an order will be treated as the ‘Date of placing the Order’.</p><p>In case the service delivery is not completed due to rejection of application by the by the regulatory authorities, no refund requests will be processed.</p><p>Cancellation of order or Refund request must be placed within 60 days of placing the order. No refund of fee will be processed (cash or credit) on requests placed after 60 days of placing the order. However, the unpaid Government fee, if any will be credited to the customer account for settlement against alternate services or future services.</p><p>Credit Balance in your customer account can only be used for availing any services from CompanySearch within 365 days of credit of amount and the unutilized balance in customer accounts will be retained by CompanySearch and will not be available for adjustment or refund.</p>
            <h6>Change of Service</h6><p>If you want to change the service you ordered for a different one, you can request this change of service within 60 days of purchase. The purchase price of the original service, less any earned fee and money paid to government entities, such as filing fees or taxes, or to other third parties with deducted and the next service will be launched. In case of change of service, there will be no 20% deduction</p>
            <h6>Penalty/ Additional Fees or Tax Payment</h6><p>I CompanySearch and its employees play the role of a corporate service provider. While, it is our responsibility to prepare the necessary filings, it is the client’s responsibility to ensure accuracy and correctness. Hence, CompanySearch will not pay any penalties/ additional fees or taxes on behalf of its clients. Further, CompanySearch is not responsible or liable for any other cost incurred by the client related to the completion of the service that is out of scope.  </p><p>The process may take unexpected long time due to technical issues or due to technical update of the process or due to policy / process changes the regulatory platforms such as www.mca.gov.in, www.incometaxindia.gov.in, www.ipindiaonline.gov.in etc which is beyond the control of CompanySearch. So, the CompanySearch do not guarantee the delivery services within the indicated timelines. Delay in processing the service delivery or rejection of application process by the regulatory authority cannot be a reason for refund.</p>
            <h6>External Factors Beyond our Control</h6><p>CompanySearch cannot guarantee the results or outcome of your particular procedure. For example, the government regulatory may reject an application trademark application for Company / LLP / OPC Registration, Trademark Registration for technical or legal reasons beyond the control of CompanySearch. Also, the timelines indicated to you for specific services are based on the average lead time of service delivery based on the past experience of CompanySearch.</p>
            <h6>Force Majeure</h6><p>CompanySearch shall not be considered in breach of its Satisfaction Guarantee policy or default under any terms of service, and shall not be liable to the Client for any cessation, interruption, or delay in the performance of its obligations by reason of earthquake, flood, fire, storm, lightning, drought, landslide, hurricane, cyclone, typhoon, tornado, natural disaster, act of God or the public enemy, epidemic, famine or plague, action of a court or public authority, change in law, explosion, war, terrorism, armed conflict, labor strike, lockout, boycott or similar event beyond our reasonable control, whether foreseen or unforeseen (each a "Force Majeure Event"). </p>
          </div>
        </div>
      </div>

    </div>
  )
}
