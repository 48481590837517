import { ClientRequest } from 'http';
import { url } from 'inspector';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import * as constants from '../../Constants/Constants'
import './Home.css'

declare var $: any;

export default function Home() {

  const navigate = useNavigate();
  const [serviceList, setServiceList] = useState<any[]>();
  const [filteredServiceList, setFilteredServiceList] = useState<any[]>();
  const [resentBlogList, setResentBlogList] = useState<any[]>();

  useEffect(() => {
    getSearchableServiceList();
    getResentBlogList();
  }, []);

  const getSearchableServiceList = () => {
    fetch('./assets/json-data/service-list.json', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      setServiceList(response);
    });
  }

  const getResentBlogList = () => {
    fetch(constants.BASE_URL + 'blog/list?sortBy=createdDate&pageNum=1&numPerPage=4', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      if (response.status == '200') {
        response.result.forEach((element: any) => {
          if (element.image) {
            element.image = getImageUrl(element.image);
          }
          if (element.description) {
            element.description = (element.description.substring(0, 70) + '....');
          }
          if (element.heading) {
            let length = element.heading.length;
            element.heading = (element.heading.substring(0, 50) + (length > 50 ? '...' : ''));
          }
        });
        setResentBlogList(response.result);
      }
    });
  }

  const getImageUrl = (imageName: string) => {
    let baseUrl = constants.BASE_URL;
    return baseUrl + 'file/image/' + imageName + '/'
  }

  const gotoBlogDetails = (blogId: string) => {
    navigate('/blog/' + blogId);
  }

  const goToCheckout = (serviceId: string) => {
    if (localStorage.getItem('currentUser')) {
      navigate("/checkout/" + serviceId);
    } else {
      $('#login-modal').modal('show');
    }
  }

  const goToServiceDetails = (serviceName: string) => {
    navigate("/" + serviceName);
  }

  const handleSearchChange = (e: any) => {
    let searchKey = e.target.value;
    if (searchKey.length >= 3) {
      let filteredArray = serviceList?.filter((obj) => obj.serviceName.toLowerCase().includes(searchKey.toLowerCase()));
      setFilteredServiceList(filteredArray);
    } else {
      setFilteredServiceList([]);
    }
  }

  const onSearchEnter = (e: any) => {
    if(e.key == 'Enter' && e.target.value.length > 3){
      let searchKey = e.target.value;
      let filteredArray = serviceList?.filter((obj) => obj.serviceName.toLowerCase().includes(searchKey.toLowerCase()));
      if (filteredArray && filteredArray?.length > 0) {
        navigate("/" + filteredArray[0].shortForm);
      }
    }
  }

  return (
    <>
    <section className=' section bg-primary main-section'>
      <div className="hero-wrap bg-primary">
        <div className="hero-mask opacity-1" />
        <div className="hero-bg hero-bg-scroll"  />
        <div className="hero-content d-flex min-vh-100" >
          <div className="container my-auto homepadding">
            <div className="homepadding1">
              <h2 className="text-10 text-center fw-600 mb-4 white">Let's consult & start your business </h2>
              <div className="row justify-content-center">
                <div className="col-sm-10 col-lg-8 col-xl-7">
                  <div className="row g-4 g-lg-0 search-input-2">
                    <div className="input-group search-shadow mb-4 position-relative">
                      <input className="form-control shadow-none border-0 pe-0"  autoFocus type="search" id="search-input" placeholder="Search"
                        onChange={handleSearchChange}  autoComplete='off' onKeyDown={onSearchEnter}  />
                      <ul className='position-absolute search-result shadow'>
                        {filteredServiceList && filteredServiceList.length > 0 && filteredServiceList?.map((fs, index) => (
                          <li key={index} onClick={() => goToServiceDetails(fs.shortForm)}>{fs.serviceName}</li>
                        ))}
                      </ul>
                      <span className="input-group-text bg-white border-0 p-0">
                        <button className="btn text-muted shadow-none px-3 border-0" type="button"><i className="fa fa-search" /></button>
                      </span>
                    </div>
                    <div className="col-lg-12 col-xl-12">
                      <div className="tags text-center text-lg-start tag-text-center">
                        <div className='search-popular fw-700 text-4 text-center white'>Popular Services:</div><br/>
                        <a className='search-tag cursor-pointer shadow-sm' onClick={() => goToServiceDetails('private-limited-company')}>Company Registration</a>
                        <a className='search-tag cursor-pointer shadow-sm' onClick={() => goToServiceDetails('fssai-registration')}>FSSAI</a>                        

                        <a className='search-tag cursor-pointer shadow-sm' onClick={() => goToServiceDetails('trademark-registration')}>Trademark</a>
                        <a className='search-tag cursor-pointer shadow-sm' onClick={() => goToServiceDetails('farmers-producer-company')}>FPC Registration</a>
                        <a className='search-tag cursor-pointer shadow-sm' onClick={() => goToServiceDetails('patent-search')}>Patent Search</a>
                        {/* 
                        <a className='search-tag cursor-pointer shadow-sm' onClick={() => goToServiceDetails('import-export-registration')}>Import Export Code</a>
                        <a className='search-tag cursor-pointer shadow-sm' onClick={() => goToServiceDetails('iso-certification')}>ISO Certificate</a>
                        <a className='search-tag cursor-pointer shadow-sm' onClick={() => goToServiceDetails('private-to-public-company')}>Private Company to Public Company</a>
                        <a className='search-tag cursor-pointer shadow-sm' onClick={() => goToServiceDetails('gst-registration')}>GST Registration</a>
                        <a className='search-tag cursor-pointer shadow-sm' onClick={() => goToServiceDetails('audit-company-accounts')}>Company Audit</a>
                        <a className='search-tag cursor-pointer shadow-sm' onClick={() => goToServiceDetails('tds-return-filling')}>TDS Return Filling</a>
                        
                        <a className='search-tag cursor-pointer shadow-sm' onClick={() => goToServiceDetails('fpo')}>Farmers Producer Organisation(FPO)</a> */}
                        {/* <a className='search-tag cursor-pointer shadow-sm' onClick={() => goToServiceDetails('smart')}>Smart Project-Maharashtra State</a>
                        <a className='search-tag cursor-pointer shadow-sm' onClick={() => goToServiceDetails('copyright-registration')}>Copyright Registration</a>
                        <a className='search-tag cursor-pointer shadow-sm' onClick={() => goToServiceDetails('pacs')}>Primary Agricultural Credit Societies</a>*/}
 
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        
      </div>
    </section>
      
      <section className="section bg-white "  >
        <div className="container my-sm-3">
          <h2 className="text-9 text-center blue">What we can do for you</h2>
          {/* <p className="lead text-center mb-5">We believe in helping businesses at 360* angles so you can focus on your core operations business.</p> */}
          <div className="row">
            <div className="col-md-4">
              <div className="featured-box style-4">
                <div className="featured-box-icon bg-light-2 text-primary shadow-sm rounded-circle"> <i className="fa fa-building" aria-hidden="true" /></div>
                <h3 className="text-5 fw-400 mb-3">Company Formation</h3>
                <p className="text-3">We can Registered all kinds of entities required to set up the business.</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="featured-box style-4">
                <div className="featured-box-icon bg-light-2 text-primary shadow-sm rounded-circle"> <i className='fas fa-money-check-alt'></i></div>
                <h3 className="text-5 fw-400 mb-3">Tax Consulting </h3>
                <p className="text-3">We'll prepare and file your Income Tax returns and give reminders and status updates of your filings.</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="featured-box style-4">
                <div className="featured-box-icon bg-light-2 text-primary shadow-sm rounded-circle"> <i className='fa fa-print'></i> </div>
                <h3 className="text-5 fw-400 mb-3">Audit and accounting services</h3>
                <p className="text-3">We provide a range of accounting & audited services for individuals and businesses of all sizes.</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="featured-box style-4">
                <div className="featured-box-icon bg-light-2 text-primary shadow-sm rounded-circle"><i className='fas fa-pen-square'></i></div>
                <h3 className="text-5 fw-400 mb-3">Registration & Licensing</h3>
                <p className="text-3">We can secure all kinds of licenses & registrations required for running different kinds of business.</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="featured-box style-4">
                <div className="featured-box-icon bg-light-2 text-primary shadow-sm rounded-circle"> <i className='fa fa-edit' /> </div>
                <h3 className="text-5 fw-400 mb-3">Legal Compliances </h3>
                <p className="text-3">We provide complete online solution for compliance service required for any legal entity.</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="featured-box style-4">
                <div className="featured-box-icon bg-light-2 text-primary shadow-sm rounded-circle"> <i className='far fa-file' /> </div>
                <h3 className="text-5 fw-400 mb-3">FPO Services  </h3>
                <p className="text-3">We provide all facilities to  FPO from registration to implementation  government schemes.</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="featured-box style-4">
                <div className="featured-box-icon bg-light-2 text-primary shadow-sm rounded-circle"> <i className='fas fa-briefcase' /> </div>
                <h3 className="text-5 fw-400 mb-3">IPR Services</h3>
                <p className="text-3">We will work with you to protect IP, ensuring success by putting together a commercially robust strategy right at the outset, while focusing on alignment with your business objectives.</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="featured-box style-4">
                <div className="featured-box-icon bg-light-2 text-primary shadow-sm rounded-circle"> <i className='fas fa-donate'></i> </div>
                <h3 className="text-5 fw-400 mb-3">NGO Services</h3>
                <p className="text-3">We provide all services for Section 8 Company, Trust, and Society etc. This includes registration, legal Compliances and more.</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="featured-box style-4">
                <div className="featured-box-icon bg-light-2 text-primary shadow-sm rounded-circle"> <i className='fas fa-calendar-alt'></i> </div>
                <h3 className="text-5 fw-400 mb-3">Business Consulting</h3>
                <p className="text-3">We provide from time to time guidance, training support, counseling for business.</p>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section className='section bg-primary'>
        <div id="content">
          <div className="container my-sm-3">
            <h2 className="text-9 text-center white mb-4">Our Services</h2>
            <div className="bg-white shadow-md rounded p-4">
              <div className="row">
                <div className="col-lg-12">
                  <ul className="nav nav-tabs justify-content-xl-center mb-4" id="myTab" role="tablist">
                    <li className="nav-item"> <a className="nav-link active" id="one-tab" data-bs-toggle="tab" href="#oneTab" role="tab" aria-controls="oneTab" aria-selected="true">Business Setup</a> </li>
                    <li className="nav-item"> <a className="nav-link" id="two-tab" data-bs-toggle="tab" href="#twoTab" role="tab" aria-controls="twoTab" aria-selected="false">Licence and Registrations</a> </li>
                    <li className="nav-item"> <a className="nav-link" id="three-tab" data-bs-toggle="tab" href="#threeTab" role="tab" aria-controls="threeTab" aria-selected="false">Audit and Compliances</a> </li>
                    <li className="nav-item"> <a className="nav-link" id="four-tab" data-bs-toggle="tab" href="#fourTab" role="tab" aria-controls="fourTab" aria-selected="false">GST and Taxes</a> </li>
                    <li className="nav-item"> <a className="nav-link" id="five-tab" data-bs-toggle="tab" href="#fiveTab" role="tab" aria-controls="fiveTab" aria-selected="false">Trademark and IPR</a> </li>
                    <li className="nav-item"> <a className="nav-link" id="seven-tab" data-bs-toggle="tab" href="#sevenTab" role="tab" aria-controls="sevenTab" aria-selected="false">FPO Services</a> </li>
                  </ul>
                </div>
                <div className="col-lg-12">
                  <div className="tab-content" id="listCards">
                    <div className="tab-pane fade show active" id="oneTab" role="tabpanel" aria-labelledby="one-tab">
                      <div className="row">
                        <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
                          <div className="card minheight text-center">
                            <div className="card-header">
                              <h5 className="card-title text-4 mb-0">Private Limited Company</h5>
                            </div>
                            <div className="card-body">
                              <ul className="list-unstyled">
                                <li className="mb-1 text-black-50">DIN & DSC for Two Directors</li>
                                <li className="mb-1 text-black-50">Name Approval Certificate</li>
                                <li className="mb-1 text-black-50">Drafting of MoA & AoA</li>
                                <li className="mb-1 text-black-50">Registration Fees & Stamp Duty</li>
                                <li className="mb-1 text-black-50">Company Incorporation Certificate</li>
                                <li className="mb-1 text-black-50">Company PAN & TAN</li>
                                <li className="mb-1 text-black-50">Zero Balance Current Account</li>
                                <li className="mb-1 text-black-50">& more</li>
                                <li><Link className="btn-link text-1" to='/private-limited-company'>More Details</Link></li>
                              </ul>
                              <div className="d-grid">
                                <button className="btn btn-sm btn-outline-primary text-5 py-1 fw-500 shadow-none" type="button"
                                  onClick={() => { goToCheckout('13cfe442-25ff-11ed-861d-0242ac120002') }}>Enquire</button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
                          <div className="card minheight text-center">
                            <div className="card-header">
                              <h5 className="card-title text-4 mb-0">Public Limited Company</h5>
                            </div>
                            <div className="card-body">
                              <ul className="list-unstyled">
                                <li className="mb-1 text-black-50">Company Incorporation Certificate</li>
                                <li className="mb-1 text-black-50">DSC for one director and DIN  for upto three directors </li>
                                <li className="mb-1 text-black-50">Online Accounting Software valid for one year </li>
                                <li className="mb-1 text-black-50">Zero balance account</li>
                                <li className="mb-1 text-black-50">Drafting of MoA and AoA</li>
                                <li className="mb-1 text-black-50">Registration fees and stamp duty</li>
                                <li className="mb-1 text-black-50">& more</li>
                                <li><Link className="btn-link text-1" to='/public-limited-company'>More Details</Link></li>
                              </ul>
                              <div className="d-grid">
                                <button className="btn btn-sm btn-outline-primary text-5 py-1 fw-500 shadow-none" type="button"
                                  onClick={() => { goToCheckout('22b8c816-25ff-11ed-861d-0242ac120002') }}>Enquire</button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
                          <div className="card minheight text-center">
                            <div className="card-header">
                              <h5 className="card-title text-4 mb-0">One Person Company</h5>
                            </div>
                            <div className="card-body">
                              <ul className="list-unstyled">
                                <li className="mb-1 text-black-50">Company Incorporation Certificate</li>
                                <li className="mb-1 text-black-50">DSC for one director and DIN  for upto three directors </li>
                                <li className="mb-1 text-black-50">Online Accounting Software valid for one year </li>
                                <li className="mb-1 text-black-50">Zero balance account</li>
                                <li className="mb-1 text-black-50">Drafting of MoA and AoA</li>
                                <li className="mb-1 text-black-50">Registration fees and stamp duty</li>
                                <li className="mb-1 text-black-50">& more</li>
                                <li><Link className="btn-link text-1" to='/one-person-company'>More Details</Link></li>
                              </ul>
                              <div className="d-grid">
                                <button className="btn btn-sm btn-outline-primary text-5 py-1 fw-500 shadow-none" type="button"
                                  onClick={() => { goToCheckout('31a50e8e-25ff-11ed-861d-0242ac120002') }}>Enquire</button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
                          <div className="card minheight text-center">
                            <div className="card-header">
                              <h5 className="card-title text-4 mb-0">Limited Liability Partnership</h5>
                            </div>
                            <div className="card-body">
                              <ul className="list-unstyled">
                                <li className="mb-1 text-black-50">Company Incorporation Certificate</li>
                                <li className="mb-1 text-black-50">DSC for one director and DIN  for upto three directors </li>
                                <li className="mb-1 text-black-50">Online Accounting Software valid for one year </li>
                                <li className="mb-1 text-black-50">Zero balance account</li>
                                <li className="mb-1 text-black-50">Drafting of MoA and AoA</li>
                                <li className="mb-1 text-black-50">Registration fees and stamp duty</li>
                                <li className="mb-1 text-black-50">& more</li>
                                <li><Link className="btn-link text-1" to='/limited-liability-partnership'>More Details</Link></li>
                              </ul>
                              <div className="d-grid">
                                <button className="btn btn-sm btn-outline-primary text-5 py-1 fw-500 shadow-none" type="button"
                                  onClick={() => { goToCheckout('4598767e-25ff-11ed-861d-0242ac120002') }}>Enquire</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane fade " id="twoTab" role="tabpanel" aria-labelledby="two-tab">
                      <div className="row">
                        <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
                          <div className="card minheight text-center">
                            <div className="card-header">
                              <h5 className="card-title text-4 mb-0">FSSAI <br/> Registration </h5>
                            </div>
                            <div className="card-body">
                              <ul className="list-unstyled">
                                <li className="mb-3 text-black-50">Every business running in food industry should register under FSSAI.</li>
                                <li className="mb-3 text-black-50">We guide you choosing right type of licence for your business.</li>
                                <li className="mb-3 text-black-50">We will take care of filling process.</li>
                                <li className="mb-3 text-black-50">We will explain you how run your business as per FSSAI rules.</li>
                                <li><Link className="btn-link text-1" to='/fssai-registration'>More Details</Link></li>
                              </ul>
                              <div className="d-grid">
                                <button className="btn btn-sm btn-outline-primary text-5 py-1 fw-500 shadow-none" type="button"
                                  onClick={() => { goToCheckout('834f6e98-6088-4c9c-9ce4-350dd9d2153a') }}>Enquire</button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
                          <div className="card minheight text-center">
                            <div className="card-header">
                              <h5 className="card-title text-4 mb-0">Shop and Establishment</h5>
                            </div>
                            <div className="card-body">
                              <ul className="list-unstyled">
                                <li className="mb-3 text-black-50">Opening a commercial eastablishment?</li>
                                <li className="mb-3 text-black-50">It is compulsory to get shop and establishment licence.</li>
                                <li className="mb-3 text-black-50">We will take care of filling process.</li>
                                <li className="mb-3 text-black-50">Our team will handle  entire process and get your licence in minimum time.</li>
                                <li><Link className="btn-link text-1" to='/shop-and-establishment'>More Details</Link></li>
                              </ul>
                              <div className="d-grid">
                                <button className="btn btn-sm btn-outline-primary text-5 py-1 fw-500 shadow-none" type="button"
                                  onClick={() => { goToCheckout('ad539be5-c9d5-4233-87b8-be1bed06f3aa') }}>Enquire</button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
                          <div className="card minheight text-center">
                            <div className="card-header">
                              <h5 className="card-title text-4 mb-0">Import Export Code(IEC)</h5>
                            </div>
                            <div className="card-body">
                              <ul className="list-unstyled">
                                <li className="mb-3 text-black-50">Want to start Import Export Business?</li>
                                <li className="mb-3 text-black-50">You require Import Export code for it.</li>
                                <li className="mb-3 text-black-50">We will take care of filling process.</li>
                                <li className="mb-3 text-black-50">Our team will handle  entire process and get your registration  in minimum time.</li>
                                <li><Link className="btn-link text-1" to='/import-export-registration'>More Details</Link></li>
                              </ul>
                              <div className="d-grid">
                                <button className="btn btn-sm btn-outline-primary text-5 py-1 fw-500 shadow-none" type="button"
                                  onClick={() => { goToCheckout('cbfbf2bc-31c7-11ed-a261-0242ac120002') }}>Enquire</button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
                          <div className="card minheight text-center">
                            <div className="card-header">
                              <h5 className="card-title text-4 mb-0">ISO <br/>STANDARDS</h5>
                            </div>
                            <div className="card-body">
                              <ul className="list-unstyled">
                                <li className="mb-2 text-black-50">ISO certification assists every business with a mark of quality and high standards.</li>
                                <li className="mb-2 text-black-50">We will assist you choosing right type of ISO certification for your business .</li>
                                <li className="mb-1 text-black-50"> we help you to connect trustworthy relations with vendor.</li>
                                <li className="mb-1 text-black-50">Our team will handle  entire process.</li>
                                <li><Link className="btn-link text-1" to='/iso-certification'>More Details</Link></li>
                              </ul>
                              <div className="d-grid">
                                <button className="btn btn-sm btn-outline-primary text-5 py-1 fw-500 shadow-none" type="button"
                                  onClick={() => { goToCheckout('7344d811-96d9-41b3-b66b-2b1d683df58b') }}>Enquire</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane fade " id="threeTab" role="tabpanel" aria-labelledby="three-tab">
                      <div className="row">
                        <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
                          <div className="card minheight text-center">
                            <div className="card-header">
                              <h5 className="card-title text-4 mb-0">Striking off Company</h5>
                            </div>
                            <div className="card-body">
                              <ul className="list-unstyled">
                                <li className="mb-3 text-black-50">Serving of Notice</li>
                                <li className="mb-3 text-black-50">Representation of Company</li>
                                <li className="mb-3 text-black-50">Publication of notice</li>
                                <li className="mb-3 text-black-50">Intimation to regulatory authorities</li>
                                <li className="mb-3 text-black-50">Strike off the name and publish notice of dissolution of the company</li>
                                <li><Link className="btn-link text-1" to='/striking-off-company'>More Details</Link></li>
                              </ul>
                              <div className="d-grid">
                                <button className="btn btn-sm btn-outline-primary text-5 py-1 fw-500 shadow-none" type="button"
                                  onClick={() => { goToCheckout('b5f98980-2b0d-4727-b40c-ef3bd7e37b3f') }}>Enquire</button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
                          <div className="card minheight text-center">
                            <div className="card-header">
                              <h5 className="card-title text-4 mb-0">Company Audit</h5>
                            </div>
                            <div className="card-body">
                              <ul className="list-unstyled">
                                <li className="mb-3 text-black-50">Auditor of a Company</li>
                                <li className="mb-3 text-black-50">Appointment of First Auditor by a Company</li>
                                <li className="mb-3 text-black-50">Appointment of Auditor at AGM of Company</li>
                                <li className="mb-3 text-black-50">Rotation of Auditors</li>
                                {/* <li className="mb-3 text-black-50">Auditor to Sign Audit Reports</li> */}
                                <li><Link className="btn-link text-1" to='/audit-company-accounts'>More Details</Link></li>
                              </ul>
                              <div className="d-grid">
                                <button className="btn btn-sm btn-outline-primary text-5 py-1 fw-500 shadow-none" type="button"
                                  onClick={() => { goToCheckout('ea54ee61-8220-4f07-98c8-7134bfd6a1e8') }}>Enquire</button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
                          <div className="card minheight text-center">
                            <div className="card-header">
                              <h5 className="card-title text-3 mb-0">Change in Partners of LLP</h5>
                            </div>
                            <div className="card-body">
                              <ul className="list-unstyled">
                                <li className="mb-3 text-black-50">Verify Partner Change Situation</li>
                                <li className="mb-3 text-black-50">LLP Agreement</li>
                                <li className="mb-3 text-black-50">Partners Meeting</li>
                                <li className="mb-3 text-black-50">Filing of Changes with Registrar of Companies</li>
                                <li><Link className="btn-link text-1" to='/changes-in-partnersofllp'>More Details</Link></li>
                              </ul>
                              <div className="d-grid">
                                <button className="btn btn-sm btn-outline-primary text-5 py-1 fw-500 shadow-none" type="button"
                                  onClick={() => { goToCheckout('5a006722-f7ba-487b-98d9-d15014535b33') }}>Enquire</button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
                          <div className="card minheight text-center">
                            <div className="card-header">
                              <h5 className="card-title text-4 mb-0">Business Plan</h5>
                            </div>
                            <div className="card-body">
                              <ul className="list-unstyled">
                                <li className="mb-3 text-black-50">Traditional Business Plan</li>
                                <li className="mb-3 text-black-50">Lean Business Plan</li>
                                <li className="mb-3 text-black-50">Internal Business Plan</li>
                                <li className="mb-3 text-black-50">External Business Plan</li>
                                <li className="mb-3 text-black-50">Startup Business Plan</li>
                                <li><Link className="btn-link text-1" to='/business-plan'>More Details</Link></li>
                              </ul>
                              <div className="d-grid">
                                <button className="btn btn-sm btn-outline-primary text-5 py-1 fw-500 shadow-none" type="button"
                                  onClick={() => { goToCheckout('91bf515e-1ae6-4326-9c9d-55cbd4293b12') }}>Enquire</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane fade " id="fourTab" role="tabpanel" aria-labelledby="four-tab" >
                      <div className="row">
                        <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
                          <div className="card minheight text-center">
                            <div className="card-header">
                              <h5 className="card-title text-4 mb-0">TDS Return </h5>
                            </div>
                            <div className="card-body">
                              <ul className="list-unstyled">
                                <li className="mb-3 text-black-50">An employer or company that has valid TAN -tax collection</li>
                                <li className="mb-3 text-black-50">Deduction account number can file for TDS return.</li>
                                <li className="mb-3 text-black-50">Any individaul or business who makes a particular payments which is stated under Income tax act needs to deduct tax at source.</li>
                                <li><Link className="btn-link text-1 " to='/tds-return-filling'>More Details</Link></li>
                              </ul>
                              <div className="d-grid">
                                <button className="btn btn-sm btn-outline-primary text-5 py-1 fw-500 shadow-none" type="button"
                                  onClick={() => { goToCheckout('1b632863-f260-42f4-b249-75161a826ad1') }}>Enquire</button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-3 mb-4" >
                          <div className="card minheight text-center">
                            <div className="card-header">
                              <h5 className="card-title text-4 mb-0">Income Tax Returns </h5>
                            </div>
                            <div className="card-body">
                              <ul className="list-unstyled" >
                                <li className="mb-3 text-black-50"> We make you Understand the latest rules</li>
                                <li className="mb-3 text-black-50"> We will give information about available exemptions every year to file your tax returns can be nerve-wracking </li>
                                <li className="mb-3 text-black-50">you can get it done through CompanySearch.</li>
                                <li><Link className="btn-link text-1" to='/individual-income-tax-returns'>More Details</Link></li>
                              </ul>
                              <div className="d-grid">
                                <button className="btn btn-sm btn-outline-primary text-5 py-1 fw-500 shadow-none" type="button"
                                  onClick={() => { goToCheckout('a9c8c769-1aa7-4106-9a04-c3425705321d') }}>Enquire</button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
                          <div className="card minheight text-center">
                            <div className="card-header">
                              <h5 className="card-title text-4 mb-0">GST Return Filing</h5>
                            </div>
                            <div className="card-body">
                              <ul className="list-unstyled">
                                <li className="mb-3 text-black-50">Who should file GST returns?</li>
                                <li className="mb-3 text-black-50">How to file GST returns?</li>
                                <li className="mb-3 text-black-50">Can the GST return be revised?</li>
                                <li className="mb-3 text-black-50">Who should file GSTR 1 Return?</li>
                                <li><Link className="btn-link text-1" to='/gst-return-filling'>More Details</Link></li>
                              </ul>
                              <div className="d-grid">
                                <button className="btn btn-sm btn-outline-primary text-5 py-1 fw-500 shadow-none" type="button"
                                  onClick={() => { goToCheckout('4791bf56-d53f-4f99-b4c2-b0be4584dc27') }}>Enquire</button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
                          <div className="card minheight text-center">
                            <div className="card-header">
                              <h5 className="card-title text-4 mb-0">GST Registration</h5>
                            </div>
                            <div className="card-body">
                              <ul className="list-unstyled">
                                <li className="mb-3 text-black-50">GST  applies to all Indian service providers (including freelancers), traders and manufacturers.</li>
                                <li className="mb-4 text-black-50">Our experts will walk you through entire process and file for you.</li>
                                {/* <li className="mb-1 text-black-50"> We ensure you get your GSTIN at the earliest .  </li> */}
                                <li><Link className="btn-link text-1" to='/gst-registration'>More Details</Link></li>
                              </ul>
                              <div className="d-grid">
                                <button className="btn btn-sm btn-outline-primary text-5 py-1 fw-500 shadow-none" type="button"
                                  onClick={() => { goToCheckout('1dac9c44-6d96-4919-8986-911728ab7fbb') }}>Enquire</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane fade " id="fiveTab" role="tabpanel" aria-labelledby="five-tab">
                      <div className="row">
                        <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
                          <div className="card minheight text-center">
                            <div className="card-header">
                              <h5 className="card-title text-4 mb-0">Patent Registration </h5>
                            </div>
                            <div className="card-body">
                              <ul className="list-unstyled">
                                <li className="mb-3 text-black-50">Apply for a design patent through CompanySearch right now !</li>
                                <li className="mb-3 text-black-50">Invention is hard but its again dificult to prortect your invention,but not with us.</li>
                                <li className="mb-3 text-black-50">From patent search and listing different uses of your inventions to filling for patent protection.</li>
                                <li><Link className="btn-link text-1" to='/patent-registration'>More Details</Link></li>
                              </ul>
                              <div className="d-grid">
                                <button className="btn btn-sm btn-outline-primary text-5 py-1 fw-500 shadow-none" type="button"
                                  onClick={() => { goToCheckout('bcf086d6-1a23-4bfc-8865-b84cb8474d74') }}>Enquire</button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
                          <div className="card minheight text-center">
                            <div className="card-header">
                              <h5 className="card-title text-4 mb-0">Trademark Registration</h5>
                            </div>
                            <div className="card-body">
                              <ul className="list-unstyled">
                                <li className="mb-3 text-black-50">Your brand is important because customers identifies you by your brand.</li>
                                <li className="mb-3 text-black-50">So you need to protect your brand by registering with CompanySearch.</li>
                                <li className="mb-3 text-black-50">Our Expert will help you pick the right trademark class.</li>
                                <li className="mb-3 text-black-50">We can get it done  it in 24 hours.</li>
                                <li><Link className="btn-link text-1" to='/trademark-registration'>More Details</Link></li>
                              </ul>
                              <div className="d-grid">
                                <button className="btn btn-sm btn-outline-primary text-5 py-1 fw-500 shadow-none" type="button"
                                  onClick={() => { goToCheckout('4ccb1c2f-401f-40c1-bc6a-8473b8fa4261') }}>Enquire</button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
                          <div className="card minheight text-center">
                            <div className="card-header">
                              <h5 className="card-title text-4 mb-0">Copyright Registration</h5>
                            </div>
                            <div className="card-body">
                              <ul className="list-unstyled">
                                <li className="mb-3 text-black-50">But it is easier today to steal your work and use it without your permission.</li>
                                <li className="mb-3 text-black-50">Many people dont copyright their work because of hectic process.</li>
                                <li className="mb-3 text-black-50">With CompanySearch you get protection without hassle as we take care of entire process.</li>
                                <li><Link className="btn-link text-1" to='/copyright-registration'>More Details</Link></li>
                              </ul>
                              <div className="d-grid">
                                <button className="btn btn-sm btn-outline-primary text-5 py-1 fw-500 shadow-none" type="button"
                                  onClick={() => { goToCheckout('584f9bed-b9b5-4705-9548-e699bca3fa9d') }}>Enquire</button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
                          <div className="card minheight text-center">
                            <div className="card-header">
                              <h5 className="card-title text-4 mb-0">Trademark Renewal</h5>
                            </div>
                            <div className="card-body">
                              <ul className="list-unstyled">
                                <li className="mb-3 text-black-50">Security of the Brand Name</li>
                                <li className="mb-3 text-black-50">Create a Unique Identity</li>
                                <li className="mb-3 text-black-50">Protection from any Litigation</li>
                                <li className="mb-3 text-black-50">Extension of Ownership Rights and Monetary Returns</li>
                                <li><Link className="btn-link text-1" to='/trademark-renewal'>More Details</Link></li>
                              </ul>
                              <div className="d-grid">
                                <button className="btn btn-sm btn-outline-primary text-5 py-1 fw-500 shadow-none" type="button"
                                  onClick={() => { goToCheckout('8f38b3e6-6c1e-47e0-a2d6-5eb6b147f900') }}>Enquire</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane fade " id="sevenTab" role="tabpanel" aria-labelledby="seven-tab">
                      <div className="row">
                        <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
                          <div className="card minheight text-center">
                            <div className="card-header">
                              <h5 className="card-title text-4 mb-0">FPO</h5>
                            </div>
                            <div className="card-body">
                              <ul className="list-unstyled">
                                <li className="mb-3 text-black-50">We guide you choosing right type of licence for your business.</li>
                                <li className="mb-3 text-black-50">We will take care of filling process.</li>
                                <li className="mb-3 text-black-50">We will explain you how run your business as per  rules.</li>
                                <li><Link className="btn-link text-1" to='/fpo'>More Details</Link></li>
                              </ul>
                              <div className="d-grid">
                                <button className="btn btn-sm btn-outline-primary text-5 py-1 fw-500 shadow-none" type="button"
                                  onClick={() => { goToCheckout('2446d3a0-9098-467e-b8c7-fccec5f68b66') }}>Enquire</button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
                          <div className="card minheight text-center">
                            <div className="card-header">
                              <h5 className="card-title text-4 mb-0">FPC</h5>
                            </div>
                            <div className="card-body">
                              <ul className="list-unstyled">
                                <li className="mb-3 text-black-50">We guide you choosing right type of licence for your business.</li>
                                <li className="mb-3 text-black-50">We will take care of filling process.</li>
                                <li className="mb-3 text-black-50">We will explain you how run your business as per  rules.</li>
                                <li><Link className="btn-link text-1" to='/farmers-producer-company'>More Details</Link></li>
                              </ul>
                              <div className="d-grid">
                                <button className="btn btn-sm btn-outline-primary text-5 py-1 fw-500 shadow-none" type="button"
                                  onClick={() => { goToCheckout('22ca56f5-c3e1-47b8-8085-7b3943014c4d') }}>Enquire</button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
                          <div className="card minheight text-center">
                            <div className="card-header">
                              <h5 className="card-title text-4 mb-0">PACS</h5>
                            </div>
                            <div className="card-body">
                              <ul className="list-unstyled">
                                <li className="mb-3 text-black-50">Some of the PACS are also assisting farmers in marketing of their produce.</li>
                                <li className="mb-3 text-black-50">We will take care of filling process.</li>
                                <li className="mb-3 text-black-50">We will explain you how run your business as per  rules.</li>
                                <li><Link className="btn-link text-1" to='/pacs'>More Details</Link></li>
                              </ul>
                              <div className="d-grid">
                                <button className="btn btn-sm btn-outline-primary text-5 py-1 fw-500 shadow-none" type="button"
                                  onClick={() => { goToCheckout('34660386-3a47-4265-bd51-d70746b25cb8') }}>Enquire</button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
                          <div className="card minheight text-center">
                            <div className="card-header">
                              <h5 className="card-title text-4 mb-0">Equity Grant Scheme</h5>
                            </div>
                            <div className="card-body">
                              <ul className="list-unstyled">
                                <li className="mb-3 text-black-50">Equity Grant Scheme extends support to the equity base of Farmer Producer Companies (FPCs).</li>
                                <li className="mb-3 text-black-50">It provides matching equity grants subject to maximum of Rs.15 lakh per FPC in two tranche with in a period of 3 Year .</li>
                                <li><Link className="btn-link text-1" to='/equity-grant-scheme'>More Details</Link></li>
                              </ul>
                              <div className="d-grid">
                                <button className="btn btn-sm btn-outline-primary text-5 py-1 fw-500 shadow-none" type="button"
                                  onClick={() => { goToCheckout('0c9bce42-1ae9-4f65-8273-779c31917bb3') }}>Enquire</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section bg-white">
        <div className="container my-sm-4">
          <h2 className="text-9 text-center blue">Our Working Process</h2>
          <h5 className="lead text-center mb-5 text-4 blue"><strong>Delivering your Expectations</strong></h5>
          <div className="row">
            <div className="col-md-3">
              <div className="featured-box style-4">
                <div className="featured-box-icon bg-light-2 text-primary shadow-sm rounded-circle"> <i className='fas fa-briefcase' /> </div>
                <h3 className="text-5 fw-400 mb-3">Select service</h3>
                <p className="text-3">Select the type of service that best suit your needs.</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="featured-box style-4">
                <div className="featured-box-icon bg-light-2 text-primary shadow-sm rounded-circle"> <i className='fas fa-user-graduate' /> </div>
                <h3 className="text-5 fw-400 mb-3">Connect with Experts</h3>
                <p className="text-3">Our expert will connect with you & prepare documents.</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="featured-box style-4">
                <div className="featured-box-icon bg-light-2 text-primary shadow-sm rounded-circle"> <i className='far fa-file' /> </div>
                <h3 className="text-5 fw-400 mb-3">Submission </h3>
                <p className="text-3">Submit all the required Documents & information.</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="featured-box style-4">
                <div className="featured-box-icon bg-light-2 text-primary shadow-sm rounded-circle"> <i className='far fa-registered' /></div>
                <h3 className="text-5 fw-400 mb-3">Get Registration</h3>
                <p className="text-3">You are ready to embark on your entrepreneurial journey.</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
            </div>
            <div className="col-md-4">
              <div className="featured-box style-4">
                <div className="featured-box-icon bg-light-2 text-primary shadow-sm rounded-circle"><i className="fa fa-play-circle" />  </div>
                <h3 className="text-5 fw-400 mb-3">Our Work Process as Video</h3>
                <p className="text-3">Watch the video to learn how we work for you….</p>
              </div>
            </div>
            <div className="col-md-4">
            </div>
          </div>
        </div>
      </section>
      <section className="section bg-primary">
        <div className="container my-sm-3">
          <div className="row">
            <div className="container">
              <h2 className="text-9 fw-500 text-center white mb-3">What clients are saying about us</h2>
              <div className="tab-content mt-4" >
                <div className="tab-pane fade show active" role="tabpanel" aria-labelledby="operator-one-tab">
                  <div>
                    <div className="brands-grid">
                      <div className="row m-3 owl-carousel owl-theme dispay:contents" data-autoplay="true" data-loop="true" data-margin="40" data-items-xs="1" data-items-sm="1" data-items-md="1" data-items-lg="2">
                        <div className="item  client-item1    testimonial   bg-white rounded shadow-sm text-center p-3"><p className="text-3">“CompanySearch   revert promptly every time I need any information & commitment you have shown with your profession. It's really commendable.”</p><span className="text-warning"><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i></span> <span className="d-block fw-500">Sandeep Ray</span>
                        </div>
                        <div className="item client-item1  testimonial  bg-white  rounded shadow-sm text-center p-3"><p className="text-3">“My overall experience was great. Every member supported me at every stage. Services are fast transparent and without hustle. I thank the CompanySearch team for their assistance.”</p><span className="text-warning"><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i></span> <span className="d-block fw-500">Lijo George</span>
                        </div>
                        <div className="item client-item1  testimonial bg-white rounded shadow-sm text-center p-3"><p className="text-3">“Thank you CompanySearch for the excellent service provided in incorporating my company.Both  prices are competitive and the person assigned to me was good . ”</p><span className="text-warning"><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i></span> <span className="d-block fw-500">Shailja Patil</span>
                        </div>
                        <div className="item client-item1  testimonial bg-white rounded shadow-sm text-center p-3"><p className="text-3">“My overall experience was great. Every member supported me at every stage. Services are fast transparent and without hustle. I thank the CompanySearch team for their assistance.”</p><span className="text-warning"><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i></span> <span className="d-block fw-500">	Lijo George</span>
                        </div>
                        <div className="item client-item1  testimonial bg-white rounded shadow-sm text-center p-3"><p className="text-3">“CompanySearch is the best Professional Legal Service provider, the employees of this company are very supportive and guided me throughout the process. I highly recommend this company.”</p><span className="text-warning"><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i></span> <span className="d-block fw-500">Sachin Shaha</span>
                        </div>
                        <div className="item client-item1  testimonial bg-white rounded shadow-sm text-center p-3"><p className="text-3">“I was happy with the service provided by CompanySearch & following up constantly and helped to get the TM Application done. ”</p><span className="text-warning"><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i></span> <span className="d-block fw-500">Anwar Shaikh</span>
                        </div>
                        <div className="item client-item1  testimonial bg-white rounded shadow-sm text-center p-3"><p className="text-3">“We were searching for a company for assistance in the incorporation services. The CompanySearch team is really efficient and has an experienced staff to guide us through the entire process.”</p><span className="text-warning"><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i></span> <span className="d-block fw-500">Shailendra Sharma</span>
                        </div>
                        <div className="item client-item1  testimonial bg-white rounded shadow-sm text-center p-3"><p className="text-3">“CompanySearch provides excellent service using technology to track, update and process their services. I did GST registration, it was done efficiently. I recommend CompanySearch .”</p><span className="text-warning"><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i></span> <span className="d-block fw-500">Sharad Mehtre</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section bg-white">
        <div className="container my-sm-4">
          <div className="row">
            <div className="container">
              <h2 className="text-9 fw-500 text-center mb-4">Our Clients</h2>
              <div className="tab-content mt-4" >
                <div className="tab-pane fade show active" id="myPartnersContent" role="tabpanel" aria-labelledby="operator-one-tab">
                  <div>
                    <div className="brands-grid">
                      <div className="row m-2 owl-carousel owl-theme" data-autoplay="true" data-loop="true" data-margin="40" data-items-xs="3" data-items-sm="3" data-items-md="3" data-items-lg="6">
                        <div className="item client-item text-center m-1"><a><img className="img-fluid" src="assets/images/partners/Bhoomy.png" alt="Brands" style={{ marginTop: '20%' }} /> <span className="d-block"></span></a> </div>
                        <div className="item client-item text-center m-1"><a><img className="img-fluid" src="assets/images/partners/Cemex-Infra.jpeg" alt="Brands" style={{ marginTop: '20%', width: '75%' }} /><span className="d-block"></span></a></div>
                        <div className="item client-item text-center m-1"><a><img className="img-fluid" src="assets/images/partners/Eco-Opus.png" alt="Brands" style={{ marginTop: '40%' }} /><span className="d-block"></span></a></div>
                        <div className="item client-item text-center m-1"><a><img className="img-fluid" src="assets/images/partners/Krushi-Ankur.png" alt="Brands" style={{ marginTop: '10%' }} /><span className="d-block"></span></a></div>
                        <div className="item client-item text-center m-1"><a><img className="img-fluid" src="assets/images/partners/Magnatic.jpg" alt="Brands" style={{ marginTop: '25%' }} /><span className="d-block"></span></a></div>
                        <div className="item client-item text-center m-1"><a><img className="img-fluid" src="assets/images/partners/Shri-Bhadra-Milk.jpg" alt="Brands" style={{ marginTop: '10%' }} /><span className="d-block"></span></a></div>
                        <div className="item client-item text-center m-1"><a><img className="img-fluid" src="assets/images/partners/Venture-Agro.png" alt="Brands" style={{ marginTop: '35%' }} /><span className="d-block"></span></a></div>
                        <div className="item client-item text-center m-1"><a><img className="img-fluid" src="assets/images/partners/Villgro.jpg" alt="Brands" style={{ marginTop: '17%', width: '80%' }} /><span className="d-block"></span></a></div>
                        <div className="item client-item text-center m-1"><a><img className="img-fluid" src="assets/images/partners/Tripoye.png" alt="Brands" style={{ marginTop: '20%', width: '75%' }} /><span className="d-block"></span></a></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className='section p-3 bg-primary'>
        <div className="container my-sm-4">
          <div className="row">
            <div className="container">
              <h2 className="text-9 fw-500 text-center mb-4 text-white">Blogs</h2>
              <div className="col-lg-12 col-xl-12">
                <div className='row g-4'>
                  {resentBlogList?.map((rblg, index) => ( 
                    <div className='col-md-6' key={index}>
                      <div className="blog-post card shadow-sm border-0 mb-4">
                        <div className="row g-0 p-3">
                          <div className="col-md-5"> 
                            <a className="cursor-pointer" onClick={() => gotoBlogDetails(rblg.blogId)}>
                              <div className='blog-image' style={{backgroundImage: "url('"+rblg.image+"')"}}></div>
                              {/* <img className="card-img d-block" src={rblg.image} alt="" /> */}
                            </a> 
                          </div>
                          <div className="col-md-7">
                            <div className="card-body pb-0 pt-3 pt-md-0 ps-0 ps-md-4 pe-0">
                              <h4 className="title-blog text-5">
                                <a className="cursor-pointer" onClick={() => gotoBlogDetails(rblg.blogId)}>{rblg.heading}</a>
                              </h4>
                              <ul className="meta-blog mb-1">
                                <li><i className="fas fa-calendar-alt" />{rblg.blogDate}</li>
                                <li><a ><i className="fas fa-user" /> Admin</a></li>
                              </ul>
                              <p><span dangerouslySetInnerHTML={{ __html: rblg?.description }}></span> <a className="cursor-pointer" onClick={() => gotoBlogDetails(rblg.blogId)}>[Read More]</a></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Middle Panel End */}
      <section className="section  bg-white p-0">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-9 col-xl-8 mx-auto">
              <div className="row">
                <div className="col-md-5 text-center align-bottom order-1 order-md-0"> <img className="img-fluid" alt="" src="assets/images/app-mobile-3.png" /> </div>
                <div className="col-md-7 my-auto order-0 order-md-1 text-center text-md-start">
                  <h2 className="text-9">Ease in doing business</h2>
                  <p className="text-4"><strong>Consult anywhere…</strong></p>
                  <ul className='textlegal'>
                    <li className="text-4 pb-2 ">Save money. Save time.</li>
                    <li className="text-4 pb-2">Now keep your Startup close to your heart.</li>
                    <li className="text-4 pb-2">Book your services at just one click.</li>
                    <li className="text-4 pb-2">Easily connect with our professionals.</li>
                    <li className="text-4 pb-2">Live Chat with our support team.</li>
                    <li className="text-4 pb-2">Download and get realtime update on your order status.</li>
                  </ul>
                  <div className="pt-2 mb-4 mb-md-0 position-relative">
                    <img className="img-fluid me-3 w-25" alt="qrcode" src="assets/images/qrcode.png" />
                    <a href="https://play.google.com/store/apps/details?id=com.averta.companysearch" target="_blank"><img className="img-fluid position-absolute w-25" style={{ top: "32%" }} alt="googleplaystore" src="assets/images/google-play-store.png" /></a> </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* mobileapp ends */}

    </>
  )
}
