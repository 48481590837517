import React from 'react'
import { Link, useParams } from 'react-router-dom';

declare var $: any;

export default function PaymentFail() {

  return (
    <>
      <section className="page-header page-header-dark bg-secondary">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-8">
              <h1>Payment Status</h1>
            </div>
            <div className="col-md-4">
              <ul className="breadcrumb justify-content-start justify-content-md-end mb-0">
                <li>
                  <Link to='/'>Home</Link>
                </li>
                <li className="active">Payment Status</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div id="content">
        <div className="container">
          <div className="row my-5">
            <div className="col-lg-12 text-center mt-5">
              <p className="text-danger text-16 lh-1"><i className="fas fa-times-circle"></i></p>
              <h2 className="text-8">Payment Failed</h2>
              <p className="lead">Your payment was unsuccessful. Please try again</p>
            </div>
            <div className="col-md-8 col-lg-6 col-xl-5 mx-auto">
              {/* <div className="bg-white shadow-sm rounded p-4 p-lg-5 mb-4">
                <div className="row">
                  <div className="col-sm text-muted">Transactions ID</div>
                  <div className="col-sm text-sm-end fw-600">PHDF173076359</div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm text-muted">Date</div>
                  <div className="col-sm text-sm-end fw-600">06-Feb-2019</div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm text-muted">Mode of Payment</div>
                  <div className="col-sm text-sm-end fw-600">Credit Card</div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm text-muted">Transaction Status</div>
                  <div className="col-sm text-sm-end fw-600 text-success">Success</div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm text-muted">Customer Name</div>
                  <div className="col-sm text-sm-end fw-600">Johne Cary</div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm text-muted">Mobile No</div>
                  <div className="col-sm text-sm-end fw-600">(+91) 9898989898</div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm text-muted">Subject</div>
                  <div className="col-sm text-sm-end fw-600">Mobile Recharge</div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm text-muted">Payment Amount</div>
                  <div className="col-sm text-sm-end text-6 fw-500">$135</div>
                </div>
              </div> */}
              <div className="text-center">
                {/* <a href="#" className="btn-link text-muted mx-3 my-2 align-items-center d-inline-flex">
                  <span className="text-5 me-2"><i className="far fa-file-pdf"></i></span>Save as PDF</a> 
                <a href="#" className="btn-link text-muted mx-3 my-2 align-items-center d-inline-flex">
                  <span className="text-5 me-2"><i className="fas fa-print"></i></span>Print Receipt</a> 
                <a href="#" className="btn-link text-muted mx-3 my-2 align-items-center d-inline-flex">
                  <span className="text-5 me-2"><i className="far fa-envelope"></i></span>Email Receipt</a> */}
                <p className="mt-4 mb-0"><Link className="btn btn-primary" to='/'>Go to Home</Link></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
