import React, { useEffect, useState } from 'react'
import ProfileSidebar from '../../ProfileSidebar/ProfileSidebar'
import * as constants from '../../../Constants/Constants'
import { useForm } from 'react-hook-form';
import ReactPaginate from 'react-paginate';
declare var $: any;

export default function ServiceRequests() {

  const [serviceRequestList, setServiceRequestList] = useState<any[]>();
  const [currentUser, setCurrentUser] = useState<any>();
  const [serviceRequestId, setServiceRequestId] = useState<string>();
  const { register, handleSubmit, setValue, formState: { errors } } = useForm();
  const { register: register2, handleSubmit: handleSubmit2, setValue: setValue2, formState: { errors: errors2 } } = useForm();
  const [formSubmitLoader, setFormSubmitLoader] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<any>();
  const [serviceRequest, setServiceRequest] = useState<any>();

  // VARIABLES FOR PAGINATION
  const [pageCount, setPageCount] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchKey, setSearchKey] = useState<string | null>(null);
  const [status, setStatus] = useState<string | null>(null);

  const [categoryList, setCategoryList] = useState<any[]>();
  const [subCategoryList, setSubCategoryList] = useState<any[]>();
  const [categoryId, setCategoryId] = useState<string | null>();
  const [subCategoryId, setSubCategoryId] = useState<string | null>();

  useEffect(() => {
    getCategoryList();
  }, []);

  useEffect(() => {
    if (localStorage.getItem('currentUser')) {
      let userData = JSON.parse(localStorage.getItem('currentUser')!);
      setCurrentUser(userData);
    }
    getServiceRequestList();
  }, [pageNum, searchKey, status, categoryId, subCategoryId]);

  const getCategoryList = () => {
    fetch(constants.BASE_URL + 'category/dropdown', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      if (response.status == '200') {
        setCategoryList(response.result);
      } else {
        constants.showError(response.message);
      }
    });
  }

  const onCategorySelect = (e: any) => {
    setCategoryId(e.target.value);
    fetch(constants.BASE_URL + 'category/sub/dropdown/' + e.target.value, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      if (response.status == '200') {
        setSubCategoryList(response.result);
      } else {
        constants.showError(response.message);
      }
    });
  }

  const onSubCategorySelect = (e: any) => {
    setSubCategoryId(e.target.value);
  }

  const getServiceRequestList = () => {
    let queryParams = '?pageNum=' + pageNum + '&numPerPage=' + itemsPerPage + (searchKey && searchKey.length > 2 ? '&searchBy=' + searchKey : '')
      + (status && status.length > 0 ? '&status=' + status : '')
      + (categoryId && categoryId.length > 0 && (!subCategoryId || subCategoryId.length == 0) ? '&categoryId=' + categoryId : '')
      + (subCategoryId && subCategoryId.length > 0 ? '&subCategoryId=' + subCategoryId : '');
    fetch(constants.BASE_URL + 'service-request' + queryParams, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      if (response.status == '200') {
        response.result.forEach((element: any) => {
          element['serviceAmountStr'] = element.serviceAmount?.toLocaleString();
          element['totalAmountStr'] = element.totalAmount?.toLocaleString();
          element['paidAmountStr'] = element.paidAmount?.toLocaleString();
        });
        setServiceRequestList(response.result);
        setPageCount(Math.ceil(response.listCount / itemsPerPage));
      } else {
        constants.showError(response.message);
      }
    });
  }

  const handlePageClick = (event: any) => {
    setPageNum(event.selected + 1);
  };

  const prepareUpdatePrice = (serviceRequestId: string, totalAmount: number) => {
    setServiceRequestId(serviceRequestId);
    setTimeout(() => {
      setValue('totalAmount', totalAmount, { shouldDirty: true });
    }, 500);
    $('#payment-modal').modal('show');
  }

  const updateServicePrice = (data: any) => {
    setFormSubmitLoader(true);
    fetch(constants.BASE_URL + 'service-request/amount/' + serviceRequestId, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
      body: JSON.stringify(data)
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      setFormSubmitLoader(false);
      if (response.status == '200') {
        constants.showSuccess(response.message);
        $('#payment-modal').modal('hide');
        getServiceRequestList();
      } else {
        constants.showError(response.message);
      }
    });
  }

  const viewUserDetails = (user: any) => {
    setSelectedUser(user);
    $('#user-modal').modal('show');
  }

  const searchByService = (e: any) => {
    let searchKey = e.target.value;
    if (searchKey.length >= 3 || searchKey.length == 0) {
      setSearchKey(searchKey);
    }
  }

  const searchByStatus = (status: string | null) => {
    setStatus(status);
  }

  const prepareEditServiceRequest = (serviceRequest: any) => {
    setServiceRequest(serviceRequest);
    setTimeout(() => {
      setValue2('comments', serviceRequest.comments)
    }, 500);
    $('#comments-modal').modal('show');
  }

  const updateServiceComments = (data: any) => {
    setFormSubmitLoader(true);
    let urlToAppend = "?comments=" + data['comments'] + '&closeStatus=' + data['closeStatus'];
    fetch(constants.BASE_URL + 'service-request/comments/' + serviceRequest.serviceRequestId + urlToAppend, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
      body: JSON.stringify({})
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      setFormSubmitLoader(false);
      if (response.status == '200') {
        constants.showSuccess(response.message);
        $('#comments-modal').modal('hide');
        getServiceRequestList();
      } else {
        constants.showError(response.message);
      }
    });
  }

  return (
    <>
      <section className="page-header page-header-dark bg-secondary">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-8">
              <h1>My Profile</h1>
            </div>
            <div className="col-md-4">
              <ul className="breadcrumb justify-content-start justify-content-md-end mb-0">
                <li><a href="/">Home</a></li>
                <li className="active">My Profile</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div className="container-fluid p-5 mb-2">
        <div className="row">
          <div className="col-lg-2">
            <ProfileSidebar />
          </div>
          <div className="col-lg-10">
            <div className="bg-white shadow-md rounded p-4">
              <h4 className="mb-4">Service Requests</h4>
              <hr className="mx-n4" />
              <ul className="nav nav-tabs mb-4 position-relative" id="myTab" role="tablist">
                <div className='col-md-1.5 col-sm-1.5 col-xs-2'>
                  <li className="nav-item cursor-pointer" onClick={() => searchByStatus(null)}> <a className={"nav-link" + (!status ? ' active' : '')} id="first-tab">All</a></li>
                </div>
                <div className='col-md-1.5 col-sm-1.5 col-xs-2'>
                  <li className="nav-item cursor-pointer" onClick={() => searchByStatus('PARTIALLY_PAID')}> <a className={"nav-link" + (status == 'PARTIALLY_PAID' ? ' active' : '')} id="second-tab">Ongoing</a> </li>
                </div>
                <div className='col-md-1.5 col-sm-1.5 col-xs-2'>
                  <li className="nav-item cursor-pointer" onClick={() => searchByStatus('PAYMENT_AWAITED')}> <a className={"nav-link" + (status == 'PAYMENT_AWAITED' ? ' active' : '')} id="third-tab">Payment Awaited</a> </li>
                </div>
                <div className='col-md-1.5 col-sm-1.5 col-xs-2'>
                  <li className="nav-item cursor-pointer" onClick={() => searchByStatus('PAID')}> <a className={"nav-link" + (status == 'PAID' ? ' active' : '')} id="fourth-tab">Paid</a> </li>
                </div>
                <div className='col-md-1.5 col-sm-1.5 col-xs-2'>
                  <li className="nav-item cursor-pointer" onClick={() => searchByStatus('CLOSED')}> <a className={"nav-link" + (status == 'CLOSED' ? ' active' : '')} id="fifth-tab">Closed</a> </li>
                </div>
                <div className='col-md-1.5 col-sm-1.5 col-xs-2'>
                  <li className="nav-item cursor-pointer" onClick={() => searchByStatus('PENDING')}> <a className={"nav-link" + (status == 'PENDING' ? ' active' : '')} id="sixth-tab">Failed</a> </li>
                </div>
                <div className='col-md-2 col-sm-2 col-xs-2'></div>
                <div className='col-md-3 col-sm-3 col-xs-3'>
                  <li className="nav-item pt-2">
                    
                  </li>
                </div>
              </ul>
              <div className="tab-content my-3" id="myTabContent">
                <div className="tab-pane fade show active" id="first" role="tabpanel" aria-labelledby="first-tab">
                  <div className='row'>
                    <div className='col form-group'>
                      <label className='form-label'>Category</label>
                      <select className='form-control form-control-sm' onChange={onCategorySelect}>
                        <option value="">Select Category</option>
                        {categoryList?.map((cat, index) => (
                          <option key={index} value={cat.categoryId}>{cat.categoryName}</option>
                        ))}
                      </select>
                    </div>
                    <div className='col'>
                      <label className='form-label'>Sub Category</label>
                      <select className='form-control form-control-sm' onChange={onSubCategorySelect}>
                        <option value="">Select Sub Category</option>
                        {subCategoryList?.map((subCat, index) => (
                          <option key={index} value={subCat.categoryId}>{subCat.categoryName}</option>
                        ))}
                      </select>
                    </div>
                    <div className='col'>
                      <label className='form-label'>Search by Service Name</label>
                      <input type="text" className='form-control form-control-sm'
                        placeholder='Search by Service Name' onChange={searchByService} />  
                    </div>
                  </div>
                  <div className="table-responsive-md mt-4">
                    <table className="table table-hover border">
                      <thead>
                        <tr>
                          <th>Sr No</th>
                          <th>Customer</th>
                          <th>Service</th>
                          <th>Request Date</th>
                          <th>Amount(₹)</th>
                          <th>Tax(%)</th>
                          <th>Total Amount(₹)</th>
                          <th>Paid Amount(₹)</th>
                          <th>Status</th>
                          <th>Comments</th>
                          <th style={{ minWidth: '88px' }}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {serviceRequestList?.map((ser, index) => (
                          <tr key={index}>
                            <td className="align-middle">{((pageNum - 1) * itemsPerPage) + (index + 1)}</td>
                            <td className="align-middle">
                              <a className="btn-link cursor-pointer" onClick={() => viewUserDetails(ser.user)}>{ser?.user?.fullName}</a>
                            </td>
                            <td className="align-middle">{ser?.service?.serviceName}</td>
                            <td className="align-middle">{ser?.requestDate}</td>
                            <td className="align-middle">{ser?.serviceAmountStr}</td>
                            <td className="align-middle">{ser?.taxPercent}</td>
                            <td className="align-middle">{ser?.totalAmountStr}</td>
                            <td className="align-middle">{ser?.paidAmountStr}</td>
                            <td className="align-middle">{ser?.status}</td>
                            <td className="align-middle">{ser?.comments}</td>
                            <td className="align-middle" style={{ minWidth: '88px' }}>
                              {ser?.status == 'PARTIALLY_PAID' &&
                                <>
                                  <button className="featured-box-icon bg-primary text-white rounded-circle border-0 edit-icon"
                                    title='Update final price' style={{ padding: "3px 10px" }}
                                    onClick={() => prepareUpdatePrice(ser.serviceRequestId, ser.serviceAmount)}>
                                    <i className="fas fa-rupee-sign"></i>
                                  </button>
                                </>
                              }
                              {(ser?.status == 'PARTIALLY_PAID' || ser?.status == 'PAID') &&
                                <button className="featured-box-icon bg-primary text-white rounded-circle border-0 edit-icon ms-1"
                                  title='Comments and status'
                                  onClick={() => prepareEditServiceRequest(ser)}>
                                  <i className="fas fa-comments"></i>
                                </button>
                              }
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <ReactPaginate breakLabel="..." nextLabel="next >" onPageChange={handlePageClick} pageRangeDisplayed={5}
                      pageCount={pageCount} previousLabel="< previous" pageClassName="page-item" pageLinkClassName="page-link"
                      previousClassName="page-item" previousLinkClassName="page-link" nextClassName="page-item" nextLinkClassName="page-link"
                      breakClassName="page-item" breakLinkClassName="page-link" containerClassName="pagination" activeClassName="active"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="payment-modal" className="modal fade" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content border-0">
            <div className="modal-header">
              <h5 className="modal-title">Update total service charges (₹) </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body py-4 px-0">
              <div className="row">
                <div className="col-11 col-md-10 mx-auto">
                  <form id="signupForm" method="post" onSubmit={handleSubmit(updateServicePrice)}>
                    <div className="mb-3">
                      <input type="number" className="form-control" id="totalAmount" placeholder="Total Amount"
                        {...register("totalAmount", { required: true })} />
                      {errors.totalAmount && <span className='text-danger'>Please enter total amount</span>}
                    </div>
                    <div className="d-grid my-4">
                      <button className="btn btn-primary" type="submit" disabled={formSubmitLoader}>
                        {formSubmitLoader && <i className="fas fa-circle-notch fa-spin me-2"></i>}
                        Update
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="comments-modal" className="modal fade" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content border-0">
            <div className="modal-header">
              <h5 className="modal-title">Service Request Comments</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body py-4 px-0">
              <div className="row">
                <div className="col-11 col-md-10 mx-auto">
                  <form id="commentsForm" method="post" onSubmit={handleSubmit2(updateServiceComments)}>
                    <div className="mb-3">
                      <label className="form-label" htmlFor="comments">Comments<span className='red'>*</span></label>
                      <textarea className="form-control" id="comments" placeholder="Comments"
                        {...register2("comments", { required: true })} ></textarea>
                      {errors2.comments && <span className='text-danger'>Please provide comments</span>}
                    </div>
                    <div className="mb-3">
                      <label className="form-label" htmlFor="comments">
                        <input type="checkbox" {...register2("closeStatus")} />
                        <span className='ms-2'>Close service request?</span>
                      </label>
                    </div>
                    <div className="d-grid my-4">
                      <button className="btn btn-primary" type="submit" disabled={formSubmitLoader}>
                        {formSubmitLoader && <i className="fas fa-circle-notch fa-spin me-2"></i>}
                        Update
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="user-modal" className="modal fade" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div className="modal-content border-0">
            <div className="modal-header">
              <h5 className="modal-title">Customer Details</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body py-4 px-0">
              <div className="row ps-4 pe-4">
                <div className="col mx-auto">
                  <div className="mb-3">
                    <label>Customer Name</label>
                    <p className='fw-bold fs-6'>{selectedUser?.fullName}</p>
                  </div>
                </div>
                <div className="col mx-auto">
                  <div className="mb-3">
                    <label>Mobile</label>
                    <p className='fw-bold fs-6'>{selectedUser?.mobile}</p>
                  </div>
                </div>
              </div>
              <div className="row ps-4 pe-4">
                <div className="col mx-auto">
                  <div className="mb-3">
                    <label>Email</label>
                    <p className='fw-bold fs-6'>{selectedUser?.email}</p>
                  </div>
                </div>
                <div className="col mx-auto">
                  <div className="mb-3">
                    <label>Profile Type</label>
                    <p className='fw-bold fs-6'>{selectedUser?.profileType}</p>
                  </div>
                </div>
              </div>
              {selectedUser && selectedUser?.profileType == 'INDIVIDUAL' &&
                <>
                  <div className="row ps-4 pe-4">
                    <div className="col mx-auto">
                      <div className="mb-3">
                        <label>Gender</label>
                        <p className='fw-bold fs-6'>{selectedUser?.gender}</p>
                      </div>
                    </div>
                    <div className="col mx-auto">
                      <div className="mb-3">
                        <label>Date of Birth</label>
                        <p className='fw-bold fs-6'>{selectedUser?.dateOfBirth}</p>
                      </div>
                    </div>
                  </div>
                </>
              }
              {selectedUser && selectedUser?.profileType == 'COMPANY' &&
                <>
                  <div className="row ps-4 pe-4">
                    <div className="col mx-auto">
                      <div className="mb-3">
                        <label>Business Type</label>
                        <p className='fw-bold fs-6'>{selectedUser?.businessType}</p>
                      </div>
                    </div>
                    <div className="col mx-auto">
                      <div className="mb-3">
                        <label>Age of Business</label>
                        <p className='fw-bold fs-6'>{selectedUser?.ageOfBusiness}</p>
                      </div>
                    </div>
                  </div>
                  <div className="row ps-4 pe-4">
                    <div className="col mx-auto">
                      <div className="mb-3">
                        <label>Total Employee</label>
                        <p className='fw-bold fs-6'>{selectedUser?.totalEmployee}</p>
                      </div>
                    </div>
                    <div className="col mx-auto">
                      <div className="mb-3">
                        <label>Business Status</label>
                        <p className='fw-bold fs-6'>{selectedUser?.orgStatus}</p>
                      </div>
                    </div>
                  </div>
                </>
              }
              <br />
              <h5 className='ps-4 pe-4'>Address Details</h5>
              <hr />
              <div className="row ps-4 pe-4">
                <div className="col mx-auto">
                  <div className="mb-3">
                    <label>Address Line 1</label>
                    <p className='fw-bold fs-6'>{selectedUser?.address?.addressLineOne}</p>
                  </div>
                </div>
                <div className="col mx-auto">
                  <div className="mb-3">
                    <label>Address Line 2</label>
                    <p className='fw-bold fs-6'>{selectedUser?.address?.addressLineTwo}</p>
                  </div>
                </div>
              </div>
              <div className="row ps-4 pe-4">
                <div className="col mx-auto">
                  <div className="mb-3">
                    <label>City</label>
                    <p className='fw-bold fs-6'>{selectedUser?.address?.city}</p>
                  </div>
                </div>
                <div className="col mx-auto">
                  <div className="mb-3">
                    <label>State</label>
                    <p className='fw-bold fs-6'>{selectedUser?.address?.state}</p>
                  </div>
                </div>
              </div>
              <div className="row ps-4 pe-4">
                <div className="col mx-auto">
                  <div className="mb-3">
                    <label>ZipCode</label>
                    <p className='fw-bold fs-6'>{selectedUser?.address?.zipCode}</p>
                  </div>
                </div>
                <div className="col mx-auto">
                  <div className="mb-3">
                    <label>Country</label>
                    <p className='fw-bold fs-6'>{selectedUser?.address?.country}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
