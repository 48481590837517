import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { NavLink, useNavigate } from 'react-router-dom';
import * as constants from '../../Constants/Constants'

export default function ContactUs () {

  const navigate = useNavigate();
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const [formSubmitLoader, setFormSubmitLoader] = useState<boolean>(false);

  const saveContactForm = (data: any) => {
    setFormSubmitLoader(true);
    fetch(constants.BASE_URL + 'contact-us', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
      body: JSON.stringify(data)
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      setFormSubmitLoader(false);
      if (response.status == '201') {
        constants.showSuccess(response.message);
        navigate("/");
      } else {
        constants.showError(response.message);
      }
    });
  }

  return (
    <div>
      <section className="page-header page-header-dark bg-secondary">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-8">
              <h1>Contact Us</h1>
            </div>
            <div className="col-md-4">
              <ul className="breadcrumb justify-content-start justify-content-md-end mb-0">
                <li><NavLink to="/">Home</NavLink></li>
                <li className="active">Contact Us</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div id="content">
        <div className="container my-4">
          <div className="row g-4">
            <div className="col-md-6">
              <div className="bg-white shadow-md rounded p-4">
                <h2 className="text-6 mb-4">Contact Us</h2>
                <hr className="mx-n4 mb-4" />
                <div className="container py-4">
                  <form id="contactForm" method="post" onSubmit={handleSubmit(saveContactForm)}>
                    <div className="mb-3">
                      <input className="form-control" id="name" type="text" placeholder="Name" 
                        {...register("name", { required: true, pattern:/[a-zA-Z][a-zA-Z ]{3,}/ })}/>
                      {errors.name && <span className='text-danger'>Please enter your valid name</span>}
                    </div>
                    <div className="mb-3">
                      <input type="text" className="form-control" placeholder="Mobile/Landline Number" id='mobileNumber' 
                        {...register("mobile", { required: true ,pattern:/^(\+91[\-\s]?)?[0]?(91)?[789]\d{9}$/, minLength: 10, maxLength: 13 })}/>
                      {errors.mobile && <span className='text-danger'>Please enter valid mobile number</span>}
                    </div>
                    <div className="mb-3">
                      <input className="form-control" id="emailAddress" type="email" placeholder="Email Address" 
                        {...register("email", { required: true , pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })}/>
                      {errors.email && <span className='text-danger'>Please enter valid email id</span>}
                    </div>
                    <div className="mb-3">
                      <textarea className="form-control" id="message" placeholder="Message" rows={2} 
                        {...register("message", { required: true , maxLength:250 })}/>
                      {errors.message && <span className='text-danger'>Please enter message</span>}
                    </div>
                    <div className="d-grid">
                      <button className="btn btn-primary btn-lg" type="submit" disabled={formSubmitLoader}>
                        {formSubmitLoader && <i className="fas fa-circle-notch fa-spin me-2"></i>}
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="bg-white shadow-md rounded p-4">
                <h2 className="text-6 mb-4">Get in touch</h2>
                <hr className="mx-n4 mb-4" />
                <div className="featured-box style-1">
                  <div className="featured-box-icon text-primary"> <i className="fas fa-map-marker-alt" /></div>
                  <h3>CompanySearch</h3>
                  <address>A202,<br />
                    Devgiri Apartment,<br />
                    Ganeshmala,<br />
                    Sinhagad Road,<br />
                    Pune 411030
                  </address>
                </div>
                <div className="featured-box style-1 mt-4">
                  <div className="featured-box-icon text-primary"> <i className="fas fa-phone" /> </div>
                  <h3>Telephone</h3>
                  <a href="tel:+918484974171">(+91) 8484974171</a>
                </div>
                <div className="featured-box style-1 mt-4">
                  <div className="featured-box-icon text-primary"> <i className="fas fa-envelope" /> </div>
                  <h3>Business Inquiries</h3>
                  <p><a href="mailto:someone@example.com">info@companysearch.com</a></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
