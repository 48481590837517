import React from 'react';
import { NavLink } from 'react-router-dom';
import "./footer.css"

export default function Footer() {
  return (
    <footer id="footer" className="bg-primary mt-0 pt-4">
      <div className="container">
        <div className="row g-4">
          <div className="col-md-4">
            <div className="logo me-2 me-lg-3 text-5">
              <a href="/" title="Company Search">
                <p className='mb-0 logo-mainft position-relative'>
                  <span className='logo-leftft'>C<span className='text-danger'>O</span>MPANY</span>
                  <span className='logo-rightft'>SEAR<span className='text-danger'>C</span>H</span>
                  {/* <span className='position-absolute logo-tmft'>TM</span> */}<sup className='trademarkft'>TM</sup>
                </p>
                <p className='logo-subft float mb-0 white'>Solution for Sustainable Entrepreneurs</p>
              </a>
            </div>
            {/* <p className='white'>Payment</p>
            <ul className="payments-types">
              <li><a href="#" target="_blank"> <img data-bs-toggle="tooltip" src="/assets/images/payment/visa.png" alt="visa" title="Visa" /></a></li>
              <li><a href="#" target="_blank"> <img data-bs-toggle="tooltip" src="/assets/images/payment/discover.png" alt="discover" title="Discover" /></a></li>
              <li><a href="#" target="_blank"> <img data-bs-toggle="tooltip" src="/assets/images/payment/paypal.png" alt="paypal" title="PayPal" /></a></li>
              <li><a href="#" target="_blank"> <img data-bs-toggle="tooltip" src="/assets/images/payment/american.png" alt="american express" title="American Express" /></a></li>
              <li><a href="#" target="_blank"> <img data-bs-toggle="tooltip" src="/assets/images/payment/mastercard.png" alt="discover" title="Discover" /></a></li>
            </ul> */}
          </div>
          <div className="col-md-4">

          </div>
          <div className="col-md-4 d-flex align-items-md-end flex-column">
            <p className='white'>Keep in touch</p>
            <ul className="social-icons">
              <li className="social-icons-facebook"><a data-bs-toggle="tooltip" href="http://www.facebook.com/" target="_blank" title="Facebook"><i className="fab fa-facebook-f" /></a></li>
              {/* <li className="social-icons-facebook"><a data-bs-toggle="tooltip" href="http://www.facebook.com/" target="_blank" title="Facebook"><i className="fab fa-facebook-f" /></a></li> */}
              <li className="social-icons-twitter"><a data-bs-toggle="tooltip" href="http://www.twitter.com/" target="_blank" title="Twitter"><i className="fab fa-twitter" /></a></li>
              <li className="social-icons-google"><a data-bs-toggle="tooltip" href="http://www.google.com/" target="_blank" title="Google"><i className="fab fa-google" /></a></li>
              <li className="social-icons-linkedin"><a data-bs-toggle="tooltip" href="http://www.linkedin.com/" target="_blank" title="Linkedin"><i className="fab fa-linkedin-in" /></a></li>
              <li className="social-icons-youtube"><a data-bs-toggle="tooltip" href="http://www.youtube.com/" target="_blank" title="Youtube"><i className="fab fa-youtube" /></a></li>
              <li className="social-icons-instagram"><a data-bs-toggle="tooltip" href="http://www.instagram.com/" target="_blank" title="Instagram"><i className="fab fa-instagram" /></a></li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container mt-4">
        <div className="row g-4">
          <div className="col-md-3">
            <h6 className='text-white'>Business Setup</h6>
            <hr className='footer-cat-hr'/>
            <p className='mb-1'><NavLink to="/private-limited-company"><span className='text-white'>Private Limited Company</span></NavLink></p>
            <p className='mb-1'><NavLink to="/public-limited-company"><span className='text-white'>Public Limited Company</span></NavLink></p>
            <p className='mb-1'><NavLink to="/one-person-company"><span className='text-white'>One Person Company(OPC)</span></NavLink></p>
            <p className='mb-1'><NavLink to="/limited-liability-partnership"><span className='text-white'>Limited Liability Partnership (LLP)</span></NavLink></p>
            <p className='mb-1'><NavLink to="/partnership-firm"><span className='text-white'>Partnership Firm</span></NavLink></p>
          </div>
          <div className="col-md-3">
            <h6 className='text-white'>Licence & Registrations</h6>
            <hr className='footer-cat-hr'/>
            <p className='mb-1'><NavLink to="fssai-registration"><span className='text-white'>FSSAI</span></NavLink></p>
            <p className='mb-1'><NavLink to="shop-and-establishment"><span className='text-white'>Shop and Establishment</span> </NavLink></p>
            <p className='mb-1'><NavLink to="12A-and-80G"><span className='text-white'> Sec.12A & Sec.80 GS Registration</span></NavLink></p>
            <p className='mb-1'><NavLink to="12AA"><span className='text-white'>12AA Registration</span></NavLink></p>
            <p className='mb-1'><NavLink to="12AB"><span className='text-white'>12AB Registration</span></NavLink></p>
          </div>
          <div className="col-md-3">
            <h6 className='text-white'>Compliances</h6>
            <hr className='footer-cat-hr'/>
            <p className='mb-1'><NavLink to="/annual-filling-company"><span className='text-white'>Company / OPC</span></NavLink></p>
            <p className='mb-1'><NavLink to="/annual-filling-llp"><span className='text-white'>LLP</span></NavLink></p>
            <p className='mb-1'><NavLink to="/private-limited-company-compliance"><span className='text-white'>Company / OPC</span></NavLink></p>
            <p className='mb-1'><NavLink to="/nidhi-company-compliance"><span className='text-white'>Nidhi Company</span></NavLink></p>
            <p className='mb-1'><NavLink to="/secrion8-company-compliance"><span className='text-white'>Section 8 Company</span></NavLink></p>
          </div>
          <div className="col-md-3">
            <h6 className='text-white'>Audit & Taxation</h6>
            <hr className='footer-cat-hr'/>
            <p className='mb-1'><NavLink to="/audit-company-accounts"><span className='text-white'>Company Audit</span></NavLink></p>
            <p className='mb-1'><NavLink to="/secretorial-audit"><span className='text-white'> Secretarial Audit</span></NavLink></p>
            <p className='mb-1'><NavLink to="/tax-audit"><span className='text-white'>Tax Audit</span></NavLink></p>
            <p className='mb-1'><NavLink to="/individual-income-tax-returns"><span className='text-white'>Personal Tax Filing</span></NavLink></p>
            <p className='mb-1'><NavLink to="/income-tax-return-for-business"><span className='text-white'>Business Tax Filing</span></NavLink></p>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="footer-copyright">
          <ul className="nav justify-content-center">
            <li className="nav-item white "> <NavLink to="/aboutus" className="nav-link csfooter">About Us</NavLink></li>
            <li className="nav-item white"> <NavLink to="/contactus" className="nav-link csfooter">Contact Us</NavLink></li>
            <li className="nav-item white"> <NavLink to="/termsofuse" className="nav-link csfooter">Terms of Use</NavLink></li>
            <li className="nav-item white"> <NavLink to="/privacypolicy" className="nav-link csfooter">Privacy Policy</NavLink></li>
            <li className="nav-item white"> <NavLink to="/refundpolicy" className="nav-link csfooter">Refund Policy</NavLink></li>

          </ul>
          <p className="copyright-text">Copyright © 2022 <a href="#" className='csfooter' >CompanySearch</a>. All Rights Reserved.</p>
        </div>
      </div>
    </footer>
  )
}
