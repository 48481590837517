import React, { useEffect, useState } from "react";
import ReactPaginate from 'react-paginate';
import { useNavigate, useParams } from "react-router-dom";
import * as constants from '../../Constants/Constants'

export default function BlogDetails() {

  const { blogId } = useParams();
  let navigate = useNavigate();
  const [resentBlogList, setResentBlogList] = useState<any[]>();
  const [blog, setBlog] = useState<any>();
  const [tags, setTags] = useState<string[]>();

  useEffect(() => {
    window.scrollTo(0, 0);
    getResentBlogList();
    getBlogDetails();
  }, [blogId]);

  const getResentBlogList = () => {
    fetch(constants.BASE_URL + 'blog/list?sortBy=createdDate&pageNum=1&numPerPage=3', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      if (response.status == '200') {
        setResentBlogList(response.result);
      }
    });
  }

  const getBlogDetails = () => {
    fetch(constants.BASE_URL + 'blog/' + blogId, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      if (response.status == '200') {
        if (response.result.image) {
          response.result.image = getImageUrl(response.result.image);
        }
        if (response.result.tags) {
          setTags(response.result.tags.split(','));
        }  
        setBlog(response.result);
      }
    });
  }

  const getImageUrl = (imageName: string) => {
    let baseUrl = constants.BASE_URL;
    return baseUrl + 'file/image/' + imageName + '/'
  }

  const gotoBlogDetails = (blogId: string) => {
    navigate('/blog/' + blogId);
  }

  return (
    <>
      <section className="page-header page-header-dark bg-secondary">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-8">
              <h1>Blog Details</h1>
            </div>
            <div className="col-md-4">
              <ul className="breadcrumb justify-content-start justify-content-md-end mb-0">
                <li><a href="/">Home</a></li>
                <li><a href="/blogs">Blog</a></li>
                <li className="active">Blog Details</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div id="content">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-xl-9">
              <div className="blog-post card shadow-sm border-0 mb-4 p-4">
                <h2 className="title-blog text-7">{blog?.heading}</h2>
                <ul className="meta-blog mb-4">
                  <li><i className="fas fa-calendar-alt"></i> {blog?.blogDate}</li>
                  <li><a href=""><i className="fas fa-user"></i> Admin</a></li>
                  {/* <li><a href="#comments"><i className="fas fa-comments"></i> 03</a></li> */}
                </ul>
                <img className="img-fluid" src={blog?.image} alt="" />
                <div className="card-body px-0 pb-0">
                  <p dangerouslySetInnerHTML={{ __html: blog?.description }}></p>
                </div>
                <hr className="mb-4" />
                <div className="row mb-3">
                  <div className="col-lg-7 col-xl-8">
                    <div className="tags text-center text-lg-start"> 
                      {tags?.map((tg, index) => (    
                        <a key={index}>{tg}</a> 
                      ))}
                    </div>
                  </div>
                  <div className="col-lg-5 col-xl-4">
                    <div className="d-flex flex-column">
                      <ul className="social-icons social-icons-colored justify-content-center justify-content-lg-end">
                        {/* <li className="social-icons-facebook"><a data-bs-toggle="tooltip" href="http://www.facebook.com/" target="_blank" title="Facebook"><i className="fab fa-facebook-f"></i></a></li>
                        <li className="social-icons-twitter"><a data-bs-toggle="tooltip" href="http://www.twitter.com/" target="_blank" title="Twitter"><i className="fab fa-twitter"></i></a></li>
                        <li className="social-icons-google"><a data-bs-toggle="tooltip" href="http://www.google.com/" target="_blank" title="Google"><i className="fab fa-google"></i></a></li>
                        <li className="social-icons-linkedin"><a data-bs-toggle="tooltip" href="http://www.linkedin.com/" target="_blank" title="Linkedin"><i className="fab fa-linkedin-in"></i></a></li>
                        <li className="social-icons-instagram"><a data-bs-toggle="tooltip" href="http://www.instagram.com/" target="_blank" title="Instagram"><i className="fab fa-instagram"></i></a></li> */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <aside className="col-lg-4 col-xl-3" style={{position: 'sticky', top: '80px', alignSelf: 'flex-start'}}>
              <div className="bg-white shadow-sm rounded p-3 mb-4">
                <h4 className="text-5">Recent Posts</h4>
                <hr className="mx-n3" />
                <div className="side-post">
                  {resentBlogList?.map((rblg, index) => (    
                    <div className="item-post" key={index}>
                      <div className="caption"> <a className="cursor-pointer" onClick={() => gotoBlogDetails(rblg.blogId)}>{rblg.heading}</a>
                        <p className="date-post">{rblg.blogDate}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </aside>
          </div>
        </div>
      </div>
    </>
  )
}