import React, { useEffect, useState } from "react";
import ReactPaginate from 'react-paginate';
import { useNavigate } from "react-router-dom";
import * as constants from '../../Constants/Constants'
import './Blogs.css'

export default function Blogs() {

  let navigate = useNavigate();
  const [blogList, setBlogList] = useState<any[]>();
  const [resentBlogList, setResentBlogList] = useState<any[]>();
  const [tags, setTags] = useState<any[]>();
  const [tag, setTag] = useState<string | null>(null);
  const [searchKey, setSearchKey] = useState<string | null>(null);
  
  // VARIABLES FOR PAGINATION
  const [pageCount, setPageCount] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  useEffect(() => {
    getBlogList(null, null);
    getResentBlogList();
    getTags();
  }, [pageNum]);

  const handlePageClick = (event: any) => {
    setPageNum(event.selected + 1);
  };

  const getBlogList = (tg: string | null, sk: string | null) => {
    fetch(constants.BASE_URL + 'blog/list?pageNum=' + pageNum + '&numPerPage=' + itemsPerPage + (tg ? '&tag=' + tg : '') + (sk ? '&searchBy=' + sk : ''), {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      if (response.status == '200') {
        response.result.forEach((element: any) => {
          if (element.image) {
            element.image = getImageUrl(element.image);
          }
          if (element.description) {
            element.description = (element.description.substring(0, 230) + '....');
          }
          if (element.heading) {
            let length = element.heading.length;
            element.heading = (element.heading.substring(0, 80) + (length > 80 ? '...' : ''));
          }
        });
        setBlogList(response.result);
        setPageCount(Math.ceil(response.listCount / itemsPerPage));
      }
    });
  }

  const getResentBlogList = () => {
    fetch(constants.BASE_URL + 'blog/list?sortBy=createdDate&pageNum=1&numPerPage=3', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      if (response.status == '200') {
        setResentBlogList(response.result);
      }
    });
  }

  const getTags = () => {
    fetch(constants.BASE_URL + 'blog/list/tags', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      if (response.status == '200') {
        setTags(response.result);
      }
    });
  }

  const getImageUrl = (imageName: string) => {
    let baseUrl = constants.BASE_URL;
    return baseUrl + 'file/image/' + imageName + '/'
  }

  const searchByTag = (tag: string) => {
    setTag(tag);
    getBlogList(tag, searchKey);
  }

  const onSearchChange = (e: any) => {
    let sk = e.target.value;
    if(sk.length >= 3){
      setSearchKey(sk);
      getBlogList(tag, sk);
    }else if(sk.length == 0){
      setSearchKey(null);
      getBlogList(tag, null);
    }
  }

  const gotoBlogDetails = (blogId: string) => {
    navigate('/blog/' + blogId);
  }

  return (
    <>
      <section className="page-header page-header-dark bg-secondary">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-8">
              <h1>Blogs</h1>
            </div>
            <div className="col-md-4">
              <ul className="breadcrumb justify-content-start justify-content-md-end mb-0">
                <li><a href="/">Home</a></li>
                <li className="active">Blogs</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div id="content">
        <div className="container">
          <div className="row">
            <aside className="col-lg-4 col-xl-3 blog-aside">
              <div className="input-group shadow-sm mb-4">
                <input className="form-control shadow-none border-0 pe-0" type="search" id="search-input" placeholder="Search" defaultValue={""}
                 onChange={onSearchChange} />
                <span className="input-group-text bg-white border-0 p-0">
                  <button className="btn text-muted shadow-none px-3 border-0" type="button"><i className="fa fa-search" /></button>
                </span>
              </div>
              <div className="bg-white shadow-sm rounded p-3 mb-4">
                <h4 className="text-5">Recent Posts</h4>
                <hr className="mx-n3" />
                <div className="side-post">
                  {resentBlogList?.map((rblg, index) => (    
                    <div className="item-post" key={index}>
                      <div className="caption"> <a className="cursor-pointer" onClick={() => gotoBlogDetails(rblg.blogId)}>{rblg.heading}</a>
                        <p className="date-post">{rblg.blogDate}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="bg-white shadow-sm rounded p-3 mb-4">
                <h4 className="text-5">Popular Tags</h4>
                <hr className="mx-n3" />
                <div className="tags">
                  {tags?.map((tg, index) => (
                    <a className={`cursor-pointer ${tag == tg ? 'active-tag' : ''}`} onClick={() => searchByTag(tg)} key={index}>{tg}</a>  
                  ))}
                </div>
              </div>
            </aside>
            <div className="col-lg-8 col-xl-9 tagbl">
              {blogList?.map((blg, index) => (
                <div className="blog-post card shadow-sm border-0 mb-4" key={index}>
                  <div className="row g-0 p-3">
                    <div className="col-md-5">
                      <a className="cursor-pointer" onClick={() => gotoBlogDetails(blg.blogId)}>
                        <div className='blog-image-list' style={{backgroundImage: "url('"+blg.image+"')"}}></div>
                        {/* <img className="card-img d-block" src={rblg.image} alt="" /> */}
                      </a>
                    </div>
                    <div className="col-md-7">
                      <div className="card-body pb-0 pt-3 pt-md-0 ps-0 ps-md-4 pe-0">
                        <h4 className="title-blog text-5">
                          <a className="cursor-pointer" onClick={() => gotoBlogDetails(blg.blogId)}>{blg.heading}</a>
                        </h4>
                        <ul className="meta-blog mb-1">
                          <li><i className="fas fa-calendar-alt" /> {blg.blogDate}</li>
                          <li><a ><i className="fas fa-user" /> Admin</a></li>
                          {/* <li><a ><i className="fas fa-comments" /> 10</a></li> */}
                        </ul>
                        <p dangerouslySetInnerHTML={{ __html: blg?.description }}></p>
                        <a onClick={() => gotoBlogDetails(blg.blogId)} className="btn btn-primary btn-sm cursor-pointer">Read more</a> </div>
                    </div>
                  </div>
                </div>
              ))}
              <div className="text-denter">
                <ReactPaginate breakLabel="..." nextLabel="next >" onPageChange={handlePageClick} pageRangeDisplayed={5}
                  pageCount={pageCount} previousLabel="< previous" pageClassName="page-item" pageLinkClassName="page-link"
                  previousClassName="page-item" previousLinkClassName="page-link" nextClassName="page-item" nextLinkClassName="page-link"
                  breakClassName="page-item" breakLinkClassName="page-link" containerClassName="pagination" activeClassName="active"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}