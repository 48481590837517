import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as constants from '../../Constants/Constants'
import './ServiceDetails.css'

declare var $: any;

export default function ServiceDetails() {

  const { servceName } = useParams();
  const navigate = useNavigate();
  let location = useLocation();
  const [serviceDetails, setServiceDetails] = useState<any>();
  const [limitedOverview, setLimitedOverview] = useState<string>('');
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const { register: register2, handleSubmit: handleSubmit2, watch: watch2, formState: { errors: errors2 } } = useForm();
  const { register: register3, handleSubmit: handleSubmit3, watch: watch3, formState: { errors: errors3 } } = useForm();
  const [formSubmitLoader, setFormSubmitLoader] = useState<boolean>(false);
  const [basicDetails, setBasicDetails] = useState<any>();
  const [activeTab, setActiveTab] = useState<string>('overview');
  const [currentUser, setCurrentUser] = useState<any>();
  const [costDetails, setCostDetails] = useState<any>();

  useEffect(() => {
    getServiceDetails();
    checkServiceScroll();
    getLoggedInDetails();
    constants.loginSubscriber.subscribe((status: boolean) => {
      getLoggedInDetails();
    });
  }, [servceName]);

  const getLoggedInDetails = () => {
    if (localStorage.getItem('currentUser')) {
      setCurrentUser(JSON.parse(localStorage.getItem('currentUser')!));
    }
  }

  const checkServiceScroll = () => {
    $('#scrollable').scroll(function () {
      let mainOffset = $('#scrollable').offset().top;
      if ((mainOffset - $('#overview').offset().top) > -300) {
        setActiveTab('overview');
      }
      if ((parseFloat(mainOffset) - parseFloat($('#benefits').offset().top)) > -300) {
        setActiveTab('benefits');
      }
      if ((mainOffset - $('#requirements').offset().top) > -300) {
        setActiveTab('requirements');
      }
      if ((mainOffset - $('#documentReq').offset().top) > -300) {
        setActiveTab('documentReq');
      }
      if ((mainOffset - $('#registrationProcess').offset().top) > -300) {
        setActiveTab('registrationProcess');
      }
      if ((mainOffset - $('#basePrice').offset().top) > -300) {
        setActiveTab('basePrice');
      }
      if ((mainOffset - $('#whyCompanySearch').offset().top) > -300) {
        setActiveTab('whyCompanySearch');
      }
      if ((mainOffset - $('#freqaskQuestions').offset().top) > -300) {
        setActiveTab('faqs');
      }
    });
  }

  const getServiceDetails = () => {
    fetch(constants.BASE_URL + 'services/details/' + servceName + '.json', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      window.scrollTo(0, 0);
      setLimitedOverview(response.result?.overview.substring(0, 600) + '....');
      setServiceDetails(response.result);
      getCostDetails(response.result?.serviceId);
    });
  }

  const getCostDetails = (serviceId: string) => {
    fetch(constants.BASE_URL + 'services/' + serviceId, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      if (response.status == '200') {
        response.result['basePriceStr'] = response.result?.basePrice?.toLocaleString();
        response.result['minimumPayableStr'] = response.result?.minimumPayable?.toLocaleString();
        setCostDetails(response.result);
      } else {
        constants.showError(response.message);
      }
    });
  }

  const changeActiveTab = (activeTab: string) => {
    setActiveTab(activeTab);
  }

  const prepareUserRegister = (data: any) => {
    setBasicDetails(data);
    $('#remaining-signup-modal').modal('show');
  }

  const prepareUserRegister2 = (data: any) => {
    setBasicDetails(data);
    $('#remaining-signup-modal').modal('show');
  }

  const userRegister = (data: any) => {
    setFormSubmitLoader(true);
    data['email'] = basicDetails.email;
    data['mobile'] = basicDetails.mobile;
    data['address'] = { state: basicDetails.state };
    data['profileType'] = 'COMPANY';
    fetch(constants.BASE_URL + 'user/register', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
      body: JSON.stringify(data)
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      setFormSubmitLoader(false);
      if (response.status == '200') {
        localStorage.setItem('currentUser', JSON.stringify(response.result));
        constants.loginSubscriber.next(true);
        $('#remaining-signup-modal').modal('hide');
        navigate("/checkout/" + serviceDetails.serviceId);
      } else {
        constants.showError(response.message);
      }
    });
  }

  const goToCheckout = (serviceId: string) => {
    navigate("/checkout/" + serviceId);
  }

  return (
    <>
      <div id="content">
        <div className="hero-wrap">
          <div className="hero-mask opacity-7 bg-primary" />
          <div className="hero-bg" />
          <div className="hero-content d-flex">
            <div className="container my-auto py-5">
              <div className="row g-5">
                <div className="col-lg-8">
                  <div className="lead mb-4 text-white ms-3" dangerouslySetInnerHTML={{ __html: limitedOverview }}>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="position-relative px-4 pt-3 pb-4">
                    <div className="hero-mask opacity-8 bg-dark rounded" />
                    <div className="hero-content">
                      <div className="tab-content pt-4" id="myTabContent">
                        {!currentUser &&
                          <form id="signupForm" className="search-input-line" method="post" onSubmit={handleSubmit(prepareUserRegister)}>
                            <div className="row gy-3 gx-4">
                              <div className="col-lg-12 position-relative">
                                <input type="email" autoFocus className="form-control" id="email" placeholder="Email"
                                  {...register("email", { required: true, pattern:/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/})} />
                                {errors.email && <span className='text-danger'>Please enter a valid email id</span>}
                              </div>
                              <div className="col-lg-12 position-relative">
                                <input type="tel" className="form-control" placeholder="Mobile Number" maxLength={13} minLength={10}
                                  {...register("mobile", { required: true, pattern: /^(\+91[\-\s]?)?[0]?(91)?[789]\d{9}$/, minLength: 10, maxLength: 13 })} />
                                {errors.mobile && <span className='text-danger'>Please enter valid mobile number</span>}
                              </div>
                              <div className="col-lg-12 position-relative">
                                <select className="form-control" {...register("state", { required: true })}>
                                  <option value="">Select State</option>
                                  <option>Andhra Pradesh</option>
                                  <option>Arunachal Pradesh</option>
                                  <option>Assam</option>
                                  <option>Bihar</option>
                                  <option>Chhattisgarh</option>
                                  <option>Goa</option>
                                  <option>Gujarat</option>
                                  <option>Haryana</option>
                                  <option>Himachal Pradesh</option>
                                  <option>Jharkhand</option>
                                  <option>Karnataka</option>
                                  <option>Kerala</option>
                                  <option>Madhya Pradesh</option>
                                  <option>Maharashtra</option>
                                  <option>Assam</option>
                                  <option>Meghalaya</option>
                                  <option>Mizoram</option>
                                  <option>Nagaland</option>
                                  <option>Odisha</option>
                                  <option>Punjab</option>
                                  <option>Rajasthan</option>
                                  <option>Sikkim</option>
                                  <option>Tamil Nadu</option>
                                  <option>Telangana</option>
                                  <option>Tripura</option>
                                  <option>Uttar Pradesh</option>
                                  <option>Uttarakhand</option>
                                  <option>West Bengal</option>
                                  <option>Delhi</option>
                                  <option>Andaman and Nicobar</option>
                                  <option>Chandigarh</option>
                                  <option>Dadra and Nagar Haveli and Daman and Diu</option>
                                  <option>Jammu and Kashmir</option>
                                  <option>Ladakh</option>
                                  <option>Lakshadweep</option>
                                  <option>Puducherry</option>
                                </select>
                                {errors.state && <span className='text-danger'>Please select state</span>}
                              </div>
                              <div className="col-12 d-grid mt-4">
                                <button className="btn btn-primary" type="submit">Get Started</button>
                              </div>
                            </div>
                          </form>
                        }
                        {currentUser &&
                          <div className="row gy-3 gx-4">
                            <div className="col-lg-12 position-relative">
                              <h5 className='text-white'>Approximate Cost: ₹ {costDetails?.basePriceStr}</h5>
                            </div>
                            <div className="col-lg-12 position-relative">
                              <h5 className='text-white'>Minimum Payable: ₹ {costDetails?.minimumPayableStr}</h5>
                            </div>
                            <div className="col-12 d-grid mt-4">
                              <button className="btn btn-primary" type="button" onClick={() => goToCheckout(costDetails?.serviceId)}>Enquire</button>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* How does it work
    ============================================= */}
      <section className="section bg-white" style={{ padding: '10px 0' }}>
        <div className="container my-2">
          <h2 className="text-7 text-center">How does it work?</h2>
          <p className="lead text-center mb-3">CompanySearch helps you to complete process in 3 simple steps..</p>
          <div className="row">
            <div className="col-md-4">
              <div className="featured-box style-4">
                <div className="featured-box-icon bg-light-2 text-primary shadow-sm rounded-circle htw-icon"> <i className="fas fa-mobile-alt" /> </div>
                <h4 className="text-4 fw-300 mb-3">Step-1</h4>
                <h3 className="text-5 fw-400 mb-3">Enter your/company details</h3>
                {/* <p className="text-3">Select destination from the list of services then, Enter your/company details.</p> */}
              </div>
            </div>
            <div className="col-md-4">
              <div className="featured-box style-4">
                <div className="featured-box-icon bg-light-2 text-primary shadow-sm rounded-circle htw-icon"> <i className="fas fa-location-arrow" /> </div>
                <h4 className="text-4 fw-300 mb-3">Step-2</h4>
                <h3 className="text-5 fw-400 mb-3">Select Service</h3>
                {/* <p className="text-3">Review all services  available online and choose a service as per your requirement.</p> */}
              </div>
            </div>
            <div className="col-md-4">
              <div className="featured-box style-4">
                <div className="featured-box-icon bg-light-2 text-primary shadow-sm rounded-circle htw-icon"> <i className="fas fa-money-check-alt" /> </div>
                <h4 className="text-4 fw-300 mb-3">Step-3</h4>
                <h3 className="text-5 fw-400 mb-3">Confirm your payment</h3>
                {/* <p className="text-3">Make a payment using our secure online payment process.</p> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* How does it work end */}
      <div id="content">
        <div className="container-fluid my-5" id="tab1">
          <div className="row">
            <div className='accordion mobile-details' id='accordionExample'>
              <div className='accordion-item'>
                <p className='accordion-header' id='headingOne'>
                  <button className='accordion-button collapsed' type='button' data-bs-toggle='collapse' 
                    data-bs-target='#collapseOne' aria-expanded='true' aria-controls='collapseOne'>Overview</button>
                </p>
                <div id='collapseOne' className='accordion-collapse collapse' aria-labelledby='headingOne' data-bs-parent='#accordionExample'>
                  <div className='accordion-body' dangerouslySetInnerHTML={{ __html: serviceDetails?.overview }}></div>
                </div>
              </div>
              <div className='accordion-item'>
                <p className='accordion-header' id='headingTwo'>
                  <button className='accordion-button collapsed' type='button' data-bs-toggle='collapse' 
                    data-bs-target='#collapseTwo' aria-expanded='false' aria-controls='collapseTwo'>Benefits</button>
                </p>
                <div id='collapseTwo' className='accordion-collapse collapse' aria-labelledby='headingTwo' data-bs-parent='#accordionExample'>
                  <div className='accordion-body' dangerouslySetInnerHTML={{ __html: serviceDetails?.benefits }}></div>
                </div>
              </div>
              <div className='accordion-item'>
                <p className='accordion-header' id='headingThree'>
                  <button className='accordion-button collapsed' type='button' data-bs-toggle='collapse' 
                    data-bs-target='#collapseThree' aria-expanded='false' aria-controls='collapseThree'>Requirements</button>
                </p>
                <div id='collapseThree' className='accordion-collapse collapse' aria-labelledby='headingThree' data-bs-parent='#accordionExample'>
                  <div className='accordion-body' dangerouslySetInnerHTML={{ __html: serviceDetails?.requirements }}></div>
                </div>
              </div>
              <div className='accordion-item'>
                <p className='accordion-header' id='headingFour'>
                  <button className='accordion-button collapsed' type='button' data-bs-toggle='collapse' 
                    data-bs-target='#collapseFour' aria-expanded='false' aria-controls='collapseFour'>Documents Required</button>
                </p>
                <div id='collapseFour' className='accordion-collapse collapse' aria-labelledby='headingFour' data-bs-parent='#accordionExample'>
                  <div className='accordion-body' dangerouslySetInnerHTML={{ __html: serviceDetails?.documentReq }}></div>
                </div>
              </div>
              <div className='accordion-item'>
                <p className='accordion-header' id='headingFive'>
                  <button className='accordion-button collapsed' type='button' data-bs-toggle='collapse' 
                    data-bs-target='#collapseFive' aria-expanded='false' aria-controls='collapseFive'>Registration Process</button>
                </p>
                <div id='collapseFive' className='accordion-collapse collapse' aria-labelledby='headingFive' data-bs-parent='#accordionExample'>
                  <div className='accordion-body' dangerouslySetInnerHTML={{ __html: serviceDetails?.registrationProcess }}></div>
                </div>
              </div>
              <div className='accordion-item'>
                <p className='accordion-header' id='headingSix'>
                  <button className='accordion-button collapsed' type='button' data-bs-toggle='collapse' 
                    data-bs-target='#collapseSix' aria-expanded='false' aria-controls='collapseSix'>Base Price</button>
                </p>
                <div id='collapseSix' className='accordion-collapse collapse' aria-labelledby='headingSix' data-bs-parent='#accordionExample'>
                  <div className='accordion-body' dangerouslySetInnerHTML={{ __html: serviceDetails?.basePrice }}></div>
                </div>
              </div>
              <div className='accordion-item'>
                <p className='accordion-header' id='headingSeven'>
                  <button className='accordion-button collapsed' type='button' data-bs-toggle='collapse' 
                    data-bs-target='#collapseSeven' aria-expanded='false' aria-controls='collapseSeven'>Why CompanySearch?</button>
                </p>
                <div id='collapseSeven' className='accordion-collapse collapse' aria-labelledby='headingSeven' data-bs-parent='#accordionExample'>
                  <div className='accordion-body' dangerouslySetInnerHTML={{ __html: serviceDetails?.whyCompanySearch }}></div>
                </div>
              </div>
              <div className='accordion-item'>
                <p className='accordion-header' id='headingEight'>
                  <button className='accordion-button collapsed' type='button' data-bs-toggle='collapse' 
                    data-bs-target='#collapseEight' aria-expanded='false' aria-controls='collapseEight'>FAQ</button>
                </p>
                <div id='collapseEight' className='accordion-collapse collapse' aria-labelledby='headingEight' data-bs-parent='#accordionExample'>
                  <div className='accordion-body' dangerouslySetInnerHTML={{ __html: serviceDetails?.freqaskQuestions }}></div>
                </div>
              </div>
            </div>
            <aside className="col-lg-2 col-xl-2 desktop-details">
              <div className="bg-white shadow-sm rounded p-3 mb-4">
                <ul className="list-item text-4sm">
                  <li className={activeTab == 'overview' ? 'mb-1 item-active' : 'mb-1'} onClick={() => changeActiveTab('overview')}><a href='#overview'>Overview</a></li>
                  <li className={activeTab == 'benefits' ? 'mb-1 item-active' : 'mb-1'} onClick={() => changeActiveTab('benefits')}><a href='#benefits'>Benefits</a></li>
                  <li className={activeTab == 'requirements' ? 'mb-1 item-active' : 'mb-1'} onClick={() => changeActiveTab('requirements')}><a href='#requirements'>Requirements</a></li>
                  <li className={activeTab == 'documentReq' ? 'mb-1 item-active' : 'mb-1'} onClick={() => changeActiveTab('documentReq')}><a href='#documentReq'>Documents Required</a></li>
                  <li className={activeTab == 'registrationProcess' ? 'mb-1 item-active' : 'mb-1'} onClick={() => changeActiveTab('registrationProcess')}><a href='#registrationProcess'>Registration Process</a></li>
                  <li className={activeTab == 'basePrice' ? 'mb-1 item-active' : 'mb-1'} onClick={() => changeActiveTab('basePrice')}><a href='#basePrice'>Base Price</a></li>
                  <li className={activeTab == 'whyCompanySearch' ? 'mb-1 item-active' : 'mb-1'} onClick={() => changeActiveTab('whyCompanySearch')}><a href='#whyCompanySearch'>Why CompanySearch?</a></li>
                  <li className={activeTab == 'faqs' ? 'mb-1 item-active' : 'mb-1'} onClick={() => changeActiveTab('faqs')}><a href='#freqaskQuestions'>FAQ</a></li>
                </ul>
              </div>
            </aside>
            <div className="col-lg-8 col-xl-8 scrollDiv desktop-details" id="scrollable" >
              <div className="blog-post card shadow-sm border-0 mb-4 p-4 ">
                <h1 className="title-blog psd text-7 fw-500">{serviceDetails?.serviceName}</h1>
                <div className="card-body  px-0 pb-0 text-4">
                  <div id='overview' className="psd1" dangerouslySetInnerHTML={{ __html: serviceDetails?.overview }}>
                  </div>
                  <div id='benefits' className="psd1" dangerouslySetInnerHTML={{ __html: serviceDetails?.benefits }}>
                  </div>
                  <div id='requirements' className="psd1" dangerouslySetInnerHTML={{ __html: serviceDetails?.requirements }}>
                  </div>
                  <div id='documentReq' className="psd1" dangerouslySetInnerHTML={{ __html: serviceDetails?.documentReq }}>
                  </div>
                  <div id='registrationProcess' className="psd1" dangerouslySetInnerHTML={{ __html: serviceDetails?.registrationProcess }}>
                  </div>
                  <div id='basePrice' className="psd1" dangerouslySetInnerHTML={{ __html: serviceDetails?.basePrice }}>
                  </div>
                  <div id='whyCompanySearch' className="psd1" dangerouslySetInnerHTML={{ __html: serviceDetails?.whyCompanySearch }}>
                  </div>
                  <div id='freqaskQuestions' className="psd1" dangerouslySetInnerHTML={{ __html: serviceDetails?.freqaskQuestions }}>
                  </div>
                </div>
                <hr className="mb-3" />
                <div className="row mb-3">
                  <div className="col-lg-6 col-xl-7">
                  </div>
                </div>
              </div>
            </div>

            <div className='col-lg-2 col-xl-2'>
              <div className="position-relative px-1 pt-0 pb-2 bg-dark">
                <div className="hero-mask opacity-8 bg-primary rounded" />
                <div className="hero-content">
                  <div className="tab-content pt-4 m-2" id="myTabContent">
                    {!currentUser &&
                      <form id="bookingFlight" className="search-input-line" method="post" onSubmit={handleSubmit3(prepareUserRegister2)}>
                        <div className="row gy-3 gx-4">
                          <div className="col-lg-12 position-relative">
                            <input type="email"   className="form-control" id="email2" placeholder="Your Email"
                              {...register3("email", { required: true, pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/})} />
                            {errors3.email && <span className='text-danger'>Please enter a valid email id</span>}
                          </div>
                          <div className="col-lg-12 position-relative">
                            <input type="text" className="form-control" id="mobile2" placeholder="Your Mobile Number"
                              {...register3("mobile", { required: true, pattern:/^(\+91[\-\s]?)?[0]?(91)?[789]\d{9}$/, minLength: 10, maxLength: 13 })} />
                            {errors3.mobile && <span className='text-danger'>Please enter a valid mobile number</span>}
                          </div>
                          <div className="col-lg-12 position-relative">
                            <select className="form-control" {...register3("state", { required: true })}>
                              <option value="">Select State</option>
                              <option>Andhra Pradesh</option>
                              <option>Arunachal Pradesh</option>
                              <option>Assam</option>
                              <option>Bihar</option>
                              <option>Chhattisgarh</option>
                              <option>Goa</option>
                              <option>Gujarat</option>
                              <option>Haryana</option>
                              <option>Himachal Pradesh</option>
                              <option>Jharkhand</option>
                              <option>Karnataka</option>
                              <option>Kerala</option>
                              <option>Madhya Pradesh</option>
                              <option>Maharashtra</option>
                              <option>Assam</option>
                              <option>Meghalaya</option>
                              <option>Mizoram</option>
                              <option>Nagaland</option>
                              <option>Odisha</option>
                              <option>Punjab</option>
                              <option>Rajasthan</option>
                              <option>Sikkim</option>
                              <option>Tamil Nadu</option>
                              <option>Telangana</option>
                              <option>Tripura</option>
                              <option>Uttar Pradesh</option>
                              <option>Uttarakhand</option>
                              <option>West Bengal</option>
                              <option>Delhi</option>
                              <option>Andaman and Nicobar</option>
                              <option>Chandigarh</option>
                              <option>Dadra and Nagar Haveli and Daman and Diu</option>
                              <option>Jammu and Kashmir</option>
                              <option>Ladakh</option>
                              <option>Lakshadweep</option>
                              <option>Puducherry</option>
                            </select>
                            {errors3.state && <span className='text-danger'>Please select state</span>}
                          </div>
                          <div className="col-12 d-grid mt-4">
                            <button className="btn btn-primary" type="submit">Get Started</button>
                          </div>
                        </div>
                      </form>
                    }
                    {currentUser &&
                      <div className="row gy-3 gx-4">
                        <div className="col-lg-12 position-relative">
                          <h6 className='text-white'>Approximate Cost: ₹ {costDetails?.basePriceStr}</h6>
                        </div>
                        <br/>
                        <div className="col-lg-12 position-relative">
                          <h6 className='text-white'>Minimum Payable: ₹ {costDetails?.minimumPayableStr}</h6>
                        </div>
                        <br/>
                        <div className="col-12 d-grid mt-4">
                          <button className="btn btn-primary" type="button" onClick={() => goToCheckout(costDetails?.serviceId)}>Enquire</button>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="remaining-signup-modal" className="modal fade" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content border-0">
            <div className="modal-header">
              <h5 className="modal-title">Complete your business details</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body py-4 px-0">
              <div className="row">
                <div className="col-11 col-md-10 mx-auto">
                  <form id="signupForm" method="post" onSubmit={handleSubmit2(userRegister)}>
                    <div className="mb-3">
                      <label className="form-label" htmlFor="fullName">Company Name<span className='red'>*</span></label>
                      <input type="text"   className="form-control" id="fullName" placeholder="Company Name"
                        {...register2("fullName", { required: true })} />
                      {errors2.fullName && <span className='text-danger'>Name is required</span>}
                    </div>
                    <div className="mb-3">
                      <label className="form-label" htmlFor="businessType">Business Type<span className='red'>*</span></label>
                      <select className="form-control" id="businessType" {...register2("businessType", { required: true })}>
                        <option value="">Select</option>
                        <option value="Agri & Agritech">Agri & Agritech</option>
                        <option value="Automobiles">Automobiles</option>
                        <option value="Clothing & Apparel">Clothing & Apparel</option>
                        <option value="eCommerce">eCommerce</option>
                        <option value="Education">Education</option>
                        <option value="Finance & Fintech">Finance & Fintech</option>
                        <option value="Food & Beverages">Food & Beverages</option>
                        <option value="Healthcare & Healthtech">Healthcare & Healthtech</option>
                        <option value="Hospitality, Travel & Tourism">Hospitality, Travel & Tourism</option>
                        <option value="Logistics & Transport">Logistics & Transport</option>
                        <option value="Advertising & Marketing">Advertising & Marketing</option>
                        <option value="Telecom, Media & Entertainment">Telecom, Media & Entertainment</option>
                        <option value="Pharma">Pharma</option>
                        <option value="Real Estate">Real Estate</option>
                        <option value="Energy">Energy</option>
                        <option value="Labor & Employment">Labor & Employment</option>
                        <option value="Software & ITeS">Software & ITeS</option>
                        <option value="Trade & Retail">Trade & Retail</option>
                        <option value="Manufacturing">Manufacturing</option>
                        <option value="Other Services">Other Services</option>
                      </select>
                      {errors2.businessType && <span className='text-danger'>Business type is required</span>}
                    </div>
                    <div className="mb-3">
                      <label className="form-label" htmlFor="ageOfBusiness">Age of Business (In Years)<span className='red'>*</span></label>
                      <select className="form-control" id="ageOfBusiness" {...register2("ageOfBusiness", { required: true })}>
                        <option value="">Select</option>
                        <option value="Less than 1 year">Less than 1 year</option>
                        <option value="1-2 years">1-2 years</option>
                        <option value="2-5 years">2-5 years</option>
                        <option value="5+ years">5+ years</option>
                      </select>
                      {errors2.ageOfBusiness && <span className='text-danger'>Age of Business is required</span>}
                    </div>
                    <div className="mb-3">
                      <label className="form-label" htmlFor="totalEmployee">Total Employee<span className='red'>*</span></label>
                      <select className="form-control" id="totalEmployee" {...register2("totalEmployee", { required: true })}>
                        <option value="">Select</option>
                        <option value="Less than 20">Less than 20</option>
                        <option value="21-100">21-100</option>
                        <option value="101-500">101-500</option>
                        <option value="501+">501+</option>
                      </select>
                      {errors2.totalEmployee && <span className='text-danger'>Please enter total employees</span>}
                    </div>
                    <div className="mb-3">
                      <label className="form-label" htmlFor="orgStatus">Business Status<span className='red'>*</span></label>
                      <select className="form-control" id="orgStatus" {...register2("orgStatus", { required: true })}>
                        <option value="">Select</option>
                        <option value="Yet to start">Yet to start</option>
                        <option value="Have been for a while">Have been for a while</option>
                      </select>
                      {errors2.orgStatus && <span className='text-danger'>Business Status is required</span>}
                    </div>
                    <div className="d-grid my-4">
                      <button className="btn btn-primary" type="submit" disabled={formSubmitLoader}>
                        {formSubmitLoader && <i className="fas fa-circle-notch fa-spin me-2"></i>}
                        Submit
                      </button>
                    </div>
                  </form>
                  <p className="text-2 text-center mb-0">Already have an account? <a className="btn-link" href="" data-bs-toggle="modal" data-bs-target="#login-modal" data-bs-dismiss="modal">Log In</a></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
