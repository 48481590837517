import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as constants from '../../Constants/Constants';
import "./SignUp.css";
declare var $: any;

export default function SignUp() {

  const { register, handleSubmit, watch, formState: { errors },reset } = useForm();
  const [formSubmitLoader, setFormSubmitLoader] = useState<boolean>(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const[eye,setEye] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
    setEye(eye ? false:true);
  };

  const userRegister = (data: any) => {
   
    setFormSubmitLoader(true);
    fetch(constants.BASE_URL + 'user/register', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
      body: JSON.stringify(data)
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      setFormSubmitLoader(false);
      if (response.status == '200') {
        reset()
        constants.showSuccess('Registered Successfully');
        localStorage.setItem('currentUser', JSON.stringify(response.result));
        constants.loginSubscriber.next(true);
      
        $('#signup-modal').modal('hide');
      } else {
        constants.showError(response.message);
      }
    });
  }

  return (
    <div id="signup-modal" className="modal fade" role="dialog" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content border-0">
          <div className="modal-body py-4 px-0">
            <button type="button" className="btn-close position-absolute top-0 end-0 mt-1 me-1 mt-sm-2 me-sm-2" data-bs-dismiss="modal" aria-label="Close"></button>
            <div className="row">
              <div className="col-11 col-md-10 mx-auto">
                <ul className="nav nav-tabs nav-justified mb-4" role="tablist">
                  <li className="nav-item"> <a className="nav-link text-5 lh-lg" href="" data-bs-toggle="modal" data-bs-target="#login-modal" data-bs-dismiss="modal">Log In</a> </li>
                  <li className="nav-item"> <a className="nav-link text-5 lh-lg active">Sign Up</a> </li>
                </ul>
                <p className="text-4 fw-300 text-muted text-center mb-4">Looks like you're new here!</p>
                <form id="signupForm" method="post" onSubmit={handleSubmit(userRegister)}>
                  <div className="mb-3">
                    <label className="form-label me-4" htmlFor="profileType">Profile Type:</label>
                    <div className="form-check form-check-inline">
                      <input id="individual" value="INDIVIDUAL" className="form-check-input" type="radio"
                        {...register("profileType", { required: true })} />
                      <label className="form-check-label" htmlFor="individual">Individual</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input id="company" value="COMPANY" className="form-check-input" type="radio"
                        {...register("profileType", { required: true })} />
                      <label className="form-check-label" htmlFor="company">Company</label>
                    </div>
                    {errors.profileType && <><br/><span className='text-danger'>Please select profile type</span></>}
                  </div>
                  <div className="mb-3">
                    <input type="text" className="form-control" id="fullName" placeholder="Your/Company Full Name"
                      {...register("fullName", { required: true , pattern:/[a-zA-Z][a-zA-Z ]{3,}/ , maxLength:45})} />
                    {errors.fullName && <span className='text-danger'>Please enter full name</span>}
                  </div>
                  <div className="mb-3">
                    <input type="tel" className="form-control" id="mobile" placeholder="Mobile Number" minLength={10} maxLength={13}
                      {...register("mobile", { required: true , pattern:/^(\+91[\-\s]?)?[0]?(91)?[789]\d{9}$/, minLength: 10, maxLength: 13})} />
                    {errors.mobile && <span className='text-danger'>Please enter  valid mobile number</span>}
                  </div>
                  <div className="mb-3">
                    <input type="email" className="form-control" id="emailAddress" placeholder="Email Id"
                      {...register("email", { required: true, pattern:/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/})}/>
                    {errors.email && <span className='text-danger'>Please enter valid email id</span>}
                  </div>
                  <div className="mb-3 ">
                    {/* password starts */}
                    
                    {/* password ends */}
                    <div className='pass-wrapper'>
                    <input type={passwordShown ? "text" : "password"} className="form-control" id="loginPassword" placeholder="Password" 
                      {...register("password", { required: true, pattern:/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{5,}$/ , maxLength:40})}/>
                      <span><i onClick={togglePasswordVisiblity} className={`fa ${eye ? "fa-eye-slash" : "fa-eye" } passwordhide`} aria-hidden="true"></i></span>

                    </div>
                                          {errors.password && <span className='text-danger'>Password must contain minimum five  characters, at least one uppercase letter, one lowercase letter and one number.</span>}
                  </div>
                  <div className="form-check text-3 my-4">
                      <input id="agree" name="agree" className="form-check-input" type="checkbox"  required />
                      <label className="form-check-label text-2">I agree to the <a href="/termsofuse">Terms</a> and <a href="/privacypolicy">Privacy Policy</a>.</label>
                  </div>
                  <div className="d-grid my-4">
                    <button className="btn btn-primary" type="submit" disabled={formSubmitLoader}>
                      {formSubmitLoader && <i className="fas fa-circle-notch fa-spin me-2"></i>}
                      Sign Up
                    </button>
                  </div>
                </form>
                <p className="text-2 text-center mb-0">Already have an account? <a className="btn-link" href="" data-bs-toggle="modal" data-bs-target="#login-modal" data-bs-dismiss="modal">Log In</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
