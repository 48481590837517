import React from 'react'
import { NavLink } from 'react-router-dom'

export default function TermsOfUse
() {
  return (
    <div>
<section className="page-header page-header-dark bg-secondary">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-md-8">
          <h1>Terms Of Use</h1>
        </div>
        <div className="col-md-4">
          <ul className="breadcrumb justify-content-start justify-content-md-end mb-0">
            <li><NavLink to="/">Home</NavLink></li>
            <li className="active">Terms Of Use</li>
          </ul>
        </div>
      </div>
    </div>
  </section> 
  <div id="content">
        <div className="container">
          <div className="bg-white shadow-md rounded p-4 my-4">
            <h2 className="text-6">Terms Of Use</h2>
            <h6><b>Agreement between user and CompanySearch</b></h6>
            <p>These Terms and Conditions (the “Agreement” or “Terms and Conditions”) are an agreement between you and CompanySearch incorporated under the provisions of Companies Act, 2013 having registered office A202, Devgiri Apartment, Ganeshmala, Sinhagad Road, Pune 411030, ("CompanySearch") concerning the terms under which CompanySearch offers you the information and services contained in or available through   companysearch.co.in is made up of various web pages operated by CompanySearch. Services are offered to you conditioned on your acceptance of the terms, conditions, and notices contained in this electronic document. If you do not agree with terms and conditions, or if you are not of age 18 or older, do not use companysearch.co.in.</p>
           <p>“Using” companysearch.co.in means doing actions like clicking on links, entering data, creating documents, uploading information, enrolling, or browsing. Your using companysearch.co.in constitutes your agreement to all the terms, conditions, and notices contained in this website.</p>
           <p>companysearch.co.in gives general information about the tax, audit, compliances and registration of companies; but it does not provide any legal advice. CompanySearch is not a law firm: Neither the employees of CompanySearch nor the participating professionals (Company secretaries, Chartered Accountants or lawyers) are representing you or acting as your professional advisers. Your use of companysearch.co.in does not make lawyer-client relation between you and CompanySearch or any of the employees or professionals you may encounter through it. In any legal matter you undertake through companysearch.co.in’s legal document service, you are representing yourself.</p>
           <p>companysearch.co.in should not be used as a substitute for the advice of a professional. If any type of relationship is inadvertently formed between you and a CompanySearch employee or professional participating, that relationship excludes companysearch.co.in as a participant.</p><p>The information given by CompanySearch on this website does not consider legal advice. It should therefore not be relied upon as legal advice. Because the law changes continuously, CompanySearch cannot guarantee that all the information on companysearch.co.in is current or correct. The law differs from jurisdiction to jurisdiction, but it also is subject to interpretation by different courts. No general information or legal tool like the ones companysearch.co.in provides can fit every circumstance. CompanySearch is not responsible for any loss, injury, claim, liability, or damage related to your use either of companysearch.co.in or of any site linked to companysearch.co.in. Although this is the case for all uses of these sites, we specifically mention here two of the most likely causes: (1) incidents caused by the site being down; and (2) incidents caused by errors or omissions in the content of our site or any other linked sites. In short, your Use of companysearch.co.in is at your own risk</p>
          <ol>
            <li><h6>Accountable Use and Conduct</h6><p>By going through our website and getting to the data, resources, organizations items, and devices we accommodate you, either directly or by implication (in the upcoming alluded to as ‘Assets’), you agree to use these skills just for the reasons planned as permissible by (a) the terms of this User Agreement, and (b) suitable laws, rules and for the most part recognized online practices or rules. </p><p>Please understand following:</p>
              <ol>
                <li>If you practice any of the services which are offered by CompanySearch through any means other than the means we provide is severely prohibited. You mainly agree not to get to (or endeavour to get to) any of our Resources through any robotized, unfair means</li>
                <li>Contributing in any program that disappointments or interferes with our Resources, including the servers and/or systems, to which our Resources are found or joined, is completely prohibited.</li>
                <li>To go through our skills and services, sometimes you need to make available the information which has been inquired (like Identity Proof, phone number, email, contact details, etc.). This info is mandatory for any registration process or as part of your capacity to use the Resources. Client is requested to provide the right and correct information.</li>
                <li>Trying to duplicate, copy, imitate, proposal, conversation, or exchange our assets is entirely precluded.</li>
                <li>You are in responsible for keeping up the classification of any login data associated with any record you use to get to our Resources. You are responsible for all exercises that happen under your records.</li>
                <li>You are exceptionally reliable of any results, misfortunes, or troubles that we might precisely or in an indirect way carry or suffer because of any unapproved workouts led by you, as explained above, and may obtain illegal or mutual risk.
                  <ol>
                    <li>We may give different open, focused devices on our website, for example, online journal comments, blog entries, public visit, discussions, communication sheets, news groups, item assessments and reviews, different online networking administrations, and so on. You understand that by we don’t pre-screen or screen the material published by customers of these various specific devices, which infers that on the off chance that you use these methods to present any kind of equipment to our website; formerly it is your honest response to use these tools mindfully and ethically. By posting data or usually using any particular open tools as said, you agree that you won’t handover, post, proposal, or generally disperse any material:</li>
                    <li>Infringes on any trademark, patent, prized formula, copyright, or another exclusive right of any meeting;</li>
                    <li>Is illegal, debilitating, offensive, harmful, tapping, degrading, terrifying, false, beguiling, obtrusive, racist, or covers any kind of indicative, inappropriate, or explicit language;</li>
                    <li>Impersonate any individual or material, including any www.swaritadvisors.com employees or negotiators.</li>
                    <li>Covers any kind of unapproved or impulsive circulation;</li><p>We have the relaxed sole circumspection to relocate any content that, we feel in our decision does not consent to this User Agreement, alongside any material that we believe is usually unfriendly, critical, unpleasant, off base, or abuses any third gathering copyrights or trademarks. We are not in charge of any postponement or dissatisfaction in vacating such substance. On the off chance that you post content that we evacuate, you thusly agree to such evacuation and agree to waive any circumstance contrary to us.</p>
                  </ol>
                </li>
                <li>You consensus to repay and hold safe swaritadvisors.com a computerized property of CompanySearch and its caretaker group and associates, and their executives, officers, directors, representatives, contributors, authorities, and licensors, after and in contradiction of all disasters, prices, harms and expenditures, including practical attorneys’ charges, coming about because of any violation of this User Agreement or the incapability to satisfy any promises classifying with your record attained by you or whatever other individual using your record. We maintain whatsoever authority wanted to assume control over the select protection of any case for which we are fit for settlement under this User Agreement. In such case, you must provide us with so much collaboration as is sensibly asked for by us.</li>
                <li>We do not expect any obligation for any material published by you or whatsoever other third gathering customers of our site. On the other hand, any material published by you using any open expert gadgets on our site, gave that it doesn’t disrespect or infringe on any third gathering copyrights or trademarks, turns into the assets of www.swaritadvisors.com, and all belongings considered, gives us a ceaseless, irreversible, around the world, eminence free, selective license to reappearance, change, alter, decipher, allocate, easily show and/or circulate as we see fit. This just mentions and applies to material posted by means of open particular devices as depicted, and does not refer to data that is certain as a main feature of the organization procedure, critical keeping in attention the end goal to use our Properties. All data gave as a feature of our deployment process is available by our Privacy Policy.</li>
              </ol>
            </li>
            <li><h6>Task Completion Timeline</h6><p>Timelines declared for task achievement are estimations and must not be taken as an agreed promise from CompanySearch. Task completion is dependent on numerous externalities such as government department and third-party merchants. CompanySearch will have limited guarantees in such cases. CompanySearch won’t be at risk for any immediate, backhanded, coincidental, substantial or model disaster or troubles which may be brought about by you as an importance of using our Resources, or as an aftereffect of any developments, information misfortune or violation, withdrawal, loss of access, or downtime to the full degree that suitable limits of obligation laws apply.</p></li>
            <li><h6>Restriction of Service contract</h6><p>By using our site, you understand and agree that all Resources we give are “as may be” and “as accessible”. This implies we don’t speak to or warrant to you that:</p>
              <ol>
                <li>The use of our Resources will address your problems or requirements.</li>
                <li>The use of our Resources will be constant, suitable, safe or free from errors</li>
                <li>The data got by using our Resources will be precise or solid, and</li>
                <li>Any limitations in the process or usefulness of any Resources we give will be fixed or edited</li>
                <li>Any material copied or usually got through the use of our Resources is done at your own precise attentiveness and hazard, and that you are unusually in custody of any damage to your PC or different devices for any loss of information that may come about because of the download of such material.</li>
                <li>No data or exhortation whether transferred, advised, verbal or composed, attained by you from www.companysearch.co.in or from any Resources we give no guarantee, ensure, or states of any sort, except for those explicitly laid out in this User Agreement.</li>
              </ol>
            </li>
            <li><h6>Trademarks /Copyrights</h6><p>All substance and materials available on www.companysearch.co.in, including however not constrained to content, design, site name, code, pictures, and logos are the licensed innovation of CompanySearch and are protected by suitable copyright and trademark law. Any inappropriate use, counting yet not constrained to the generation, assumption, exhibition or transmission of any material on this site is completely prohibited unless particularly permitted by CompanySearch.</p></li>
            <li><h6>Liability Impediment</h6><p>In combination with the Restriction of Assurances as explained above, you openly understand and agree that any case against us must be constrained to the sum you paid, if any, for the use of items and/or administrations. www.companysearch.co.in won’t be at risk for any immediate, backhanded, accidental, considerable or model misfortune or harms which may be brought about by you as a significance of using our Resources, or as an aftereffect of any developments, info misfortune or violation, termination, damage of access, or downtime to the full degree that suitable limits of obligation laws apply. </p></li>
            <li><h6>End of Use</h6><p>You concur that we might, at our sole circumspection, suspend or terminate your access to all or some percentage of our site and Resources with or without announcement and for any purpose, including, without limitations, rupture of this User Agreement. Any suspected unlawful, false or harmful action may be the explanation for ending your association and may indicate to proper law requirement authorities. Upon suspension or end, your right to use the Resources we give will quickly stop, and we keep all authority to uproot or remove any information that you may have on a document with us, including any record or login data.</p></li>
            <li><h6>Refund and Cancellation</h6><p>Termination of the request is impractical once the payment has been made. No discounts will be given with the exclusion of in the occasion of termination or non-execution of administration by CompanySearch.</p></li>
            <li><h6>Overseeing Law</h6><p>CompanySearch control this website from our offices located in the state of Maharashtra, India. It can be gotten to by most states around the globe. As every state has laws that may vary from those of Maharashtra, India by getting to our website, you agree that the statutes and laws of Maharashtra, India without admiration to the contention of laws and the United Nations Convention on the International Sales of Goods, will apply to all materials classifying with the use of this site and the buy of any items or administrations through this website.</p><p>Also, any action to implement this User Agreement might become the government or state courts located in Maharashtra, India. You, therefore, agree to individual location by such courts and waive any jurisdictional, venue, or poorly designed meeting protests to such courts.</p></li>
            <li><h6>Contact Information</h6><p>On the off chance that you have any questions or comment about these our Terms of Service as laid out above, you can get in touch with us at.</p></li>
            <li><h6>Ensure</h6><p>Unless otherwise expressed, www.companysearch.co.in particularly disclaims all warranties and circumstances of any kind, whether express or implied, including, but not limited to the implied warranties and conditions of merchant ability, fitness for a particular purpose and non-infringement. </p></li>
            
          </ol>
          </div>
        </div>
      </div>

    </div>
  )
}
