import { Routes, Route } from "react-router-dom";

import Category from "../Component/Admin/Category/Category";
import Service from "../Component/Admin/Service/Service";

import AboutUs from "../Component/AboutUS/AboutUs";

import ChangePassword from "../Component/ChangePassword/ChangePassword";
import ContactUs from "../Component/ContactUs/ContactUs";
import Dashboard from "../Component/Dashboard/Dashboard";
import Home from '../Component/Home/Home';
import MyPayments from "../Component/MyPayments/MyPayments";
import MyServices from "../Component/MyServices/MyServices";
import Profile from "../Component/Profile/Profile";
import ServiceDetails from "../Component/ServiceDetails/ServiceDetails";
import AddressDetails from "../Component/AddressDetails/AddressDetails";
import ServiceRequests from "../Component/Admin/ServiceRequests/ServiceRequests";
import Checkout from "../Component/Checkout/Checkout";
import PaymentSuccess from "../Component/Payment/PaymentSuccess";
import PaymentFail from "../Component/Payment/PaymentFail";
import TermsOfUse from "../Component/TermsOfUse/TermsOfUse";
import PrivacyPolicy from "../Component/PrivacyPolicy/PrivacyPolicy";
import Enquiries from "../Component/Admin/Enquiries/Enquiries";
import Blogs from "../Component/Blogs/Blogs";
import BlogManagement from "../Component/Admin/BlogManagement/BlogManagement";
import BlogDetails from "../Component/BlogDetails/BlogDetails";
import RefundPolicy from "../Component/Refund Policy/RefundPolicy";
import ComplianceManagement from "../Component/Admin/ComplianceManagement/ComplianceManagement";
import ComplianceCalendar from "../Component/Admin/ComplianceCalendar/ComplianceCalendar";
import MyInterest from "../Component/Admin/MyInterest/MyInterest";
import CustomerInterest from "../Component/Admin/CustomerInterest/CustomerInterest";
import EditService from "../Component/Admin/EditService/EditService";

export default function Routing() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/address-details" element={<AddressDetails />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/my-services" element={<MyServices />} />
        <Route path="/my-payments" element={<MyPayments />} />
        <Route path="/dashboard" element={<Dashboard/>} />

        <Route path="/category" element={<Category />} />
        <Route path="/service" element={<Service />} />
        <Route path="/service-requests" element={<ServiceRequests />} />
        <Route path="/service/edit/:serviceName" element={<EditService />} />

        <Route path="/aboutus" element={<AboutUs/>} />
        <Route path="/contactus" element={<ContactUs/>} />
        <Route path="/checkout/:serviceId" element={<Checkout/>} />
        <Route path="/payment-successful" element={<PaymentSuccess />} />
        <Route path="/payment-failed" element={<PaymentFail />} />
        <Route path="/termsofuse" element={<TermsOfUse/>} />
        <Route path="/privacypolicy" element={<PrivacyPolicy/>} />
        <Route path="/enquiries" element={<Enquiries/>} />
        <Route path="/:servceName" element={<ServiceDetails />} />
        <Route path="/blogs" element={<Blogs/>} />
        <Route path="/blog-management" element={<BlogManagement/>} />
        <Route path="/blog/:blogId" element={<BlogDetails/>} />
        <Route path="/refundpolicy" element={<RefundPolicy/>} />
        <Route path="/compliance-management" element={<ComplianceManagement/>} />
        <Route path="/compliance-calendar" element={<ComplianceCalendar/>} />
        <Route path="/my-interests" element={<MyInterest/>} />
        <Route path="/customer-interests" element={<CustomerInterest/>} />
      </Routes>
    </>
  )
}
