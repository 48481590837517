import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import ProfileSidebar from '../../ProfileSidebar/ProfileSidebar'
import * as constants from '../../../Constants/Constants'
import { useForm } from 'react-hook-form';
import ReactPaginate from 'react-paginate';

declare var $: any;

export default function BlogManagement () {
  
  const { register, handleSubmit, setValue, formState: { errors }, reset } = useForm();
  const [formSubmitLoader, setFormSubmitLoader] = useState<boolean>(false);
  const [blogList, setBlogList] = useState<any[]>();
  const [blog, setBlog] = useState<any>();

  // VARIABLES FOR PAGINATION
  const [pageCount, setPageCount] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const [itemsPerPage , setItemsPerPage ] = useState(10);
  const [image, setImage] = useState<string | null>();
  const [encodedImage, setEncodedImage] = useState<string | null>();

  useEffect(() => {
    getBlogList();
  }, [pageNum]);

  const getBlogList = () => {
    fetch(constants.BASE_URL + 'blog/list?pageNum='+pageNum+'&numPerPage=' + itemsPerPage, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      if (response.status == '200') {
        setBlogList(response.result);
        setPageCount(Math.ceil(response.listCount / itemsPerPage));
      }
    });
  }

  const handlePageClick = (event: any) => {
    setPageNum(event.selected + 1);
  };

  const prepareAddBlog = () => {
    reset();
    $('#summernote').summernote('destroy');
    $('#blog-modal').modal('show');
    $('#summernote').summernote({height: 100});
    $(document).on('click', '.note-btn', function(this: any){
      var ddMenu = $(this).next();
      ddMenu.toggleClass('show');
      $(document).on('focusout', ddMenu, function(){
        setTimeout(() => {
          $(ddMenu).removeClass('show');
        },200);  
      });
    });
  }

  const prepareEditBlog = (blog: any) => {
    reset();
    $('#summernote').summernote('destroy');
    $('#blog-modal').modal('show');
    setBlog(blog);
    setTimeout(() => {
      setValue('blogId', blog.blogId);
      setValue('heading', blog.heading, { shouldDirty: true });
      setValue('blogDate', blog.blogDate, { shouldDirty: true });
      setValue('tags', blog.tags, { shouldDirty: true });
    }, 500);
    $('#summernote').summernote({height: 100});
    $('#summernote').summernote('code', blog.description);
    $(document).on('click', '.note-btn', function(this: any){
      var ddMenu = $(this).next();
      ddMenu.toggleClass('show');
      $(document).on('focusout', ddMenu, function(){
        setTimeout(() => {
          $(ddMenu).removeClass('show');
        },200);  
      });
    });
  }

  const imageSelected = (e: any) => {
    console.log(e.target.files);
    if(e.target.files.length > 0){
      let file = e.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        setEncodedImage(reader.result?.toString());
        setImage(file.name);
      };
      reader.onerror = function (error) {
        constants.showError('Could not convert file to base64');
      };
    }else{
      setImage(null);
      setEncodedImage(null);
    }
  }

  const editBlog = (data: any) => {
    setFormSubmitLoader(true);
    if(blog && blog.blogId && blog.blogId.length > 0){
      if(image || encodedImage){
        data['image'] = image;
        data['encodedImage'] = encodedImage;  
      }
      data['description'] = $('#summernote').summernote('code');
      fetch(constants.BASE_URL + 'blog/' + blog.blogId, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
        body: JSON.stringify(data)
      }).then(function (response) {
        return response.json();
      }).then(function (response: any) {
        setFormSubmitLoader(false);
        if (response.status == '200') {
          $('#blog-modal').modal('hide');
          constants.showSuccess('Blog updated successfully');
          setBlog(null);
          setImage(null);
          setEncodedImage(null);
          getBlogList();
        } else {
          constants.showError(response.message);
        }
      });
    }else{
      if(!image || !encodedImage){
        constants.showError('Please select blog image');
        setFormSubmitLoader(false);
        return;
      }
      delete data.blogId;
      data['image'] = image;
      data['encodedImage'] = encodedImage;
      data['description'] = $('#summernote').summernote('code');
      fetch(constants.BASE_URL + 'blog', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
        body: JSON.stringify(data)
      }).then(function (response) {
        return response.json();
      }).then(function (response: any) {
        setFormSubmitLoader(false);
        if (response.status == '200') {
          $('#blog-modal').modal('hide');
          constants.showSuccess('Blog created successfully');
          setBlog(null);
          setImage(null);
          setEncodedImage(null);
          getBlogList();
        } else {
          constants.showError(response.message);
        }
      });
    }
  }

  return (
    <>
      <section className="page-header page-header-dark bg-secondary">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-8">
              <h1>My Profile</h1>
            </div>
            <div className="col-md-4">
              <ul className="breadcrumb justify-content-start justify-content-md-end mb-0">
                <li><a href="/">Home</a></li>
                <li className="active">My Profile</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div className="container-fluid p-5 mb-2">
        <div className="row">
          <div className="col-lg-2">
            <ProfileSidebar />
          </div>
          <div className="col-lg-10">
            <div className="bg-white shadow-md rounded p-4">
              <div className='row'>
                <div className='col-lg-12'>
                  <h4 className="mb-2">Blogs
                    <button className="featured-box-icon bg-primary text-white rounded-circle border-0 ms-2"
                      onClick={prepareAddBlog}>
                      <i className="fas fa-plus" style={{fontSize: '20px'}}></i>
                    </button>
                  </h4>
                </div>
              </div>
              <div className="tab-content my-3" id="myTabContent">
                <div className="tab-pane fade show active" id="first" role="tabpanel" aria-labelledby="first-tab">
                  <div className="table-responsive-md">
                    <table className="table table-hover border">
                      <thead>
                        <tr>
                          <th>Sr No</th>
                          <th>Heading</th>
                          <th>Date</th>
                          <th>Tags</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {blogList?.map((blg, index) => (
                          <tr key={index}>
                            <td className="align-middle">{((pageNum - 1) * itemsPerPage) + (index + 1)}</td>
                            <td className="align-middle">{blg.heading}</td>
                            <td className="align-middle">{blg.blogDate}</td>
                            <td className="align-middle">{blg.tags}</td>
                            <td className="align-middle">
                              <button className="featured-box-icon bg-primary text-white rounded-circle border-0 edit-icon"
                                data-bs-toggle="modal" data-bs-target="#blog-modal" onClick={() => prepareEditBlog(blg)}>
                                <i className="fas fa-edit"></i>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <ReactPaginate breakLabel="..." nextLabel="next >" onPageChange={handlePageClick} pageRangeDisplayed={5}
                      pageCount={pageCount} previousLabel="< previous" pageClassName="page-item" pageLinkClassName="page-link"
                      previousClassName="page-item" previousLinkClassName="page-link" nextClassName="page-item" nextLinkClassName="page-link"
                      breakClassName="page-item" breakLinkClassName="page-link" containerClassName="pagination" activeClassName="active"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="blog-modal" className="modal fade" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div className="modal-content border-0">
            <div className="modal-header">
              <h5 className="modal-title">Blog Details</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form id="loginForm" method="post" onSubmit={handleSubmit(editBlog)}>
              <div className="modal-body py-4 px-0">
                <div className="row">
                  <div className="col-11 col-md-10 mx-auto">
                    <input type="hidden" id="blogId" {...register("blogId")}/>
                    <div className="mb-3">
                      <label className="number" htmlFor="heading">Heading<span className='star'>*</span></label>
                      <input type="text" className="form-control" id="heading" placeholder="Heading" 
                        {...register("heading", { required: true })}/>
                      {errors.heading && <span className='text-danger'>Heading is required</span>}
                    </div>
                    <div className='row'>
                      <div className='col'>
                        <div className="mb-3">
                          <label className="form-label" htmlFor="blogDate">Blog Date <span className='star'>*</span></label>
                          <input type="date" className="form-control" id="blogDate" placeholder="Blog Date" 
                            {...register("blogDate", { required: true })}/>
                          {errors.blogDate && <span className='text-danger'>Blog Date is required</span>}
                        </div>
                      </div>
                      <div className='col'>
                        <div className="mb-3">
                          <label className="form-label" htmlFor="image">Image</label>
                          <input type="file" className="form-control" onChange={imageSelected} />
                        </div>
                      </div>
                    </div>
                    <div className="mb-3">
                      <label className="form-label" htmlFor="tags">Tags <span className='star'>*</span></label>
                      <input type="text" className="form-control" id="tags" placeholder="Seperate the tags with comma(,)" 
                        {...register("tags", { required: true })}/>
                      {errors.tags && <span className='text-danger'>One or more tags are required</span>}
                    </div>
                    <div className="mb-3">
                      <label className="form-label" htmlFor="description">Description <span className='star'>*</span></label>
                      <textarea className="form-control" id="summernote" placeholder='Description'></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-gray" data-bs-dismiss="modal">Close </button>
                <button type="submit" className="btn btn-primary" disabled={formSubmitLoader}>
                  {formSubmitLoader && <i className="fas fa-circle-notch fa-spin me-2"></i>}
                  Save changes
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}
