import React, { useState } from 'react'
import * as constants from '../../Constants/Constants'
import { useForm } from "react-hook-form";
import './Login.css'
import { useNavigate } from 'react-router-dom';
declare var $: any;

export default function Login () {

  let navigate = useNavigate();
  const { register, handleSubmit, watch, formState: { errors },reset} = useForm();
  const [formSubmitLoader, setFormSubmitLoader] = useState<boolean>(false);
  const[eye,setEye]=useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
    setEye(eye ? false:true);
  };


  const login = (data: any) => {
  
    setFormSubmitLoader(true);
    fetch(constants.BASE_URL + 'user/login', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
      body: JSON.stringify(data)
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      setFormSubmitLoader(false);
      if (response.status == '200') {
        reset()
        constants.showSuccess(response.message);
        localStorage.setItem('currentUser', JSON.stringify(response.result));
        constants.loginSubscriber.next(true);
        $('#login-modal').modal('hide');
        navigate('/dashboard');
      }else{
        constants.showError(response.message);
      }
    });
  }

  return (
    <div id="login-modal" className="modal fade" role="dialog" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content border-0">
          <div className="modal-body py-4 px-0">
            <button type="button" className="btn-close position-absolute top-0 end-0 mt-1 me-1 mt-sm-2 me-sm-2" data-bs-dismiss="modal" aria-label="Close"></button>
            <div className="row">
              <div className="col-11 col-md-10 mx-auto">
                <ul className="nav nav-tabs nav-justified mb-4" role="tablist">
                  <li className="nav-item"> <a className="nav-link text-5 lh-lg active">Login</a> </li>
                  <li className="nav-item"> <a className="nav-link text-5 lh-lg" href="" data-bs-toggle="modal" data-bs-target="#signup-modal" data-bs-dismiss="modal">Sign Up</a> </li>
                </ul>
                <p className="text-4 fw-300 text-muted text-center mb-4">We are glad to see you again!</p>
                <form id="loginForm" method="post" onSubmit={handleSubmit(login)}>
                  <div className="mb-3">
                    <input type="email" className="form-control"  id="emailAddress" placeholder="Email" autoFocus
                      {...register("email", { required: true, pattern:/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/})}/>
                    {errors.email && <span className='text-danger'>Please enter valid email</span>}
                  </div>
                  <div className="mb-3 ">
                    <input type="password" className="form-control" id="loginPassword" placeholder="Password" minLength={5}
                      {...register("password", { required: true})}/>
                    {errors.password && <span className='text-danger'>Please enter  password</span>}
                  </div>
                  <div className="row my-4">
                    <div className="col">
                      <div className="form-check text-3">
                      </div>
                    </div>
                    <div className="col text-2 text-end"><a className="btn-link" href="" data-bs-toggle="modal" data-bs-target="#forgot-password-modal" data-bs-dismiss="modal">Forgot Password ?</a></div>
                  </div>
                  <div className="d-grid my-4">
                    <button className="btn btn-primary" type="submit" disabled={formSubmitLoader}>
                      {formSubmitLoader && <i className="fas fa-circle-notch fa-spin me-2"></i>}
                      Login
                    </button>
                  </div>
                </form>
                <p className="text-2 text-center mb-0">New to CompanySearch? <a className="btn-link" href="" data-bs-toggle="modal" data-bs-target="#signup-modal" data-bs-dismiss="modal">Sign Up</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="toast">
    <div className="toast-header">
      Toast Header
    </div>
    <div className="toast-body">
      Some text inside the toast body
    </div>
  </div>
    </div>
  )
}
