import React, { useEffect, useState } from 'react'
import ProfileSidebar from '../ProfileSidebar/ProfileSidebar'
import * as constants from '../../Constants/Constants'
import { useNavigate } from 'react-router-dom';
import './Dashboard.css'

declare var $: any;

export default function Dashboard() {

  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState<any>();
  const [counts, setCounts] = useState<any>();
  const [categoryList, setCategoryList] = useState<any[]>();
  const [subCategoryList, setSubCategoryList] = useState<any[]>();
  const [serviceList, setServiceList] = useState<any[]>();
  const [activeCategory, setActiveCategory] = useState<any>();
  const [activeSubCategory, setActiveSubCategory] = useState<any>();
  const [activeService, setActiveService] = useState<any>();
  const [serviceDetails, setServiceDetails] = useState<any>();
  const [serviceActiveTab, setServiceActiveTab] = useState<string>('overview');
  const [formSubmitLoader, setFormSubmitLoader] = useState<boolean>(false);

  useEffect(() => {
    if (localStorage.getItem('currentUser')) {
      let userData = JSON.parse(localStorage.getItem('currentUser')!);
      setCurrentUser(userData);
      getDashboardCounts(userData.userId);
      getCategoryList();
    }
  }, []);

  const getDashboardCounts = (userId: string) => {
    fetch(constants.BASE_URL + 'dashboard/counts/' + userId, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      if (response.status == '200') {
        setCounts(response.result);
      } else {
        constants.showError(response.message);
      }
    });
  }

  const getCategoryList = () => {
    fetch(constants.BASE_URL + 'category?numPerPage=10', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      if (response.status == '200') {
        setCategoryList(response.result);
        if (response.result.length > 0) {
          setActiveCategory(response.result[0]);
          getSubCategoryList(response.result[0].categoryId);
        }
      } else {
        constants.showError(response.message);
      }
    });
  }

  const getSubCategoryList = (categoryId: string) => {
    fetch(constants.BASE_URL + 'category?numPerPage=30&parentCategoryId=' + categoryId, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      if (response.status == '200') {
        setSubCategoryList(response.result);
        if (response.result.length > 0) {
          setActiveSubCategory(response.result[0]);
          getServiceList(response.result[0].categoryId);
        }
      } else {
        constants.showError(response.message);
      }
    });
  }

  const getServiceList = (categoryId: string) => {
    fetch(constants.BASE_URL + 'services?sortBy=sequenceNo&sortOrder=asc&numPerPage=30&categoryId=' + categoryId, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      if (response.status == '200') {
        setServiceList(response.result);
      } else {
        constants.showError(response.message);
      }
    });
  }

  const onCategoryChange = (category: any) => {
    setActiveCategory(category);
    getSubCategoryList(category.categoryId);
  }

  const onSubCategoryChange = (category: any) => {
    setActiveSubCategory(category);
    getServiceList(category.categoryId);
  }

  const viewServiceDetails = (activeService: any) => {
    setActiveService(activeService)
    fetch(constants.BASE_URL + 'services/details/' + activeService.fileName, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      setServiceDetails(response.result);
      $('#service-modal').modal('show');
    });
  }

  const goToCheckout = (serviceId: string) => {
    $('#service-modal').modal('hide');
    navigate("/checkout/" + serviceId);
  }

  const addToInterested = (serviceId: string) => {
    setFormSubmitLoader(true);
    fetch(constants.BASE_URL + 'service-interest/' + serviceId + '/' + currentUser?.userId, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
      body: JSON.stringify({})
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      setFormSubmitLoader(false);
      if (response.status == '200') {
        constants.showSuccess(response.message);
        $('#service-modal').modal('hide');
      } else {
        constants.showError(response.message);
      }
    });
  }

  const goToServiceEdit = (activeService: any) => {
    $('#service-modal').modal('hide');
    navigate("/service/edit/" + activeService.fileName.split('.')[0]);
  }

  return (
    <>
      <section className="page-header page-header-dark bg-secondary">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-8">
              <h1>My Profile</h1>
            </div>
            <div className="col-md-4">
              <ul className="breadcrumb justify-content-start justify-content-md-end mb-0">
                <li><a href="/">Home</a></li>
                <li className="active">My Profile</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div className="container-fluid p-5 mb-2">
        <div className="row">
          <div className="col-lg-2">
            <ProfileSidebar />
          </div>
          <div className="col-lg-10">
            <div className="bg-white shadow-md rounded p-4">
              <h4 className="mb-1">Dashboard</h4>
              <hr className="mx-n2 mb-0" />
              <div className="row">
                <div className="col-md-4">
                  <div className="dbox dbox--color-1">
                    <div className="dbox__body">
                      <p className="dbox__count mb-0">{counts?.paymentPending}</p>
                      <p className="dbox__title mb-0">Services Pending</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="dbox dbox--color-2">
                    <div className="dbox__body">
                      <p className="dbox__count mb-0">{counts?.inProgress}</p>
                      <p className="dbox__title mb-0">In Progress</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="dbox dbox--color-3">
                    <div className="dbox__body">
                      <p className="dbox__count mb-0">{counts?.complete}</p>
                      <p className="dbox__title mb-0">Completed</p>
                    </div>
                  </div>
                </div>
              </div>
              <h4 className="mb-1 mt-2">All Services</h4>
              <hr className="mx-n2" />
              <ul className="nav nav-tabs mb-3 position-relative" role="tablist">
                {categoryList?.map((item, index) => (
                  <li className="nav-item cursor-pointer" key={index}>
                    <a className={"nav-link headingfont " + (activeCategory.categoryId == item.categoryId ? 'active' : '')} onClick={() => onCategoryChange(item)}>{item.categoryName}</a>
                  </li>
                ))}
              </ul>
              <div className='col-md-12'>
                <div className='row'>
                  <div className='col-md-3'>
                    <div id='servicefirst-tab'>
                      <ul className="nav nav-pills alternate flex-lg-column mb-3 mb-lg-0 sticky-top" style={{ zIndex: 899, top: '80px' }}>
                        {subCategoryList?.map((item, index) => (
                          <li className="nav-item" key={index}>
                            <a className={"nav-link cursor-pointer " + (activeSubCategory.categoryId == item.categoryId ? 'active' : '')} onClick={() => onSubCategoryChange(item)}>{item.categoryName}</a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className='col-md-9 ' >
                    <div className="row" id='business setup'>
                      {serviceList?.map((item, index) => (
                        
                          
                          <div className="column" key={index} onClick={() => viewServiceDetails(item)}>
                          {item.serviceName}
                          <div>

                            <span style={{ color: 'blue' }}>₹ {item.basePrice}/-</span>
                            <span style={{ color: 'grey' }}>only (All inclusive)</span>
                            <span className='arrow'>{'>'}</span>
                          </div>
                          <div className='paylink'>Pay ₹ {item.minimumPayable}/- to get started</div>
                        
                          
                        </div>
                        
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="service-modal" className="modal fade" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div className="modal-content border-0">
            <div className="modal-header d-block">
              <div className="row">
                <div className='col-md-6 col-sm-6 col-xs-6 div1'>
                  <h5 className="modal-title">
                    {serviceDetails?.serviceName}
                    <br />
                    <span className='paylink p-1'>Pay ₹ {activeService?.minimumPayable}/- to get started</span>
                  </h5>
                </div>
                <div className='col-md-3 col-sm-4 col-xs-4 div2'>
                  <h5 className="modal-title">
                    <span className='text-primary fw-bold'>₹ {activeService?.basePrice}/-</span>
                    <br />
                    <span className='text-gray' style={{ fontSize: '14px' }}>(All inclusive)</span>
                  </h5>
                </div>
                <div className='col-md-3 col-sm-2 col-xs-2 div3'>
                  <button type="button" className="btn-close float-end" data-bs-dismiss="modal" aria-label="Close"></button>

                </div>


              </div>
            </div>
            <div className="modal-body">
              <ul className="nav nav-tabs mb-4 position-relative" role="tablist">
                <li className="nav-item cursor-pointer">
                  <a className={"nav-link " + (serviceActiveTab == 'overview' ? 'active' : '')} onClick={() => setServiceActiveTab('overview')}>Overview</a>
                </li>
                <li className="nav-item cursor-pointer">
                  <a className={"nav-link " + (serviceActiveTab == 'benefits' ? 'active' : '')} onClick={() => setServiceActiveTab('benefits')}>Benefits</a>
                </li>
                <li className="nav-item cursor-pointer">
                  <a className={"nav-link " + (serviceActiveTab == 'requirements' ? 'active' : '')} onClick={() => setServiceActiveTab('requirements')}>Requirements</a>
                </li>
                <li className="nav-item cursor-pointer">
                  <a className={"nav-link " + (serviceActiveTab == 'documents' ? 'active' : '')} onClick={() => setServiceActiveTab('documents')}>Documents</a>
                </li>
              </ul>
              <div className='service-tab-content p-2'>
                {serviceActiveTab == 'overview' &&
                  <div dangerouslySetInnerHTML={{ __html: serviceDetails?.overview }}></div>
                }
                {serviceActiveTab == 'benefits' &&
                  <div dangerouslySetInnerHTML={{ __html: serviceDetails?.benefits }}></div>
                }
                {serviceActiveTab == 'requirements' &&
                  <div dangerouslySetInnerHTML={{ __html: serviceDetails?.requirements }}></div>
                }
                {serviceActiveTab == 'documents' &&
                  <div dangerouslySetInnerHTML={{ __html: serviceDetails?.documentReq }}></div>
                }
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-sm btn-gray" data-bs-dismiss="modal">Close</button>
              {currentUser && currentUser.role?.name == 'ADMIN' &&
                <button type="button" className="btn btn-sm btn-primary" onClick={() => goToServiceEdit(activeService)}>Edit</button>
              }
              {currentUser && currentUser.role?.name == 'CUSTOMER' &&
                <>
                  <button type="button" className="btn btn-sm btn-outline-primary font-weight-bold"
                    disabled={formSubmitLoader} onClick={() => addToInterested(activeService?.serviceId)}>
                    {formSubmitLoader && <i className="fas fa-circle-notch fa-spin me-2"></i>}
                    <i className="fa fa-thumbs-up" aria-hidden="true"></i> I'm Interested</button>
                  <button type="button" className="btn btn-sm btn-primary" onClick={() => goToCheckout(activeService?.serviceId)}>Buy Now</button>
                </>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
