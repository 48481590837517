import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import ProfileSidebar from '../ProfileSidebar/ProfileSidebar'
import * as constants from '../../Constants/Constants';
import { NavLink, useNavigate } from 'react-router-dom';


export default function () {

  const [currentUser, setCurrentUser] = useState<any>();
  const [profileType, setProfileType] = useState<string>('');
  const { register, handleSubmit, setValue, formState: { errors } ,reset} = useForm();
  const [formSubmitLoader, setFormSubmitLoader] = useState<boolean>(false);

  useEffect(() => {
    if (localStorage.getItem('currentUser')) {
      let userData = JSON.parse(localStorage.getItem('currentUser')!);
      setCurrentUser(userData);
      setProfileType(userData.profileType);
      setTimeout(() => {
        setValue('gender', userData.gender, { shouldDirty: true });
        setValue('fullName', userData.fullName, { shouldDirty: true });
        setValue('mobile', userData.mobile, { shouldDirty: true });
        setValue('email', userData.email, { shouldDirty: true });
        setValue('dateOfBirth', userData.dateOfBirth, { shouldDirty: true });
        setValue('profileType', userData.profileType, { shouldDirty: true });
        setValue('orgStatus', userData.orgStatus, { shouldDirty: true });
        setValue('businessType', userData.businessType, { shouldDirty: true });
        setValue('ageOfBusiness', userData.ageOfBusiness, { shouldDirty: true });
        setValue('totalEmployee', userData.totalEmployee, { shouldDirty: true });
      }, 500);
    }
  }, []);

  const updateProfile = (data: any) => {
    setFormSubmitLoader(true);
    fetch(constants.BASE_URL + 'user/' + currentUser.userId, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
      body: JSON.stringify(data)
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      setFormSubmitLoader(false);
      if (response.status == '200') {
        response.result.sessionToken = currentUser.sessionToken;
        localStorage.setItem('currentUser', JSON.stringify(response.result));
        constants.showSuccess('Profile updated successfully');
        constants.loginSubscriber.next(true);
      } else {
        constants.showError(response.message);
      }
    });
  }

  const profileTypeChange = (e: any) => {
    setProfileType(e.target?.value);
  }

  return (
    <>
      <section className="page-header page-header-dark bg-secondary">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-8">
              <h1>My Profile</h1>
            </div>
            <div className="col-md-4">
              <ul className="breadcrumb justify-content-start justify-content-md-end mb-0">
                <li><NavLink to="/">Home</NavLink></li>
                <li className="active">My Profile</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div className="container mb-2">
        <div className="row">
          <div className="col-lg-3">
            <ProfileSidebar />
          </div>
          <div className="col-lg-9">
            <div className="bg-white shadow-md rounded p-4">
              <h4 className="mb-4">Profile Information</h4>
              <hr className="mx-n4 mb-4" />
              <div className="row">
                <div className="col-lg-8">
                  <form id="personalInformation" method="post" onSubmit={handleSubmit(updateProfile)}>
                    <div className="mb-3">
                      <label className="form-label me-4" htmlFor="profileType">Profile Type <span className='star'>*</span>:</label>
                      <div className="form-check form-check-inline">
                        <input id="individual" value="INDIVIDUAL" className="form-check-input" type="radio" onClick={profileTypeChange}
                          {...register("profileType", { required: true })} />
                        <label className="form-check-label" htmlFor="individual">Individual</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input id="company" value="COMPANY" className="form-check-input" type="radio" onClick={profileTypeChange}
                          {...register("profileType", { required: true })} />
                        <label className="form-check-label" htmlFor="company">Company</label>
                      </div>
                      {errors.profileType && <><br /><span className='text-danger'>Please select profile type</span></>}
                    </div>
                    <div className="mb-3">
                      <label className="form-label" htmlFor="fullName">Your/Comapny Name<span className='star'>*</span></label>
                      <input type="text" className="form-control" id="fullName" placeholder="Your/Company Name"
                        {...register("fullName", { required: true, pattern:/[a-zA-Z][a-zA-Z ]{3,}/, maxLength:45 })} />
                      {errors.fullName && <span className='text-danger'> Valid name is required</span>}
                    </div>
                    {profileType == 'COMPANY' &&
                      <>
                        <div className="mb-3">
                          <label className="form-label" htmlFor="businessType">Business Type</label>
                          <select className="form-control" id="businessType" {...register("businessType", { required: true })}>
                            <option value="">Select</option>
                            <option value="Agri & Agritech">Agri & Agritech</option>
                            <option value="Automobiles">Automobiles</option>
                            <option value="Clothing & Apparel">Clothing & Apparel</option>
                            <option value="eCommerce">eCommerce</option>
                            <option value="Education">Education</option>
                            <option value="Finance & Fintech">Finance & Fintech</option>
                            <option value="Food & Beverages">Food & Beverages</option>
                            <option value="Healthcare & Healthtech">Healthcare & Healthtech</option>
                            <option value="Hospitality, Travel & Tourism">Hospitality, Travel & Tourism</option>
                            <option value="Logistics & Transport">Logistics & Transport</option>
                            <option value="Advertising & Marketing">Advertising & Marketing</option>
                            <option value="Telecom, Media & Entertainment">Telecom, Media & Entertainment</option>
                            <option value="Pharma">Pharma</option>
                            <option value="Real Estate">Real Estate</option>
                            <option value="Energy">Energy</option>
                            <option value="Labor & Employment">Labor & Employment</option>
                            <option value="Software & ITeS">Software & ITeS</option>
                            <option value="Trade & Retail">Trade & Retail</option>
                            <option value="Manufacturing">Manufacturing</option>
                            <option value="Other Services">Other Services</option>
                          </select>
                          {errors.businessType && <span className='text-danger'>Business type is required</span>}
                        </div>
                        <div className="mb-3">
                          <label className="form-label" htmlFor="ageOfBusiness">Age of Business (In Years)</label>
                          <select className="form-control" id="ageOfBusiness" {...register("ageOfBusiness", { required: true })}>
                            <option value="">Select</option>
                            <option value="Less than 1 year">Less than 1 year</option>
                            <option value="1-2 years">1-2 years</option>
                            <option value="2-5 years">2-5 years</option>
                            <option value="5+ years">5+ years</option>
                          </select>  
                          {errors.ageOfBusiness && <span className='text-danger'>Age of Business is required</span>}
                        </div>
                        <div className="mb-3">
                          <label className="form-label" htmlFor="totalEmployee">Total Employee</label>
                          <select className="form-control" id="totalEmployee" {...register("totalEmployee", { required: true })}>
                            <option value="">Select</option>
                            <option value="Less than 20">Less than 20</option>
                            <option value="21-100">21-100</option>
                            <option value="101-500">101-500</option>
                            <option value="501+">501+</option>
                          </select>  
                          {errors.totalEmployee && <span className='text-danger'>Please enter total employees</span>}
                        </div>
                        <div className="mb-3">
                          <label className="form-label" htmlFor="orgStatus">Business Status</label>
                          <select className="form-control" id="orgStatus" {...register("orgStatus", { required: true })}>
                            <option value="">Select</option>
                            <option value="Yet to start">Yet to start</option>
                            <option value="Have been for a while">Have been for a while</option>
                          </select>
                          {errors.orgStatus && <span className='text-danger'>Business Status is required</span>}
                        </div>
                      </>
                    }
                    {profileType == 'INDIVIDUAL' &&
                      <>
                        <div className="mb-3">
                          <label className="form-label me-4" htmlFor="profileType">Gender <span className='star'>*</span> :</label>
                          <div className="form-check form-check-inline">
                            <input id="male" value="MALE" className="form-check-input" type="radio"
                              {...register("gender", { required: true })} />
                            <label className="form-check-label" htmlFor="male">Male</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input id="female" value="FEMALE" className="form-check-input" type="radio"
                              {...register("gender", { required: true })} />
                            <label className="form-check-label" htmlFor="female">Female</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input id="other" value="OTHER" className="form-check-input" type="radio"
                              {...register("gender", { required: true })} />
                            <label className="form-check-label" htmlFor="female">Other</label>
                          </div>
                          {errors.gender && <><br /><span className='text-danger'>Gender is required</span></>}
                        </div>
                        <div className="mb-3">
                          <label className="form-label" htmlFor="birthDate">Date of Birth <span className='star'>*</span> </label>
                          <input id="birthDate" type="date" className="form-control" placeholder="Date of Birth"
                            {...register("dateOfBirth", { required: true })} />
                          {errors.dateOfBirth && <span className='text-danger'>Date of birth is required</span>}
                        </div>
                      </>
                    }
                    <div className="mb-3">
                      <label className="form-label" htmlFor="mobileNumber">Mobile Number<span className='star'>*</span></label>
                      <input type="text" className="form-control" id="mobileNumber" placeholder="Mobile Number"
                        {...register("mobile", { required: true, pattern:/^(\+91[\-\s]?)?[0]?(91)?[789]\d{9}$/, minLength: 10, maxLength: 13})} />
                      {errors.mobile && <span className='text-danger'> Valid mobile number is required</span>}
                    </div>
                    <div className="mb-3">
                      <label className="form-label" htmlFor="emailID">Email ID<span className='star'>*</span></label>
                      <input type="text" className="form-control" id="emailID" placeholder="Email ID"
                        {...register("email", { required: true, pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/})} />
                      {errors.email && <span className='text-danger'> Valid email is required</span>}
                    </div>
                    <button className="btn btn-primary" type="submit" disabled={formSubmitLoader}>
                      {formSubmitLoader && <i className="fas fa-circle-notch fa-spin me-2"></i>}
                      Update Now
                    </button>
                  </form>
                </div>
                <div className="col-lg-4 mt-4 mt-lg-0 ">
                  <div className="bg-light-2 rounded p-4">
                    <h3 className="text-4 mb-2">We value your Privacy</h3>
                    <p className="mb-0">We will not sell or distribute your contact information. Read our <a
                      href="#">Privacy Policy</a>.</p>
                    <hr className="mx-n4" />
                    <h3 className="text-4 mb-3">Billing Enquiries</h3>
                    <p className="mb-0">Do not hesitate to reach our support team if you have any queries.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
