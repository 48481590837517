import React, { useEffect, useState } from 'react'
import ProfileSidebar from '../../ProfileSidebar/ProfileSidebar'
import * as constants from '../../../Constants/Constants'
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom';
declare var $: any;

export default function CustomerInterest() {

  const navigate = useNavigate();
  const [interestList, setInterestList] = useState<any[]>();
  const [complianceInterestList, setComplianceInterestList] = useState<any[]>();
  const [selectedUser, setSelectedUser] = useState<any>();

  // VARIABLES FOR PAGINATION
  const [pageCount, setPageCount] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [status, setStatus] = useState<string>('SERVICE');

  useEffect(() => {
    getInterestList();
  }, [pageNum, status]);

  const getInterestList = () => {
    if (status == 'SERVICE') {
      fetch(constants.BASE_URL + 'service-interest?pageNum=' + pageNum + '&numPerPage=' + itemsPerPage, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
      }).then(function (response) {
        return response.json();
      }).then(function (response: any) {
        if (response.status == '200') {
          response.result.forEach((element: any) => {
            element.createdDate = element.createdDate.split('T')[0];
          });
          setInterestList(response.result);
          setPageCount(Math.ceil(response.listCount / itemsPerPage));
        }
      });
    } else {
      fetch(constants.BASE_URL + 'compliance-interest?pageNum=' + pageNum + '&numPerPage=' + itemsPerPage, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
      }).then(function (response) {
        return response.json();
      }).then(function (response: any) {
        if (response.status == '200') {
          response.result.forEach((element: any) => {
            element.createdDate = element.createdDate.split('T')[0];
          });
          setComplianceInterestList(response.result);
          setPageCount(Math.ceil(response.listCount / itemsPerPage));
        }
      });
    }
  }

  const handlePageClick = (event: any) => {
    setPageNum(event.selected + 1);
  };

  const viewServiceDetails = (fileName: string) => {
    navigate("/" + fileName.split('.')[0]);
  }

  const viewUserDetails = (user: any) => {
    setSelectedUser(user);
    $('#user-modal').modal('show');
  }

  const searchByStatus = (status: string) => {
    setStatus(status);
  }

  return (
    <>
      <section className="page-header page-header-dark bg-secondary">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-8">
              <h1>My Profile</h1>
            </div>
            <div className="col-md-4">
              <ul className="breadcrumb justify-content-start justify-content-md-end mb-0">
                <li><a href="/">Home</a></li>
                <li className="active">My Profile</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div className="container-fluid p-5 mb-2">
        <div className="row">
          <div className="col-lg-2">
            <ProfileSidebar />
          </div>
          <div className="col-lg-10">
            <div className="bg-white shadow-md rounded p-4">
              <div className='row'>
                <div className='col-lg-12'>
                  <h4 className="mb-2">Customer Interests</h4>
                  <hr className="mx-n4" />
                  <ul className="nav nav-tabs mb-4 position-relative" id="myTab" role="tablist">
                    <div className='col-md-1.5 col-sm-1.5 col-xs-2'>
                      <li className="nav-item cursor-pointer" onClick={() => searchByStatus('SERVICE')}> <a className={"nav-link" + (status == 'SERVICE' ? ' active' : '')} id="first-tab">Service</a></li>
                    </div>
                    <div className='col-md-1.5 col-sm-1.5 col-xs-2'>
                      <li className="nav-item cursor-pointer" onClick={() => searchByStatus('COMPLIANCE')}> <a className={"nav-link" + (status == 'COMPLIANCE' ? ' active' : '')} id="second-tab">Compliance</a> </li>
                    </div>
                  </ul>
                </div>
              </div>
              <div className="tab-content my-3" id="myTabContent">
                <div className="tab-pane fade show active" id="first" role="tabpanel" aria-labelledby="first-tab">
                  <div className="table-responsive-md">
                    {status == 'SERVICE' &&
                      <>
                        <table className="table table-hover border">
                          <thead>
                            <tr>
                              <th>Sr No</th>
                              <th>Customer</th>
                              <th>Service</th>
                              <th>Base Price(₹)</th>
                              <th>Minimum Payable(₹)</th>
                              <th>Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {interestList?.map((il, index) => (
                              <tr key={index}>
                                <td className="align-middle">{((pageNum - 1) * itemsPerPage) + (index + 1)}</td>
                                <td className="align-middle">
                                  <a className="btn-link cursor-pointer" onClick={() => viewUserDetails(il.user)}>{il?.user?.fullName}</a>
                                </td>
                                <td className="align-middle">
                                  <a className='text-primary cursor-pointer' onClick={() => viewServiceDetails(il.service?.fileName)}>{il.service?.serviceName}</a>
                                </td>
                                <td className="align-middle">{il.service?.basePrice}</td>
                                <td className="align-middle">{il.service?.minimumPayable}</td>
                                <td className="align-middle">{il.createdDate}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <ReactPaginate breakLabel="..." nextLabel="next >" onPageChange={handlePageClick} pageRangeDisplayed={5}
                          pageCount={pageCount} previousLabel="< previous" pageClassName="page-item" pageLinkClassName="page-link"
                          previousClassName="page-item" previousLinkClassName="page-link" nextClassName="page-item" nextLinkClassName="page-link"
                          breakClassName="page-item" breakLinkClassName="page-link" containerClassName="pagination" activeClassName="active"
                        />
                      </>
                    }
                    {status == 'COMPLIANCE' &&
                      <>
                        <table className="table table-hover border">
                          <thead>
                            <tr>
                              <th>Sr No</th>
                              <th>Customer</th>
                              <th>Form</th>
                              <th>Due Date</th>
                              <th>Description</th>
                            </tr>
                          </thead>
                          <tbody>
                            {complianceInterestList?.map((ci, index) => (
                              <tr key={index}>
                                <td className="align-middle">{((pageNum - 1) * itemsPerPage) + (index + 1)}</td>
                                <td className="align-middle">
                                  <a className="btn-link cursor-pointer" onClick={() => viewUserDetails(ci.user)}>{ci?.user?.fullName}</a>
                                </td>
                                <td className="align-middle">{ci.compliance?.form}</td>
                                <td className="align-middle">{ci.compliance?.dueDate}</td>
                                <td className="align-middle">{ci.compliance?.description}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <ReactPaginate breakLabel="..." nextLabel="next >" onPageChange={handlePageClick} pageRangeDisplayed={5}
                          pageCount={pageCount} previousLabel="< previous" pageClassName="page-item" pageLinkClassName="page-link"
                          previousClassName="page-item" previousLinkClassName="page-link" nextClassName="page-item" nextLinkClassName="page-link"
                          breakClassName="page-item" breakLinkClassName="page-link" containerClassName="pagination" activeClassName="active"
                        />
                      </>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="user-modal" className="modal fade" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div className="modal-content border-0">
            <div className="modal-header">
              <h5 className="modal-title">Customer Details</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body py-4 px-0">
              <div className="row ps-4 pe-4">
                <div className="col mx-auto">
                  <div className="mb-3">
                    <label>Customer Name</label>
                    <p className='fw-bold fs-6'>{selectedUser?.fullName}</p>
                  </div>
                </div>
                <div className="col mx-auto">
                  <div className="mb-3">
                    <label>Mobile</label>
                    <p className='fw-bold fs-6'>{selectedUser?.mobile}</p>
                  </div>
                </div>
              </div>
              <div className="row ps-4 pe-4">
                <div className="col mx-auto">
                  <div className="mb-3">
                    <label>Email</label>
                    <p className='fw-bold fs-6'>{selectedUser?.email}</p>
                  </div>
                </div>
                <div className="col mx-auto">
                  <div className="mb-3">
                    <label>Profile Type</label>
                    <p className='fw-bold fs-6'>{selectedUser?.profileType}</p>
                  </div>
                </div>
              </div>
              {selectedUser && selectedUser?.profileType == 'INDIVIDUAL' &&
                <>
                  <div className="row ps-4 pe-4">
                    <div className="col mx-auto">
                      <div className="mb-3">
                        <label>Gender</label>
                        <p className='fw-bold fs-6'>{selectedUser?.gender}</p>
                      </div>
                    </div>
                    <div className="col mx-auto">
                      <div className="mb-3">
                        <label>Date of Birth</label>
                        <p className='fw-bold fs-6'>{selectedUser?.dateOfBirth}</p>
                      </div>
                    </div>
                  </div>
                </>
              }
              {selectedUser && selectedUser?.profileType == 'COMPANY' &&
                <>
                  <div className="row ps-4 pe-4">
                    <div className="col mx-auto">
                      <div className="mb-3">
                        <label>Business Type</label>
                        <p className='fw-bold fs-6'>{selectedUser?.businessType}</p>
                      </div>
                    </div>
                    <div className="col mx-auto">
                      <div className="mb-3">
                        <label>Age of Business</label>
                        <p className='fw-bold fs-6'>{selectedUser?.ageOfBusiness}</p>
                      </div>
                    </div>
                  </div>
                  <div className="row ps-4 pe-4">
                    <div className="col mx-auto">
                      <div className="mb-3">
                        <label>Total Employee</label>
                        <p className='fw-bold fs-6'>{selectedUser?.totalEmployee}</p>
                      </div>
                    </div>
                    <div className="col mx-auto">
                      <div className="mb-3">
                        <label>Business Status</label>
                        <p className='fw-bold fs-6'>{selectedUser?.orgStatus}</p>
                      </div>
                    </div>
                  </div>
                </>
              }
              <br />
              <h5 className='ps-4 pe-4'>Address Details</h5>
              <hr />
              <div className="row ps-4 pe-4">
                <div className="col mx-auto">
                  <div className="mb-3">
                    <label>Address Line 1</label>
                    <p className='fw-bold fs-6'>{selectedUser?.address?.addressLineOne}</p>
                  </div>
                </div>
                <div className="col mx-auto">
                  <div className="mb-3">
                    <label>Address Line 2</label>
                    <p className='fw-bold fs-6'>{selectedUser?.address?.addressLineTwo}</p>
                  </div>
                </div>
              </div>
              <div className="row ps-4 pe-4">
                <div className="col mx-auto">
                  <div className="mb-3">
                    <label>City</label>
                    <p className='fw-bold fs-6'>{selectedUser?.address?.city}</p>
                  </div>
                </div>
                <div className="col mx-auto">
                  <div className="mb-3">
                    <label>State</label>
                    <p className='fw-bold fs-6'>{selectedUser?.address?.state}</p>
                  </div>
                </div>
              </div>
              <div className="row ps-4 pe-4">
                <div className="col mx-auto">
                  <div className="mb-3">
                    <label>ZipCode</label>
                    <p className='fw-bold fs-6'>{selectedUser?.address?.zipCode}</p>
                  </div>
                </div>
                <div className="col mx-auto">
                  <div className="mb-3">
                    <label>Country</label>
                    <p className='fw-bold fs-6'>{selectedUser?.address?.country}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
