import React, { useEffect, useState } from 'react'
import ProfileSidebar from '../../ProfileSidebar/ProfileSidebar'
import * as constants from '../../../Constants/Constants'
import ReactPaginate from 'react-paginate';

export default function Enquiries() {

  const [enquiryList, setEnquiryList] = useState<any[]>();

  // VARIABLES FOR PAGINATION
  const [pageCount, setPageCount] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const [itemsPerPage , setItemsPerPage ] = useState(10);

  useEffect(() => {
    getEnquiryList();
  }, [pageNum]);

  const getEnquiryList = () => {
    fetch(constants.BASE_URL + 'contact-us?pageNum='+pageNum+'&numPerPage=' + itemsPerPage, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      if (response.status == '200') {
        response.result.forEach((element: any) => {
          element.createdDate = element.createdDate.split('T')[0];
        });
        setEnquiryList(response.result);
        setPageCount(Math.ceil(response.listCount / itemsPerPage));
      }
    });
  }

  const handlePageClick = (event: any) => {
    setPageNum(event.selected + 1);
  };

  return (
    <>
      <section className="page-header page-header-dark bg-secondary">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-8">
              <h1>My Profile</h1>
            </div>
            <div className="col-md-4">
              <ul className="breadcrumb justify-content-start justify-content-md-end mb-0">
                <li><a href="/">Home</a></li>
                <li className="active">My Profile</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div className="container-fluid p-5 mb-2">
        <div className="row">
          <div className="col-lg-2">
            <ProfileSidebar />
          </div>
          <div className="col-lg-10">
            <div className="bg-white shadow-md rounded p-4">
              <div className='row'>
                <div className='col-lg-12'>
                  <h4 className="mb-2">Enquiries</h4>
                </div>
              </div>
              <div className="tab-content my-3" id="myTabContent">
                <div className="tab-pane fade show active" id="first" role="tabpanel" aria-labelledby="first-tab">
                  <div className="table-responsive-md">
                    <table className="table table-hover border">
                      <thead>
                        <tr>
                          <th>Sr No</th>
                          <th>Name</th>
                          <th>Mobile</th>
                          <th>Email</th>
                          <th>Message</th>
                          <th>Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {enquiryList?.map((enq, index) => (
                          <tr key={index}>
                            <td className="align-middle">{((pageNum - 1) * itemsPerPage) + (index + 1)}</td>
                            <td className="align-middle">{enq.name}</td>
                            <td className="align-middle">{enq.mobile}</td>
                            <td className="align-middle">{enq.email}</td>
                            <td className="align-middle">{enq.message}</td>
                            <td className="align-middle">{enq.createdDate}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <ReactPaginate breakLabel="..." nextLabel="next >" onPageChange={handlePageClick} pageRangeDisplayed={5}
                      pageCount={pageCount} previousLabel="< previous" pageClassName="page-item" pageLinkClassName="page-link"
                      previousClassName="page-item" previousLinkClassName="page-link" nextClassName="page-item" nextLinkClassName="page-link"
                      breakClassName="page-item" breakLinkClassName="page-link" containerClassName="pagination" activeClassName="active"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
