import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import ProfileSidebar from '../ProfileSidebar/ProfileSidebar'
import * as constants from '../../Constants/Constants'
import { NavLink } from 'react-router-dom';
import "./ChangePassword.css";

export default function ChangePassword() {

  const [currentUser, setCurrentUser] = useState<any>();
  const { register, handleSubmit, watch, formState: { errors }, reset } = useForm();
  const [formSubmitLoader, setFormSubmitLoader] = useState<boolean>(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordShown1, setPasswordShown1] = useState(false);
  const [passwordShown2, setPasswordShown2] = useState(false);
  const [eye, setEye] = useState(false);
  const [eye1, setEye1] = useState(false);
  const [eye2, setEye2] = useState(false)


  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
    setEye(eye ? false : true)
  };
  const togglePasswordVisiblity1 = () => {
    setPasswordShown1(passwordShown1 ? false : true);
    setEye1(eye1 ? false : true)
  };
  const togglePasswordVisiblity2 = () => {
    setPasswordShown2(passwordShown2 ? false : true);
    setEye2(eye2 ? false : true)
  };

  useEffect(() => {
    if (localStorage.getItem('currentUser')) {
      setCurrentUser(JSON.parse(localStorage.getItem('currentUser')!));
    }
  }, []);

  const changePassword = (data: any) => {
    if (data.newPassword !== data.retypePassword) {
      alert("password not match")
      return

    }
    setFormSubmitLoader(true);
    data['userId'] = currentUser.userId;
    fetch(constants.BASE_URL + 'user/change-password', {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
      body: JSON.stringify(data)
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      setFormSubmitLoader(false);
      if (response.status == '200') {
        constants.showSuccess(response.message);
        reset();
      } else {
        constants.showError(response.message);
      }
    });
  }

  return (
    <>
      <section className="page-header page-header-dark bg-secondary">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-8">
              <h1>Change Password</h1>
            </div>
            <div className="col-md-4">
              <ul className="breadcrumb justify-content-start justify-content-md-end mb-0">
                <li><NavLink to="/">Home</NavLink></li>
                <li className="active">Change Password</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div className="container mb-2">
        <div className="row">
          <div className="col-lg-3">
            <ProfileSidebar />
          </div>
          <div className="col-lg-9">
            <div className="bg-white shadow-md rounded p-4">
              <h4 className="mb-4">Change Password</h4>
              <hr className="mx-n4 mb-4" />
              <div className="row g-4">
                <div className="col-lg-8">
                  <form id="changePassword" method="post" onSubmit={handleSubmit(changePassword)}>
                    <div className="mb-3">
                      <label className="form-label" htmlFor="existingPassword">Existing Password <span className='star'>*</span> </label>
                      <div className='mb-3 '>
                        <div className='pass-wrapper'>
                        <input type={passwordShown ? "text" : "password"} autoFocus className="form-control" id="existingPassword" placeholder="Existing Password"
                          {...register("password", { required: true, minLength:5, maxLength: 40 })} />
                        <span><i onClick={togglePasswordVisiblity} className={`fa ${eye ? "fa-eye-slash" : "fa-eye"} passwordhide`} aria-hidden="true"></i></span>
                        </div>
                        
                        {errors.password && <span className='text-danger'>Existing password is required</span>}
                      </div>
                    </div>
                    <div className="mb-3 ">
                      <label className="form-label" htmlFor="newPassword">New Password<span className='star'>*</span></label>
                      <div className="mb-3 ">
                        <div className='pass-wrapper'>
                        <input type={passwordShown1 ? "text" : "password"} className="form-control" id="newPassword" placeholder="New Password"
                          {...register("newPassword", { required: true, pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{5,}$/, minLength:5, maxLength: 40 })} />
                        <span><i onClick={togglePasswordVisiblity1} className={`fa ${eye1 ? "fa-eye-slash" : "fa-eye"} passwordhide`} aria-hidden="true"></i></span>
                        </div>
                        
                        {errors.newPassword && <span className='text-danger'>Password must contain minimum five characters, at least one uppercase letter, one lowercase letter and one number.</span>}
                      </div>
                    </div>
                    <div className="mb-3">
                      <label className="form-label" htmlFor="confirmPassword">Confirm Password <span className='star'>*</span></label>
                      <div className="mb-3 ">
                        <div className='pass-wrapper'>
                        <input type={passwordShown2 ? "text" : "password"} className="form-control" id="confirmPassword" placeholder="Confirm Password"
                          {...register("retypePassword", {
                            required: true, validate: (val: string) => {
                              if (watch('newPassword') != val) {
                                return "Your passwords do not match";
                              }
                            },
                          })} />
                        <span><i onClick={togglePasswordVisiblity2} className={`fa ${eye2 ? "fa-eye-slash" : "fa-eye"} passwordhide`} aria-hidden="true"></i></span>
                        </div>
                        
                        {errors.retypePassword && <span className='text-danger'>Must match with new password</span>}

                      </div>
                    </div>
                    <button className="btn btn-primary" type="submit" disabled={formSubmitLoader}>
                      {formSubmitLoader && <i className="fas fa-circle-notch fa-spin me-2"></i>}
                      Update Password
                    </button>
                  </form>
                </div>
                <div className="col-lg-4">
                  <div className="bg-light-2 rounded p-4">
                    <h3 className="text-4 mb-2">We value your Privacy</h3>
                    <p className="mb-0">We will not sell or distribute your contact information. Read our <a
                      href="/privacypolicy">Privacy Policy</a>.</p>
                    <hr className="mx-n4" />
                    <h3 className="text-4 mb-3">Billing Enquiries</h3>
                    <p className="mb-0">Do not hesitate to reach our support team if you have any queries.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
