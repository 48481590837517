import React, { useEffect, useState } from 'react'
import ProfileSidebar from '../ProfileSidebar/ProfileSidebar'
import * as constants from '../../Constants/Constants'
import ReactPaginate from 'react-paginate';
import { NavLink, useNavigate } from 'react-router-dom';


declare var $: any;

export default function () {

  const [paymentHistoryList, setPaymentHistoryList] = useState<any[]>();
  const [currentUser, setCurrentUser] = useState<any>();
  const [paymentId, setPaymentId] = useState<string | null>(null);
  const [invoiceDetails, setInvoiceDetails] = useState<string>('');

  // VARIABLES FOR PAGINATION
  const [pageCount, setPageCount] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const [itemsPerPage , setItemsPerPage ] = useState(10);
  const [searchKey, setSearchKey] = useState<string | null>(null);
  const [status, setStatus] = useState<string | null>(null);

  useEffect(() => {
    if (localStorage.getItem('currentUser')) {
      let userData = JSON.parse(localStorage.getItem('currentUser')!);
      setCurrentUser(userData);
      getServicePaymentList(userData.userId);
    }
  },[pageNum, searchKey, status]);

  const getServicePaymentList = (userId: string) => {
    let queryParams = '?pageNum='+pageNum+'&numPerPage=' + itemsPerPage + (searchKey && searchKey.length > 2 ? '&searchBy=' + searchKey : '')
      + (status && status.length > 0 ? '&status=' + status : '');
    fetch(constants.BASE_URL + 'service-request/payment/user/' + userId + queryParams, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      if (response.status == '200') {
        response.result.forEach((element: any) => {
          element['paidAmountStr'] = element.paidAmount.toLocaleString();
          element['amountToPayStr'] = element.amountToPay.toLocaleString();
        });
        setPaymentHistoryList(response.result);
        setPageCount(Math.ceil(response.listCount / itemsPerPage));
      } else {
        constants.showError(response.message);
      }
    });
  }

  const handlePageClick = (event: any) => {
    setPageNum(event.selected + 1);
  };

  const paymentInvoice = (paymentId: string) => {
    setPaymentId(paymentId);
    fetch(constants.BASE_URL + 'service-request/payment-receipt/' + paymentId, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': constants.getAuthHeader() },
    }).then(function (response) {
      return response.json();
    }).then(function (response: any) {
      if (response.status == '200') {
        setInvoiceDetails(response.result);
        setPaymentId(null);
        $('#invoice-modal').modal('show');
      } else {
        constants.showError(response.message);
      }
    });
  }

  const printInvoice = () => {
    var divToPrint = document.getElementById('invoice-content')!;
    var newWin = window.open('','Print-Window')!;
    newWin.document.open();
    newWin.document.write('<html><body onload="window.print()">'+divToPrint.innerHTML+'</body></html>');
    newWin.document.close();
    setTimeout(function(){newWin.close();},10);
  }

  const searchByService = (e: any) => {
    let searchKey = e.target.value;
    if(searchKey.length >= 3 || searchKey.length == 0){
      setSearchKey(searchKey);
    }
  }

  const searchByStatus = (status: string | null) => {
    setStatus(status);
  }

  return (
    <>
      <section className="page-header page-header-dark bg-secondary">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-8">
              <h1>My Payments</h1>
            </div>
            <div className="col-md-4">
              <ul className="breadcrumb justify-content-start justify-content-md-end mb-0">
                <li><NavLink to="/">Home</NavLink></li>
                <li className="active">My Payments</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div className="container-fluid p-5 mb-2">
        <div className="row">
          <div className="col-lg-2">
            <ProfileSidebar />
          </div>
          <div className="col-lg-10">
          <div className="bg-white shadow-md rounded p-4">
              <h4 className="mb-4">Payment History</h4>
              <hr className="mx-n4"/>
              <ul className="nav nav-tabs mb-4 position-relative" id="myTab" role="tablist">
              <div className='col-md-1.5 col-sm-1.5 col-xs-2'>
              <li className="nav-item cursor-pointer" onClick={() => searchByStatus(null)}> <a className={"nav-link" + (!status ? ' active' : '')} id="first-tab">All</a></li>

              </div>
              <div className='col-md-1.5 col-sm-1.5 col-xs-2'>
              <li className="nav-item cursor-pointer" onClick={() => searchByStatus('SUCCESS')}> <a className={"nav-link" + (status == 'SUCCESS' ? ' active' : '')} id="second-tab">Success</a> </li>
 
              </div>
              <div className='col-md-1.5 col-sm-1.5 col-xs-2'>
              <li className="nav-item cursor-pointer" onClick={() => searchByStatus('PENDING')}> <a className={"nav-link" + (status == 'PENDING' ? ' active' : '')} id="third-tab">Pending</a> </li>

                </div>
                <div className='col-md-1.5 col-sm-1.5 col-xs-2'>
                <li className="nav-item cursor-pointer" onClick={() => searchByStatus('FAILED')}> <a className={"nav-link" + (status == 'FAILED' ? ' active' : '')} id="fourth-tab">Failed</a> </li>

              </div>
              <div className='col-md-3 col-sm-3 col-xs-2'>
                
              </div>
              <div className='col-md-3 col-sm-3 col-xs-2'>
              <li className="nav-item pt-2"> 
                  <input type="text" className='form-control form-control-sm position-absolute w-25'
                    placeholder='Search by Service Name'  onChange={searchByService} /> 
                </li>
              </div>
                
              </ul>
              <div className="tab-content my-3" id="myTabContent">
                <div className="tab-pane fade show active" id="first" role="tabpanel" aria-labelledby="first-tab">
                  <div className="table-responsive-md">
                    <table className="table table-hover border">
                      <thead>
                        <tr>
                          <th>Sr No</th>
                          <th>Service</th>
                          <th>Payment Date</th>
                          <th>Payment Type</th>
                          <th>Amount Payable(₹)</th>
                          <th>Tax(%)</th>
                          <th>Total Amount(₹)</th>
                          <th>Status</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {paymentHistoryList?.map((pay, index) => (
                          <tr key={index}>
                            <td className="align-middle">{((pageNum - 1) * itemsPerPage) + (index + 1)}</td>
                            <td className="align-middle">{pay.serviceName}</td>
                            <td className="align-middle">{pay.paymentDate}</td>
                            <td className="align-middle">{pay.paymentType}</td>
                            <td className="align-middle">{pay.amountToPayStr}</td>
                            <td className="align-middle">{pay.taxPercent}</td>
                            <td className="align-middle">{pay.paidAmountStr}</td>
                            <td className="align-middle">{pay.paymentStatus}</td>
                            <td className="align-middle">
                              {pay.paymentStatus && pay.paymentStatus == 'SUCCESS' && 
                                <button className='btn btn-outline-primary btn-sm' style={{ padding: '0.1rem 1rem' }}
                                  disabled={pay.paymentId == paymentId}
                                  onClick={() => paymentInvoice(pay.paymentId)}>
                                    {pay.paymentId == paymentId && <i className="fas fa-circle-notch fa-spin me-2"></i>}
                                    Invoice
                                </button>
                              }
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <ReactPaginate breakLabel="..." nextLabel="next >" onPageChange={handlePageClick} pageRangeDisplayed={5}
                      pageCount={pageCount} previousLabel="< previous" pageClassName="page-item" pageLinkClassName="page-link"
                      previousClassName="page-item" previousLinkClassName="page-link" nextClassName="page-item" nextLinkClassName="page-link"
                      breakClassName="page-item" breakLinkClassName="page-link" containerClassName="pagination" activeClassName="active"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="invoice-modal" className="modal fade" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div className="modal-content border-0">
            <div className="modal-header">
              <h5 className="modal-title">Invoice Details</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
              <div className="modal-body py-4 px-2" id="invoice-content" dangerouslySetInnerHTML={{ __html: invoiceDetails }}>
                
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-gray" data-bs-dismiss="modal">Close</button>
                <button type="submit" className="btn btn-primary" onClick={printInvoice}>Print</button>
              </div>
          </div>
        </div>
      </div>
    </>
  )
}
