import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import * as constants from '../../Constants/Constants'
import "./Header.css"

declare var $: any;

export default function Header() {

  const navigate = useNavigate();
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<any>();
  const [bcShowMore, setBcShowMore] = useState<boolean>(false);
  const [bcShowMore1, setBcShowMore1] = useState<boolean>(false);
  const [bcShowMore2, setBcShowMore2] = useState<boolean>(false);

  useEffect(() => {
    constants.loginSubscriber.subscribe((status: boolean) => {
      getLoggedInDetails();
    });
    setTimeout(() => {
      $(document).on('click', '.dropdown-toggle', function(this: any){
        var ddMenu = $(this).next();
        ddMenu.toggleClass('show');
        $(document).on('focusout', ddMenu, function(){
          setTimeout(() => {
            $(ddMenu).removeClass('show');
          },200);  
        });
      });
    },500);
  }, []);

  const getLoggedInDetails = () => {
    if (localStorage.getItem('currentUser')) {
      setLoggedIn(true);
      setCurrentUser(JSON.parse(localStorage.getItem('currentUser')!));
    }
  }

  const logout = () => {
    localStorage.clear();
    setLoggedIn(false);
    setCurrentUser(null);
    navigate("/");
  }

  return (
    <>
      <div id="success-alert" className="alert shadow bg-success text-white" role="alert" style={{ position: 'fixed', top: '20px', right: '20px', zIndex: 1100, display: 'none' }}>
        A simple success alert—check it out!
      </div>
      <div id="error-alert" className="alert shadow bg-danger text-white" role="alert" style={{ position: 'fixed', top: '20px', right: '20px', zIndex: 1100, display: 'none' }}>
        A simple danger alert—check it out!
      </div>
      {!loggedIn &&
        <header id="header" className="text-3" style={{ position: 'sticky', top: 0, zIndex: 900 }}>
          <div className='container'>
            <div className="header-row">
              <div className="header-column justify-content-start">
                <div className="logo me-2 me-lg-3 text-5">
                  <a href="/" title="Company Search">
                    <p className='mb-0 logo-main position-relative'>
                      <span className='logo-left'>C<span className='text-danger'>O</span>MPANY</span>
                      <span className='logo-right'>SEAR<span className='text-danger'>C</span>H</span>
                      <span className='position-absolute logo-tm'>TM</span>

                    </p>
                    {/* <p className='logo-sub float-end mb-0'>Solution for Sustainable Entrepreneurs</p> */}
                  </a>
                </div>
              </div>
              <div className="header-column justify-content-end">
                <nav className="primary-menu navbar navbar-expand-lg">
                  <div id="header-nav" className="collapse navbar-collapse">
                    <ul className="navbar-nav">
                      <li className="dropdown dropdown-mega"> <a className="dropdown-toggle text3s" href="#">Business Setup <i className="arrow"></i></a>
                        <ul className="dropdown-menu">
                          <li>
                            <div className="row">
                              <div className="col-lg"> <span className="sub-title">Popular Entities</span>
                                <ul className="dropdown-mega-submenu" >
                                  <li> <NavLink to="/private-limited-company"><span>&gt;&nbsp;</span>Private Limited Company</NavLink></li>
                                  <li><NavLink to="/public-limited-company"><span>&gt;&nbsp;</span>Public Limited Company</NavLink></li>
                                  <li><NavLink to="/one-person-company"><span>&gt;&nbsp;</span>One Person Company(OPC)</NavLink></li>
                                  <li><NavLink to="/limited-liability-partnership"><span>&gt;&nbsp;</span>Limited Liability Partnership (LLP)</NavLink></li>
                                  <li><NavLink to="/partnership-firm"><span>&gt;&nbsp;</span>Partnership Firm</NavLink></li>
                                  <li><NavLink to="/proprietorship"><span>&gt;&nbsp;</span>Proprietorship</NavLink></li>
                                </ul>
                              </div>
                              <div className="col-lg"> <span className="sub-title">Special Entities</span>
                                <ul className="dropdown-mega-submenu">
                                  <li><NavLink to="farmers-producer-company"><span>&gt;&nbsp;</span>Farmer Producer Company (FPC)</NavLink></li>
                                  <li><NavLink to="nidhi-company"><span>&gt;&nbsp;</span>Nidhi Company</NavLink></li>
                                  <li><NavLink to="nonbanking-finance-company"><span>&gt;&nbsp;</span>Non-Banking Financial Company (NBFC) </NavLink></li>
                                  <li><NavLink to="indian-subsidiary"><span>&gt;&nbsp;</span>Indian Subsidiary Registration  </NavLink></li>
                                </ul>
                              </div>
                              <div className="col-lg"> <span className="sub-title">NGO Registration </span>
                                <ul className="dropdown-mega-submenu" >
                                  <li><NavLink to="section-8-company"><span>&gt;&nbsp;</span>Section 8 company  </NavLink></li>
                                  <li><NavLink to="trust-registration"><span>&gt;&nbsp;</span>Trust Registration</NavLink></li>
                                  <li><NavLink to="society-registration"><span>&gt;&nbsp;</span>Society Registration</NavLink></li>
                                </ul>
                              </div>
                              <div className="col-lg"> <span className="sub-title"> Search Name</span>
                                <ul className="dropdown-mega-submenu "  >
                                  <li><NavLink to="search-company-name"><span>&gt;&nbsp;</span>Search Company/LLP Name </NavLink></li>
                                </ul>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </li>
                      <li className="dropdown dropdown-mega"> <a className="dropdown-toggle text3s" href="#">Licence & Registrations<i className="arrow"></i></a>
                        <ul className="dropdown-menu">
                          <li>
                            <div className="row">
                              <div className="col-lg"> <span className="sub-title">Licences</span>
                                <ul className="dropdown-mega-submenu">
                                  <li><NavLink to="fssai-registration"><span>&gt;&nbsp;</span>FSSAI</NavLink></li>
                                  <li><NavLink to="shop-and-establishment"><span>&gt;&nbsp;</span>Shop and Establishment </NavLink></li>
                                  <li><NavLink to="12A-and-80G"><span>&gt;&nbsp;</span> Sec.12A & Sec.80 GS Registration</NavLink></li>
                                  <li><NavLink to="12AA"><span>&gt;&nbsp;</span>12AA Registration</NavLink></li>
                                  <li><NavLink to="12AB"><span>&gt;&nbsp;</span>12AB Registration</NavLink></li>
                                  <li><NavLink to="ngo-darpan-niti-ayog"><span>&gt;&nbsp;</span>Darpan Registration</NavLink></li>
                                </ul>
                              </div>
                              <div className="col-lg"> <span className="sub-title">Registrations</span>
                                <ul className="dropdown-mega-submenu">
                                  <li><NavLink to="import-export-registration"><span>&gt;&nbsp;</span>Import Export Code (IEC) </NavLink></li>
                                  <li><NavLink to="apeda-registration"><span>&gt;&nbsp;</span>APEDA (REMC) Registration</NavLink></li>
                                  <li><NavLink to="iso-certification"><span>&gt;&nbsp;</span>ISO Certificate</NavLink></li>
                                  <li><NavLink to="udyam"><span>&gt;&nbsp;</span>Udyam / Udyog / MSME</NavLink></li>

                                </ul>
                              </div>
                              <div className="col-lg"> <span className="sub-title">Others</span>
                                <ul className="dropdown-mega-submenu">
                                  <li> <NavLink to="/dsc"><span>&gt;&nbsp;</span>Digital Signature Certificate(DSC)</NavLink></li>
                                  <li> <NavLink to="/esi-registration"><span>&gt;&nbsp;</span>Employee State Insurance (ESI)</NavLink></li>
                                  <li> <NavLink to="/professional-tax"><span>&gt;&nbsp;</span>Professional Tax (PT) </NavLink></li>
                                  <li> <NavLink to="/pf-registration"><span>&gt;&nbsp;</span>Provident Fund (PF)</NavLink></li>
                                </ul>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </li>
                      <li className="dropdown dropdown-mega"> <a className="dropdown-toggle text3s" href="#">Compliances<i className="arrow"></i></a>
                        <ul className="dropdown-menu">
                          <li>
                            <div className="row">
                              <div className="col-lg"> <span className="sub-title">Annual Filling </span>
                                <ul className="dropdown-mega-submenu">
                                  <li > <NavLink to="/annual-filling-company"><span>&gt;&nbsp;</span>Company / OPC</NavLink></li>
                                  <li> <NavLink to="/annual-filling-llp"><span>&gt;&nbsp;</span>LLP</NavLink></li>
                                </ul>
                                <div className="col-lg"> <span className="sub-title">Annual Compliances</span>
                                  <ul className="dropdown-mega-submenu">
                                    <li> <NavLink to="/private-limited-company-compliance"><span>&gt;&nbsp;</span>Company / OPC</NavLink></li>
                                    <li> <NavLink to="/nidhi-company-compliance"><span>&gt;&nbsp;</span>Nidhi Company</NavLink></li>
                                    <li> <NavLink to="/secrion8-company-compliance"><span>&gt;&nbsp;</span>Section 8 Company</NavLink></li>
                                    <li> <NavLink to="/nbfc-compliance"><span>&gt;&nbsp;</span>NBFCs</NavLink></li>
                                  </ul>
                                  <div className="col-lg"> <span className="sub-title">Business Conversions</span>
                                    <ul className="dropdown-mega-submenu">
                                      <li> <NavLink to="/private-to-public-company"><span>&gt;&nbsp;</span>Private Company to Public Company</NavLink></li>
                                      <li> <NavLink to="/private-company-tosection8"><span>&gt;&nbsp;</span>Private Company to Section 8 Company</NavLink></li>
                                      {bcShowMore &&
                                        <>
                                          <li> <NavLink to="/privatecompany-toopc"><span>&gt;&nbsp;</span>Private Company to OPC</NavLink></li>
                                          <li> <NavLink to="/privatecompany-tollp"><span>&gt;&nbsp;</span>Private Company to LLP </NavLink></li>
                                          <li> <NavLink to="/publicto-privatelimited"><span>&gt;&nbsp;</span>Public Limited to Private Limited Company</NavLink></li>
                                          <li> <NavLink to="/section8-toprivateltd"><span>&gt;&nbsp;</span>Section 8 Company to Private Limited Company</NavLink></li>
                                          <li> <NavLink to="/opc-to-private"><span>&gt;&nbsp;</span>OPC to Private Company</NavLink></li>
                                          <li> <NavLink to="/llpto-private"><span>&gt;&nbsp;</span>LLP to Private Company</NavLink></li>
                                          <li> <NavLink to="/partnership-to-private"><span>&gt;&nbsp;</span>Partnership Firm into Private Company</NavLink></li>
                                          <li> <NavLink to="/partnershipto-llp"><span>&gt;&nbsp;</span>Partnership Firm to LLP</NavLink></li>
                                        </>
                                      }
                                      <a onClick={() => setBcShowMore(!bcShowMore)} className='text-primary cursor-pointer mb-2'>[Show {!bcShowMore && 'More'} {bcShowMore && 'Less'}]</a>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg">
                                <div className="col-lg"> <span className="sub-title">Changes In Directors</span>
                                  <ul className="dropdown-mega-submenu compliancefont">
                                    <li> <NavLink to="/appointment-of-director"><span>&gt;&nbsp;</span>Appointment of Director</NavLink></li>
                                    <li> <NavLink to="/resignation-of-director"><span>&gt;&nbsp;</span>Resignation of Director</NavLink></li>
                                    <li> <NavLink to="/removal-of-director"><span>&gt;&nbsp;</span>Removal of Director</NavLink></li>
                                  </ul>
                                </div>
                                <div className="col-lg"> <span className="sub-title">Changes in Capital</span>
                                  <ul className="dropdown-mega-submenu compliancefont">
                                    <li> <NavLink to="/authorised-capital"><span>&gt;&nbsp;</span>Authorised Capital </NavLink></li>
                                    <li> <NavLink to="/paid-up-capital"><span>&gt;&nbsp;</span>Paid-up Capital</NavLink></li>
                                  </ul>
                                </div>
                                <div className="col-lg"> <span className="sub-title">Shifting of Registered Office</span>
                                  <ul className="dropdown-mega-submenu compliancefont">
                                    <li> <NavLink to="/shifting-inlocal-limit"><span>&gt;&nbsp;</span>Shifting-Within in Local Limit</NavLink></li>
                                    <li> <NavLink to="/shiftingoutto-local"><span>&gt;&nbsp;</span>Shifting-Outside to Local Limit </NavLink></li>
                                    <li> <NavLink to="/shifting-diff-roc"><span>&gt;&nbsp;</span>Within in One State but Different ROC</NavLink></li>
                                    <li> <NavLink to="/shifting-one-state-toanother"><span>&gt;&nbsp;</span>One State to another State</NavLink></li>
                                  </ul>
                                </div>
                                <div className="col-lg">
                                  <ul className="dropdown-mega-submenu sub-title compliancefont">
                                    <li> <NavLink to="/transfer-of-share">Transfer of Shares </NavLink></li>
                                  </ul>
                                </div>
                                <div className="col-lg">
                                  <ul className="dropdown-mega-submenu sub-title compliancefont">
                                    <li> <NavLink to="/change-in-name">Changes In Names </NavLink></li>
                                  </ul>
                                </div>
                                <div className="col-lg">
                                  <ul className="dropdown-mega-submenu sub-title compliancefont">
                                    <li> <NavLink to="/changes-in-object">Changes in Object </NavLink></li>
                                  </ul>
                                </div>
                                <div className="col-lg">
                                  <ul className="dropdown-mega-submenu sub-title compliancefont">
                                    <li> <NavLink to="/Alteration-of-Articles-Association">Alteration of AOA</NavLink></li>
                                  </ul>
                                </div>
                              </div>
                              <div className="col-lg">
                                <div className="col-lg"> <span className="sub-title">Business Closure</span>
                                  <ul className="dropdown-mega-submenu">
                                    <li> <NavLink to="/striking-off-company"><span>&gt;&nbsp;</span>Striking off Company</NavLink></li>
                                    <li> <NavLink to="/striking-llp"><span>&gt;&nbsp;</span>Striking off LLP</NavLink></li>
                                    <li> <NavLink to="/winding-up-company"><span>&gt;&nbsp;</span>Winding Up – Company</NavLink></li>
                                    <li> <NavLink to="/dissolutionof-partnershipfirm"><span>&gt;&nbsp;</span>Dissolution of Partnership Firm</NavLink></li>
                                    <li> <NavLink to="/revival-of-strikingoff-company"><span>&gt;&nbsp;</span>Revival of Striking off Companies</NavLink></li>
                                  </ul>
                                </div>
                                <div className="col-lg"> <span className="sub-title">Statutory Registers & Minutes Books</span>
                                  <ul className="dropdown-mega-submenu compliancefont">
                                    <li> <NavLink to="/statuatory-registers"><span>&gt;&nbsp;</span>Statutory Registers </NavLink></li>
                                    <li> <NavLink to="/minutes-of-meeting"><span>&gt;&nbsp;</span>Minutes of Meeting</NavLink></li>
                                  </ul>
                                </div>
                                <div className="col-lg"> <span className="sub-title">Changes In LLP</span>
                                  <ul className="dropdown-mega-submenu compliancefont">
                                    <li> <NavLink to="/changes-in-partnersofllp"><span>&gt;&nbsp;</span>Change in Partners of LLP</NavLink></li>
                                    <li> <NavLink to="/change-in-llpagreement"><span>&gt;&nbsp;</span>Change in LLP Agreement</NavLink></li>
                                    <li> <NavLink to="/changing-llp-name"><span>&gt;&nbsp;</span>Changing LLP Name </NavLink></li>
                                    <li> <NavLink to="/shifting-reg-office-llp"><span>&gt;&nbsp;</span>Shifting of Registered Office of LLP </NavLink></li>
                                    <li> <NavLink to="/striking-llp"><span>&gt;&nbsp;</span>Close / Strike off LLP</NavLink></li>
                                  </ul>
                                </div>
                              </div>
                              <div className="col-lg">
                                <div className='col-lg'>
                                  <span className="sub-title">Other Compliances</span>
                                  <ul className="dropdown-mega-submenu compliancefont">
                                    <li> <NavLink to="/din-kyc"><span>&gt;&nbsp;</span>DIN KYC (Form DIR-3 KYC)</NavLink></li>
                                    <li> <NavLink to="/commensment-of-business"><span>&gt;&nbsp;</span>Commencement of Business (INC 20A)</NavLink></li>
                                    <li> <NavLink to="/active"><span>&gt;&nbsp;</span>ACTIVE - Form INC-22A</NavLink></li>
                                    <li> <NavLink to="/return-of-deposit"><span>&gt;&nbsp;</span>Return of Deposit</NavLink></li>
                                  </ul>
                                </div>
                                <div className="col-lg"> <span className="sub-title">ROC Search Report</span>
                                  <ul className="dropdown-mega-submenu compliancefont">
                                    <li > <NavLink to="/roc-search-report" ><span>&gt;&nbsp;</span>ROC Search Report</NavLink></li>
                                  </ul>
                                </div>
                                <div className="col-lg"> <span className="sub-title">Financial Services</span>
                                  <ul className="dropdown-mega-submenu compliancefont">
                                    <li> <NavLink to="/dsc"></NavLink></li>
                                    <li> <NavLink to="/business-plan"><span>&gt;&nbsp;</span>Business Plan</NavLink></li>
                                    <li> <NavLink to="/detailed-project-report"><span>&gt;&nbsp;</span>Detailed Project Reports (DPR)</NavLink></li>
                                    <li> <NavLink to="/due-dilligence"><span>&gt;&nbsp;</span>Due Dilligence Report (DDR) </NavLink></li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </li>
                      <li className="dropdown dropdown-mega"> <a className="dropdown-toggle text3s" href="#">Audit & Taxation<i className="arrow"></i></a>
                        <ul className="dropdown-menu">
                          <li>
                            <div className="row">
                              <div className="col-lg"> <span className="sub-title">Audit</span>
                                <ul className="dropdown-mega-submenu">
                                  <li> <NavLink to="/audit-company-accounts"><span>&gt;&nbsp;</span>Company Audit</NavLink></li>
                                  <li> <NavLink to="/secretorial-audit"><span>&gt;&nbsp;</span> Secretarial Audit</NavLink></li>
                                  <li> <NavLink to="/tax-audit"><span>&gt;&nbsp;</span>Tax Audit</NavLink></li>
                                </ul>
                              </div>
                              <div className="col-lg"> <span className="sub-title">Income Tax</span>
                                <ul className="dropdown-mega-submenu">
                                  <li> <NavLink to="/individual-income-tax-returns"><span>&gt;&nbsp;</span>Personal Tax Filing</NavLink></li>
                                  <li> <NavLink to="/income-tax-return-for-business"><span>&gt;&nbsp;</span>Business Tax Filing</NavLink></li>
                                  <li> <NavLink to="/tds-return-filling"><span>&gt;&nbsp;</span>TDS Return Filing</NavLink></li>
                                </ul>
                              </div>
                              <div className="col-lg"> <span className="sub-title">GST</span>
                                <ul className="dropdown-mega-submenu">
                                  <li> <NavLink to="/gst-registration"><span>&gt;&nbsp;</span>GST Registration</NavLink></li>
                                  <li> <NavLink to="/gst-return-filling"><span>&gt;&nbsp;</span>GST Return Filing</NavLink></li>
                                </ul>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </li>
                      <li className="dropdown dropdown-mega"> <a className="dropdown-toggle text3s" href="#">Trademark & IPR<i className="arrow"></i></a>
                        <ul className="dropdown-menu">
                          <li>
                            <div className="row">
                              <div className="col-lg"> <span className="sub-title">Trademark</span>
                                <ul className="dropdown-mega-submenu">
                                  <li><NavLink to="/trademark-search"><span>&gt;&nbsp;</span>Trademark Search</NavLink></li>
                                  <li><NavLink to="/trademark-application"><span>&gt;&nbsp;</span>Trademark Application</NavLink></li>
                                  <li><NavLink to="/trademark-objection"> <span>&gt;&nbsp;</span>Trademark Objection</NavLink></li>
                                  <li><NavLink to="/trademark-opposition"><span>&gt;&nbsp;</span>Trademark Opposition</NavLink></li>
                                  <li><NavLink to="/trademark-renewal"><span>&gt;&nbsp;</span>Trademark Renewal</NavLink></li>
                                  <li><NavLink to="/trademark-assignment"><span>&gt;&nbsp;</span>Trademark Assignment</NavLink></li>
                                </ul>
                              </div>
                              <div className="col-lg"> <span className="sub-title">Patent</span>
                                <ul className="dropdown-mega-submenu">
                                  <li><NavLink to="/patent-search"><span>&gt;&nbsp;</span>Patent Search</NavLink></li>
                                  <li><NavLink to="/provisional-patent"><span>&gt;&nbsp;</span>Provisional Patent</NavLink></li>
                                  <li><NavLink to="/patent-registration"><span>&gt;&nbsp;</span>Patent Registration</NavLink></li>
                                </ul>
                              </div>
                              <div className="col-lg"> <span className="sub-title">Copyright</span>
                                <ul className="dropdown-mega-submenu">
                                  <li> <NavLink to="/copyright-registration"><span>&gt;&nbsp;</span>Copyright Registration</NavLink></li>
                                  <li><NavLink to="/design-registration"><span>&gt;&nbsp;</span>Design Registration</NavLink></li>
                                </ul>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </li>
                      <li className="dropdown dropdown-mega"> <a className="dropdown-toggle text3s" href="#">FPO Service<i className="arrow"></i></a>
                        <ul className="dropdown-menu">
                          <li>
                            <div className="row">
                              <div className="col-lg"> <span className="sub-title">FPO Registration</span>
                                <ul className="dropdown-mega-submenu">
                                  <li> <NavLink to="/fpo"><span>&gt;&nbsp;</span>Farmers Producer Organization (FPO)</NavLink></li>
                                  <li><NavLink to="/farmers-producer-company"><span>&gt;&nbsp;</span>Farmers Producer Company (FPC)</NavLink></li>
                                  <li><NavLink to="/pacs"><span>&gt;&nbsp;</span>Primary Agricultural Credit Societies (PACS)</NavLink></li>
                                </ul>
                              </div>

                              <div className="col-lg"> <span className="sub-title">Schemes of SFAC for FPC </span>
                                <ul className="dropdown-mega-submenu">
                                  <li><NavLink to="equity-grant-scheme"><span>&gt;&nbsp;</span>Equity Grant Schemes</NavLink></li>
                                  <li><NavLink to="credit-guarantee-scheme"><span>&gt;&nbsp;</span>Credit Guarantee Schemes</NavLink></li>
                                  <li><NavLink to="venture-capital-assistance"><span>&gt;&nbsp;</span>Venture Capital Assistance Schemes</NavLink></li>
                                </ul>
                              </div>
                              <div className="col-lg"> <span className="sub-title">Other Schemes for FPC</span>
                                <ul className="dropdown-mega-submenu">
                                  <li><NavLink to="aif"><span>&gt;&nbsp;</span>Agriculture Infrastructure Fund (AIF) Scheme</NavLink></li>
                                  <li><NavLink to="scheme-of-promotion-fpo"><span>&gt;&nbsp;</span>Scheme of Promotion of FPO</NavLink></li>
                                  <li><NavLink to="smart"><span>&gt;&nbsp;</span>SMART Project -Maharashtra State</NavLink></li>
                                  <li><NavLink to="magnet"><span>&gt;&nbsp;</span>MAGNET Project – Maharashtra State</NavLink></li>
                                </ul>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </li>
                      <li className="dropdown"> <a className="dropdown-toggle " href="#"><i className="fas fa-bars"></i></a>
                        <ul className="dropdown-menu profile-dropdown">
                          <li><NavLink className="dropdown-item" to="/aboutus">About Us</NavLink></li>
                          <li><NavLink to="/contactus" className="dropdown-item">Contact Us</NavLink></li>
                          <li><NavLink to="/blogs" className="dropdown-item">Blogs</NavLink></li>
                          <li><a className="dropdown-item cursor-pointer" data-bs-toggle="modal" data-bs-target="#login-modal">Login</a></li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </nav>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#header-nav"> <span /> <span /> <span /> </button>
              </div>
            </div>
          </div>
        </header>
      }
      {/* new menubar starts */}
      {loggedIn &&
        <nav className="navbar navbar-expand-lg navbar-light bg-white" style={{ position: 'sticky', top: 0, zIndex: 900 }}>
          <div className="container">
            <div className="logo me-2 me-lg-3 text-5">
              <a href="/" title="Company Search">
                <p className='mb-0 logo-main position-relative'>
                  <span className='logo-left'>C<span className='text-danger'>O</span>MPANY</span>
                  <span className='logo-right'>SEAR<span className='text-danger'>C</span>H</span>
                  <span className='position-absolute logo-tm'>TM</span>
                </p>
                {/* <p className='logo-sub float-end mb-0'>Solution for Sustainable Entrepreneurs</p> */}
              </a>
            </div>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
              <span className="fas fa-bars" />
            </button>
            <div className="collapse navbar-collapse login-navbar" id="navbarNavAltMarkup">
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                <li className="nav-item user-link">
                  <NavLink className="nav-link text3s" to="/dashboard">Dashboard</NavLink>
                </li>
                {currentUser && currentUser.role?.name == 'CUSTOMER' &&
                  <li className="nav-item user-link">
                    <NavLink className="nav-link text3s" to="/my-interests">My Interests</NavLink>
                  </li>
                }
              </ul>
              <nav className="login-signup navbar navbar-expand">
                <ul className="navbar-nav ">
                  <li className="profile dropdown active">
                    <a className="pe-0 dropdown-toggle" href="#" style={{ height: "35px" }} title="Login / Sign up">
                      <span className="user-icon ms-sm-2"><i className="fas fa-user" /></span>
                    </a>
                    <ul className="dropdown-menu deskMargin">
                      <li className="text-center text-3 py-2">Hi, {currentUser?.fullName}</li>
                      <li className="dropdown-divider mx-n3"></li>
                      <li><NavLink className="dropdown-item" to="/dashboard"><i className="fa fa-chart-area"></i>Dashboard</NavLink></li>
                      {currentUser && currentUser.role?.name == 'CUSTOMER' &&
                        <>
                          <li><NavLink className="dropdown-item" to="/address-details"><i className="fas fa-address-card"></i>Address Details</NavLink></li>
                          <li><NavLink className="dropdown-item" to="/profile"><i className="fas fa-user"></i>Personal Information</NavLink></li>
                        </>
                      }
                      {currentUser && currentUser.role?.name == 'ADMIN' &&
                        <>
                          {/* <li><NavLink className="dropdown-item" to="/category"><i className="fas fa-folder"></i>Category</NavLink></li> */}
                        </>
                      }
                      <li><NavLink className="dropdown-item" to="/change-password"><i className="fas fa-key"></i>Change Password</NavLink></li>
                      <li className="dropdown-divider mx-n3"></li>
                      <li><a className="dropdown-item" href="#" onClick={logout}><i className="fas fa-sign-out-alt"></i>Log Out</a></li>
                    </ul>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </nav>
      }
      {/* new menubar ends */}

    </>
  )
}
